import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { AuthProvider } from "./components/auth/FirebaseAuthContext";
import { ReducerProvider } from "./reducer/context";

import PublicLayoutRouter from "./components/public/PublicLayout";
import AppRouter from "./components/app/AppRouter";
import UserLayout from "./components/app/layouts/UserLayout";
import AccountLayout from "./components/app/layouts/AccountLayout";
import ProfileLayout from "./components/app/layouts/ProfileLayout";
import DynamicProfileLayout from "./components/app/layouts/DynamicProfileLayout";

import Home from "./pages/app/Home";
import SignIn from "./pages/public/SignIn";
import SignUp from "./pages/public/SignUp";
import ResetPasswordRequest from "./pages/public/ResetPasswordRequest";
import ActionPage from "./pages/public/ActionPage";
import NotFound from "./pages/public/NotFound";
import CreateAccount from "./pages/app/account/CreateAccount";
import DeleteAccount from "./pages/app/account/DeleteAccount";

import UserProfile from "./pages/app/user/UserProfile";
import UpdateUserName from "./pages/app/user/UpdateUserName";
import UpdateUserPhone from "./pages/app/user/UpdateUserPhone";
import UpdateUserEmail from "./pages/app/user/UpdateUserEmail";
import UpdateUserPassword from "./pages/app/user/UpdateUserPassword";
import DeleteUser from "./pages/app/user/DeleteUser";
import UserActivities from "./pages/app/user/UserActivities";

import CreateProfile from "./pages/app/profiles/CreateProfile";
import UpdateProfile from "./pages/app/profiles/UpdateProfile";
import DeleteProfile from "./pages/app/profiles/DeleteProfile";
import Conversions from "./pages/app/profiles/Conversions";
import AccountUsers from "./pages/app/account/AccountUsers";
import AccountUserForm from "./pages/app/account/UserForm";

import Dashboard from "./pages/app/profiles/Dashboard";
import SessionList from "./pages/app/profiles/Replay";
import Pages from "./pages/app/profiles/Pages";
import Forms from "./pages/app/profiles/Forms";
import ProfileUsers from "./pages/app/profiles/Profiles";
import TrackingCode from "./pages/app/profiles/TrackingCode";
import TrackingCodeSettings from "./pages/app/profiles/TrackingCodeSettings";
import UserForm from "./pages/app/profiles/Profiles/UserForm";
import Segments from "./pages/app/profiles/Segments";
import Notes from "./pages/app/profiles/Notes";
import Funnels from "./pages/app/profiles/Funnels";
import NoteRedirect from "./pages/app/profiles/NoteRedirect";
import * as Icons from "./components/assets/icons/MenuIconProvider";

import "./style.scss";
import "./theme.scss";
import TokenGenerator from "./pages/app/profiles/TokenGenerator";
import Pathing from "./pages/app/profiles/Pathing";
import PathingRules from "./pages/app/profiles/PathingRules";
import FunnelAction from "./pages/app/profiles/Funnels/FunnelAction";
import ReportWrapper from "./components/app/ReportWrapper";
import Reports from "./pages/app/profiles/Reports";
import RedirectOldUrl from "./components/app/RedirectOldUrl";

function App() {
  return (
    <AuthProvider>
      <ReducerProvider>
        <Router>
          <RedirectOldUrl />
          <Switch>
            <AppRouter
              exact
              path="/"
              component={Home}
              template={AccountLayout}
              title="Home"
            />
            <AppRouter
              eaxct
              path="/tokengenerator"
              component={TokenGenerator}
              template={AccountLayout}
              title=""
            />
            <AppRouter
              exact
              path="/create-account"
              component={CreateAccount}
              template={AccountLayout}
              title="Create New Account"
              requiredRole={"account-creator"}
            />
            <AppRouter
              exact
              path="/delete-account"
              component={DeleteAccount}
              template={AccountLayout}
              title="Delete an Account"
              requiredRole={"super-admin"}
            />
            <AppRouter
              exact
              path="/account/:accountId/create-profile"
              component={CreateProfile}
              template={AccountLayout}
              title="Create New Profile"
            />
            <AppRouter
              exact
              path="/delete-profile"
              component={DeleteProfile}
              template={AccountLayout}
              title="Delete a Profile"
              requiredRole={"super-admin"}
            />
            <AppRouter
              exact
              path="/profile/:profileId/admin/settings"
              component={UpdateProfile}
              template={ProfileLayout}
              title="Update Profile"
              titleIcon={Icons.SettingsProfile}
            />
            <AppRouter
              exact
              path="/profile/:profileId/admin/code"
              component={TrackingCode}
              template={ProfileLayout}
              title="Tracking Code"
              titleIcon={Icons.SettingsCode}
            />
            <AppRouter
              exact
              path="/profile/:profileId/admin/code/settings"
              component={TrackingCodeSettings}
              template={ProfileLayout}
              title="Tracking Code Settings"
              titleIcon={Icons.SettingsCode}
            />
            <AppRouter
              exact
              path="/profile/:profileId/admin/conversions"
              component={Conversions}
              template={ProfileLayout}
              title="Conversions"
              titleIcon={Icons.SettingsConversions}
            />
            <AppRouter
              exact
              path="/account/:accountId/users"
              component={AccountUsers}
              template={AccountLayout}
              title="Account Users"
            />
            <AppRouter
              exact
              path="/account/:accountId/users/add"
              component={AccountUserForm}
              template={AccountLayout}
              title="Account Users"
            />
            <AppRouter
              exact
              path="/account/:accountId/users/:userId"
              component={AccountUserForm}
              template={AccountLayout}
              title="Edit Account User"
            />
            <AppRouter
              exact
              path="/profile/:profileId/reports/:reportId"
              component={ReportWrapper}
              template={ProfileLayout}
              type="report"
              dateRangeSelector={true}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/analytics/dashboard"
              component={Dashboard}
              template={ProfileLayout}
              title="Dashboard"
              type="dashboard"
              titleIcon={Icons.Dashboard}
              dateRangeSelector={true}
              quickFilter={true}
              quickReport={true}
              compareAbility={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/replay/list"
              component={SessionList}
              template={ProfileLayout}
              title="Replay List"
              type="replay_list"
              titleIcon={Icons.Replays}
              dateRangeSelector={true}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/replay/list/:visitorId"
              component={SessionList}
              template={ProfileLayout}
              title="Replay List"
              type="replay_list"
              titleIcon={Icons.Replays}
              dateRangeSelector={true}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/replay/session/:visitorId/:sessionNumber"
              component={SessionList}
              template={ProfileLayout}
              title="Replay"
            />
            <AppRouter
              exact
              path="/note/:profileId/:noteId"
              component={NoteRedirect}
              template={ProfileLayout}
              title="Replay Note"
            />
            <AppRouter
              exact
              path="/profile/:profileId/segments/list"
              component={Segments}
              template={ProfileLayout}
              title="Segments"
              titleIcon={Icons.Segments}
            />
            <AppRouter
              exact
              path="/profile/:profileId/notes/list"
              component={Notes}
              template={ProfileLayout}
              title="Notes"
              titleIcon={Icons.Notes}
            />
            <AppRouter
              exact
              path="/profile/:profileId/report/list"
              component={Reports}
              template={ProfileLayout}
              title="Saved Reports"
              type="report_list"
              titleIcon={Icons.Reports}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/pathing/sunburst"
              component={Pathing}
              template={ProfileLayout}
              title="Pathing"
              type="pathing"
              titleIcon={Icons.Pathing}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/pathing/:action"
              component={PathingRules}
              template={ProfileLayout}
              title="Edit Page Grouping Rules"
              quickFilter={false}
              quickReport={false}
            />
            <AppRouter
              exact
              path="/profile/:profileId/funnels/list"
              component={Funnels}
              template={ProfileLayout}
              title="Funnel List"
              type="funnel_list"
              titleIcon={Icons.Funnels}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/funnels/:action"
              component={FunnelAction}
              template={ProfileLayout}
              title="Funnel View"
              titleIcon={Icons.Funnels}
              quickFilter={false}
              quickReport={false}
            />
            <AppRouter
              exact
              path="/profile/:profileId/funnels/:action/:funnelId"
              component={FunnelAction}
              template={ProfileLayout}
              title="Funnel View"
              type="funnel_view"
              titleIcon={Icons.Funnels}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/pages/list"
              component={Pages}
              template={DynamicProfileLayout}
              title="All Pages"
              type="page_list"
              titleIcon={Icons.PageAnalysis}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/landing-pages/list"
              component={Pages}
              template={DynamicProfileLayout}
              title="Landing Pages"
              type="landing_page_list"
              titleIcon={Icons.LandingPages}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/forms/list"
              component={Forms}
              template={DynamicProfileLayout}
              title="Form Analysis"
              type="form_list"
              titleIcon={Icons.FormAnalysis}
              quickFilter={true}
              quickReport={true}
            />
            <AppRouter
              exact
              path="/profile/:profileId/admin/users"
              component={ProfileUsers}
              template={ProfileLayout}
              title="Profile Users"
              quickFilter={false}
              quickReport={false}
              titleIcon={Icons.SettingsUsers}
            />
            <AppRouter
              exact
              path="/profile/:profileId/admin/users/add"
              component={UserForm}
              template={ProfileLayout}
              title="Invite Users to Your Profile "
              quickFilter={false}
              quickReport={false}
              titleIcon={Icons.SettingsUsers}
            />
            <AppRouter
              exact
              path="/profile/:profileId/admin/users/:userId"
              component={UserForm}
              template={ProfileLayout}
              title="Edit Profile User"
              quickFilter={false}
              quickReport={false}
              titleIcon={Icons.SettingsUsers}
            />
            <AppRouter
              exact
              path="/user/profile"
              component={UserProfile}
              template={UserLayout}
              title="Your Profile"
            />
            <AppRouter
              exact
              path="/user/profile/update-name"
              component={UpdateUserName}
              template={UserLayout}
              title="Change Your Name"
            />
            <AppRouter
              exact
              path="/user/profile/update-email"
              component={UpdateUserEmail}
              template={UserLayout}
              title="Change Your Email"
            />
            <AppRouter
              exact
              path="/user/profile/update-password"
              component={UpdateUserPassword}
              template={UserLayout}
              title="Change Your Password"
            />
            <AppRouter
              exact
              path="/user/profile/update-phone"
              component={UpdateUserPhone}
              template={UserLayout}
              title="Change Your Phone"
            />
            <AppRouter
              exact
              path="/user/profile/delete-account"
              component={DeleteUser}
              template={UserLayout}
              title="Delete Your Account"
            />
            <AppRouter
              exact
              path="/user/log"
              component={UserActivities}
              template={UserLayout}
              title="Your Activities"
            />
            <PublicLayoutRouter exact path="/signin" component={SignIn} />
            <PublicLayoutRouter exact path="/signup" component={SignUp} />
            <PublicLayoutRouter
              exact
              path="/reset-request"
              component={ResetPasswordRequest}
            />
            <PublicLayoutRouter exact path="/action" component={ActionPage} />
            <PublicLayoutRouter component={NotFound} />
          </Switch>
        </Router>
      </ReducerProvider>
    </AuthProvider>
  );
}

export default App;

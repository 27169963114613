import React, { useEffect, useContext, useState } from "react";
import { Route, withRouter } from "react-router-dom";
import { AuthContext } from "../auth/FirebaseAuthContext";
import Loader from "../share/Loader";
import { UserDataProvider } from "./UserData";
import { SegmentsProvider } from "./../../context/Segments";
import ReportWrapper from "./ReportWrapper";
import * as Sentry from "@sentry/react";

// Role inheritance system
const ROLE_HIERARCHY = {
  "super-admin": ["account-creator"],
  "account-creator": [],
};

// Helper function to check if a role has access to a required role
const hasRequiredRole = (userRole, requiredRole) => {
  if (!userRole || !requiredRole) return false;
  if (userRole === requiredRole) return true;

  // Check if userRole includes requiredRole in its hierarchy
  const inheritedRoles = ROLE_HIERARCHY[userRole] || [];
  return (
    inheritedRoles.includes(requiredRole) ||
    inheritedRoles.some((role) => hasRequiredRole(role, requiredRole))
  );
};

const AppRouter = ({
  component: Component,
  template: Template,
  requiredRole,
  ...rest
}) => {
  const { authUser } = useContext(AuthContext);
  const [claimsChecked, setClaimsChecked] = useState(!requiredRole);
  const [hasRole, setHasRole] = useState(false);

  useEffect(() => {
    // Store the current url if user is not authenticated
    // and if the user is not signin or signup
    // before redirecting the user to the signin page
    if (
      authUser.checked &&
      !authUser.user &&
      !["/signin", "/signup"].includes(window.location.pathname)
    ) {
      sessionStorage.setItem("redirect", window.location.pathname);
      rest.history.push("/signin");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.checked]);

  useEffect(() => {
    setClaimsChecked(false);

    // Skip role check if no role required
    if (!requiredRole) {
      setClaimsChecked(true);
      setHasRole(true);
      return;
    }

    // Reset state when user changes
    if (!authUser.user) {
      setClaimsChecked(true);
      setHasRole(false);
      return;
    }

    try {
      const userRole = authUser.role;
      const hasAccess = hasRequiredRole(userRole, requiredRole);
      setHasRole(hasAccess);

      if (!hasAccess) {
        // Track attempts to access unauthorized routes
        Sentry.captureMessage("error: user does not have required role", {
          level: "warning",
        });
        rest.history.push("/404");
      }
    } catch (error) {
      setHasRole(false);
      console.error("Error checking role:", error);
      Sentry.captureException(error);
      rest.history.push("/404");
    }
    setClaimsChecked(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.user, requiredRole]);

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        // Show loader while checking auth or claims
        !authUser.checked || !claimsChecked ? (
          <Loader />
        ) : (
          // Only render if user is authenticated and has required role
          !!authUser.user &&
          (!requiredRole || hasRole) && (
            <UserDataProvider>
              <SegmentsProvider>
                {rest.type !== "report" ? (
                  <Template {...rest}>
                    <Component {...matchProps} />
                  </Template>
                ) : (
                  <ReportWrapper
                    template={Template}
                    rest={rest}
                    matchProps={matchProps}
                  />
                )}
              </SegmentsProvider>
            </UserDataProvider>
          )
        )
      }
    />
  );
};

export default withRouter(AppRouter);

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { addLog, updatePassword } from "./../../../lib/firebase";
import { UPDATE_PASSWORD } from "./../../../constants/log";
import { Form, Button, Input } from "./../../../components/share/InsightUI";
import { passwordChangeValidator } from "./../../../helpers/formValidators";
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "./../../../lib/firebase";
import Alert from "./../../../components/share/Alert";

const UpdateUserPassword = () => {
  const [alert, setAlert] = useState({
    show: false,
    type: "success",
    message: "",
    count: 0,
  });

  const [initialValues] = useState({
    password: "",
    password1: "",
    password2: "",
  });

  const handleSubmit = (values, { setSubmitting, setValues }) => {
    const credential = EmailAuthProvider.credential(
      getAuth().currentUser.email,
      values.password
    );
    reauthenticateWithCredential(getAuth().currentUser, credential)
      .then(() => {
        updatePassword(getAuth().currentUser, values.password1)
          .then(() => {
            addLog(UPDATE_PASSWORD);
            setSubmitting(false);
            setValues(initialValues, false);
            setAlert({
              ...alert,
              type: "success",
              show: true,
              message: "Your password was successfully updated",
            });
          })
          .catch(() => {
            setSubmitting(false);
            setAlert({
              ...alert,
              type: "danger",
              show: true,
              message: "An error was encountered while updating your password",
            });
          });
      })
      .catch(() => {
        setSubmitting(false);
        setAlert({
          ...alert,
          type: "danger",
          show: true,
          message: "The password you provided is invalid",
        });
      });
  };

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          handleVisibility={() => {
            setAlert({ ...alert, show: false });
          }}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validate={passwordChangeValidator}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={handleSubmit}
              >
                {({
                  dirty,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form
                    className="user"
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting}
                  >
                    <Input
                      inline
                      columnwidths={[2, 4]}
                      isInvalid={!!(touched.password && errors.password)}
                      invalidFeedback={errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      value={values.password || ""}
                      label="Current Password"
                      type="password"
                    />
                    <Input
                      inline
                      columnwidths={[2, 4]}
                      isInvalid={!!(touched.password1 && errors.password1)}
                      invalidFeedback={errors.password1}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password1"
                      value={values.password1 || ""}
                      label="New Password"
                      type="password"
                    />
                    <Input
                      inline
                      columnwidths={[2, 4]}
                      isInvalid={!!(touched.password2 && errors.password2)}
                      invalidFeedback={errors.password2}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password2"
                      value={values.password2 || ""}
                      label="Confirm Password"
                      type="password"
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">&nbsp;</div>
                      <div className="col-sm-4">
                        <Button
                          disabled={isSubmitting || !dirty}
                          isprocessing={isSubmitting}
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                        {` `}
                        <Link to="/user/profile" className="btn btn-secondary">
                          Back
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateUserPassword;

import {
  Firestore,
  getDocs,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  getAuth,
  query,
  orderBy,
  collection,
} from "./index";

export const saveComment = (commentData, profileId, noteId, id = null) => {
  let commentsCollectionRef = collection(
    Firestore,
    "profiles",
    profileId,
    "notes",
    noteId,
    "comments"
  );
  if (id) {
    const comment = doc(commentsCollectionRef, id);

    return setDoc(comment, commentData, { merge: true });
  }

  commentData.ownerId = getAuth().currentUser.uid;
  commentData.creationTime = String(Date.now());

  return addDoc(commentsCollectionRef, commentData);
};

export const fetchComments = (profileId, noteId) => {
  return getDocs(
    query(
      collection(Firestore, "profiles", profileId, "notes", noteId, "comments"),
      orderBy("creationTime")
    )
  );
};

export const deleteComment = (profileId, noteId, commentId) => {
  return deleteDoc(
    doc(
      Firestore,
      "profiles",
      profileId,
      "notes",
      noteId,
      "comments",
      commentId
    )
  );
};

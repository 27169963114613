import React, { useContext, useState, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { AuthContext } from "../../../components/auth/FirebaseAuthContext";
import Alert from "../../../components/share/Alert";
import { PhoneAuthProvider, RecaptchaVerifier } from "firebase/auth";
import { Form, Input, Button } from "./../../../components/share/InsightUI";
import {
  phoneValidator,
  phoneVerificationValidator,
} from "./../../../helpers/formValidators";
import { addLog, getAuth } from "./../../../lib/firebase";
import { UPDATE_PHONE } from "./../../../constants/log";

const UpdateUserPhone = () => {
  let recaptcha = createRef();
  const { authUser } = useContext(AuthContext);
  const [step, setStep] = useState(1);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [initialValues, setInitialValues] = useState({
    phone: authUser.user.phoneNumber,
    verificationCode: "",
  });
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });
  const validators = {
    1: phoneValidator,
    2: phoneVerificationValidator,
  };

  const handleSubmit = (values, { setSubmitting, setValues }) => {
    // Step 1. Phone Number submit
    if (step === 1) {
      var provider = new PhoneAuthProvider(getAuth());
      provider
        .verifyPhoneNumber(values.phone, window.recaptchaVerifier)
        .then((vid) => {
          setVerificationId(vid);
          setSubmitting(false);
          setStep(2);
        })
        .catch(function (error) {
          setSubmitting(false);
          setAlert({
            show: true,
            type: "danger",
            message: error.message,
            count: alert.count + 1,
          });
        });
    }

    // Step 2. Verification Code submit
    if (step === 2) {
      var cred = PhoneAuthProvider.credential(
        verificationId,
        values.verificationCode
      );
      authUser.user
        .updatePhoneNumber(cred)
        .then(function () {
          addLog(UPDATE_PHONE);
          setSubmitting(false);
          setInitialValues({
            phone: values.phone,
            verificationCode: "",
          });
          setValues({
            phone: values.phone,
            verificationCode: "",
          });
          setIsCaptchaVerified(false);
          setStep(1);
          setAlert({
            show: true,
            type: "success",
            message:
              'Your phone number has been updated. Please click "Back" button to go back to your profile page.',
            count: alert.count + 1,
          });
        })
        .catch((error) => {
          setSubmitting(false);
          setAlert({
            show: true,
            type: "danger",
            message: error.message,
            count: alert.count + 1,
          });
        });
    }
  };

  useEffect(() => {
    if (recaptcha && step === 1) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        recaptcha,
        {
          size: "normal",
          callback: () => {
            setIsCaptchaVerified(true);
          },
          "expired-callback": () => {
            setIsCaptchaVerified(false);
          },
        },
        getAuth()
      );
      window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validate={validators[step]}
                validateOnBlur={false}
                validateOnChange={false}
                onSubmit={handleSubmit}
              >
                {({
                  dirty,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form className="user" handleSubmit={handleSubmit}>
                    {step === 1 && (
                      <Input
                        inline
                        columnwidths={[2, 6]}
                        helptext="Please put in your phone number including country code (e.g. +16505550101)."
                        isInvalid={!!(touched.phone && errors.phone)}
                        invalidFeedback={errors.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="phone"
                        value={values.phone || ""}
                        label="Phone Number"
                        placeholder="Enter your phone number"
                      />
                    )}
                    {step === 1 && (
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"></label>
                        <div className="col-sm-10">
                          <div ref={(ref) => (recaptcha = ref)}></div>
                        </div>
                      </div>
                    )}
                    {step === 2 && (
                      <Input
                        inline
                        columnwidths={[2, 6]}
                        helptext="Please find your verification code in the text message we sent to your phone."
                        isInvalid={
                          !!(
                            touched.verificationCode && errors.verificationCode
                          )
                        }
                        invalidFeedback={errors.verificationCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="verificationCode"
                        value={values.verificationCode || ""}
                        label="Verification Code"
                        placeholder="e.g. 123456"
                      />
                    )}
                    <div className="form-group row">
                      <div className="col-sm-2">&nbsp;</div>
                      <div className="col-sm-4">
                        <Button
                          disabled={
                            !isCaptchaVerified ||
                            isSubmitting ||
                            initialValues.phone === values.phone ||
                            (step === 2 && !values.verificationCode)
                          }
                          isprocessing={isSubmitting}
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                        {` `}
                        <Link to="/user/profile" className="btn btn-secondary">
                          Back
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
              {/**
                    {verifyStep?(
                        <TextField id="verification-code" label="Verification Code" placeholder="e.g. 123456" text={verificationCode} helpText="Please find your verification code in the text message we sent to your phone." handleChange={(e) => {
                            setVerificationCode(e.target.value);
                        }}></TextField> 
                    ):(
                        <TextField id="phone-number" label="Phone Number" placeholder="e.g. +1650550101" text={phoneNumber} helpText="Please put in your phone number including country code (e.g. +16505550101)." handleChange={(e) => {
                            setPhoneNumber(e.target.value);
                        }}></TextField>
                    )}
                    {!verifyStep?(
                        <div className="form-group row">
                            <label className="col-sm-2 col-form-label"></label>
                            <div className="col-sm-10">
                                <div ref={(ref)=>recaptcha=ref}></div>
                            </div>
                        </div>
                    ):(<></>)}
                    <SubmitButtons backButtonTo="/user/profile" disabled={!recaptchaVerified} processing={processing} handleClick={(e) => {
                        e.preventDefault();
                        setProcessing(true);
                        if(verifyStep){
                            var cred = firebase.auth.PhoneAuthProvider.credential(verificationId, verificationCode);
                            authUser.user.updatePhoneNumber(cred).then(function(){
                                addLog('updated phone number');
                                setProcessing(false);
                                setFormVisibility(false);
                                setAlert({
                                    'show':true, 
                                    'type':'success',
                                    'message':'Your phone number has been updated. Please click "Back" button to go back to your profile page.',
                                    'count':alert.count+1
                                });
                            }).catch(function(error){
                                setProcessing(false);
                                setAlert({
                                    'show':true, 
                                    'type':'danger',
                                    'message':error.message,
                                    'count':alert.count+1
                                });
                            });
                        }else{
                            setRecaptchaVerified(false);
                            var provider = new firebase.auth.PhoneAuthProvider();
                            provider.verifyPhoneNumber(
                                phoneNumber,
                                window.recaptchaVerifier
                            ).then(function(vid){
                                setProcessing(false);
                                setRecaptchaVerified(true);
                                setVerificationId(vid);
                                setVerifyStep(true);
                            }).catch(function(error){
                                setProcessing(false);
                                setAlert({
                                    'show':true, 
                                    'type':'danger',
                                    'message':error.message,
                                    'count':alert.count+1
                                });
                            })    
                        }
                    }}></SubmitButtons>
                    */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateUserPhone;

import {
  getAuth,
  Firestore,
  getDocs,
  query,
  where,
  collection,
  documentId,
} from "./index";
import apiUi from "./../../api/api-ui";

export const fetchProfileById = (profileId) => {
  const authUserId = getAuth().currentUser.uid;
  return getDocs(
    query(
      collection(Firestore, "profiles"),
      where(documentId(), "==", profileId),
      where("access", "array-contains", authUserId)
    )
  );
};

export const fetchProfile = (data) => {
  return getAuth()
    .currentUser.getIdToken()
    .then((token) => {
      return apiUi.fetchProfile(token, data);
    })
    .then((response) => {
      return response;
    });
};

import React, { useContext } from 'react';
import qs from 'query-string';
import {
    applyActionCode,
    verifyActionCode,
    getAuth,
    signOut
} from './../../../lib/firebase';
import { Link, useHistory } from 'react-router-dom';
import { RESET_PASSWORD, VERIFY_EMAIL } from './../../../constants/firebaseActions';
import { AuthContext } from "./../../../components/auth/FirebaseAuthContext";
import { Alert } from './../../../components/share/InsightUI';
import LoaderWithText from './../../../components/share/LoaderWithText';
import ResetPassword from './ResetPassword';

function ActionPage(props) {
    const { mode, oobCode } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    const [isCodeValid, setIsCodeValid] = React.useState(false);
    const [isVerifying, setIsVerifying] = React.useState(true);
    const [isEmailVerified, setIsEmailVerified] = React.useState(false);
    const [isVerifyingEmail, setIsVerifyingEmail] = React.useState(false);
    const [isCodeVerified, setIsCodeVerified] = React.useState(false);
    const [error, setError] = React.useState('');
    const { authUser } = useContext(AuthContext);
    const history = useHistory();


    const verifyEmail = () => {
        setIsVerifyingEmail(true);
        applyActionCode(oobCode, (response) => {
            setIsVerifyingEmail(false);
            setIsEmailVerified(true);
        }, () => {
            setError('A problem was encountered while verifying your email address');
        })
    }

    React.useEffect(() => {
        if (oobCode) {
            verifyActionCode(oobCode, () => {
                setIsVerifying(false);
                setIsCodeVerified(true);
                setIsCodeValid(true);

                if(mode===VERIFY_EMAIL){
                    verifyEmail();
                }

            }, (error) => {
                setIsVerifying(false);
                setIsCodeVerified(true);
                setIsCodeValid(false);
                setError(error.message);
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="page-container container">
            <Link className="main-logo" to="/">
                <img className=""
                    srcSet="/images/logo@2x.png 2x, /images/logo.png 1x"
                    src="/images/logo.png" alt="" />
            </Link>
            {
                // oobCode is being verified
                isVerifying &&
                <LoaderWithText text="Verifying Action Code" />
            }
            {
                isVerifyingEmail &&
                <LoaderWithText text="Verifying Email address" />
            }
            {
                // oobCode was verified and is Invalid
                (isCodeVerified && !isCodeValid && error && mode===VERIFY_EMAIL) &&
                <>
                    <h1 className="h4 text-gray-900 mt-3 mb-4">Verify email</h1>
                    <Alert variant="danger">Link invalid or expired</Alert>
                    <span>Your email might have been validated already, try <Link to="/signin">sign in</Link></span>
                </>
            }
            {
                // oobCode was verified and is Invalid
                (isCodeVerified && !isCodeValid && error && mode===RESET_PASSWORD) &&
                <>
                    <h1 className="h4 text-gray-900 mt-3 mb-4">Reset password</h1>
                    <Alert variant="danger">Link invalid or expired</Alert>
                    <span>Try <Link to="/signin">sign in</Link></span>
                </>
            }
            {
                (isEmailVerified && mode===VERIFY_EMAIL) &&
                <>
                    <h1 className="h4 text-gray-900 mt-3 mb-4">Email Verified</h1>
                    <Alert variant="success">
                        You can now <Link to="/signin">sign in</Link> with your email and password
                    </Alert>
                </>
            }
            {
                // Reset Password form
                (isCodeVerified && isCodeValid && mode===RESET_PASSWORD) &&
                <ResetPassword code={oobCode} setError={setError} />
            }
            {
                // User is signed in
                authUser.checked && !!authUser.user &&
                <>
                    <div>
                        <hr />
                        <h2 className="h4 text-gray-900 mt-3 mb-4">You are logged in as {authUser.user.email}</h2>
                    </div>
                    <span>
                        Go to <Link to="/">Homepage</Link> or 
                        <a
                            class="pl-2"
                            href="/"
                            onClick={(e) => {
                                e.preventDefault();
                                signOut(getAuth())
                                    .then(() => {
                                    // Sign-out successful.
                                    history.push("/signin");
                                    })
                                    .catch((error) => {
                                    // An error happened.
                                    });
                            }}
                            >
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Sign Out
                        </a>
                    </span>
                </>
            }
        </div>
    )
}

ActionPage.propTypes = {
}

ActionPage.defaultProps = {
}

export default ActionPage
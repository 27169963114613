import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Switch } from "./../../../components/share/InsightUI";
import CustomCheckbox from "../../share/CustomCheckbox";
import { UserDataContext } from "../UserData";
import { DISABLED_SEGMENT_COMPARISON } from "../../../constants/info";
import { FiUnlock } from "react-icons/fi";
import { FaLock, FaChevronUp, FaChevronDown } from "react-icons/fa";
import "./style.scss";
import { defaultSortOrder } from "../../../utils";
import InsightechIcon from "../../share/InsightechIcon";

function SegmentsDropdown(props) {
  const {
    showCompareMode,
    segments,
    selectedSegments,
    setSelectedSegments,
    comparedSelectedSegments,
    setComparedSelectedSegments,
    handleClickSegment,
    setFocusedSegmentId,
    setReloadApi,
    isCompareDisabled,
    showEditSegments,
  } = props;
  const { isSegmentCompareOn, setIsSegmentCompareOn } =
    useContext(UserDataContext);
  const [keyword, setKeyword] = useState("");
  const [searchInputText, setSearchInputText] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [segmentsWithChecked, setSegmentsWithChecked] = useState({});
  const [comparedSegmentsWithChecked, setComparedSegmentsWithChecked] =
    useState({});
  const [selectedSegmentsCnt, setSelectedSegmentsCnt] = useState(0);
  const [comparisonSegmentsCnt, setComparisonSegmentsCnt] = useState(0);

  const dropdownRef = useRef(null);
  let searchTimeout = null;

  // Setup click events
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsVisible(false);
        setSearchInputText("");
        setKeyword("");
        cleanUnsavedChanges();
      }
    };

    // Add outside click handler
    document.addEventListener("mousedown", handleClickOutside);

    // Remove outside click handler on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownRef]);

  // Update the dropdown list check status when selectedSegments update
  useEffect(() => {
    const newSegmentsWithCheck = {};

    Object.keys(segments).forEach((key) => {
      newSegmentsWithCheck[key] = {
        checked: selectedSegments[key] ? true : false,
      };
    });

    setSegmentsWithChecked(newSegmentsWithCheck);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSegments]);

  // Update the compared dropdown list check status when comparedSelectedSegments update
  useEffect(() => {
    if (showCompareMode && isSwitchOn) {
      const newSegmentsWithCheck = {};

      Object.keys(segments).forEach((key) => {
        newSegmentsWithCheck[key] = {
          checked: comparedSelectedSegments[key] ? true : false,
        };
      });

      setComparedSegmentsWithChecked(newSegmentsWithCheck);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedSelectedSegments]);

  // Count the Selected Segments
  useEffect(() => {
    if (!!segmentsWithChecked) {
      setSelectedSegmentsCnt(countCheckedSegments(segmentsWithChecked));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentsWithChecked]);

  // Count the Comparison Segments
  useEffect(() => {
    if (showCompareMode && isSwitchOn && !!comparedSegmentsWithChecked) {
      setComparisonSegmentsCnt(
        countCheckedSegments(comparedSegmentsWithChecked)
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comparedSegmentsWithChecked, isSwitchOn]);

  // clean up states that not applied
  const cleanUnsavedChanges = () => {
    const newSegmentsWithCheck = {};

    Object.keys(segments).forEach((key) => {
      newSegmentsWithCheck[key] = {
        checked: selectedSegments[key] ? true : false,
      };
    });

    if (showCompareMode && (isSwitchOn || isSegmentCompareOn)) {
      const newComparedSegmentsWithCheck = {};

      Object.keys(segments).forEach((key) => {
        newComparedSegmentsWithCheck[key] = {
          checked: comparedSelectedSegments[key] ? true : false,
        };
      });

      setComparedSegmentsWithChecked(newComparedSegmentsWithCheck);
    }

    setSegmentsWithChecked(newSegmentsWithCheck);

    setIsSwitchOn(isSegmentCompareOn);
  };

  // Calculate the count of checked segments
  const countCheckedSegments = (segments) => {
    return Object.values(segments).reduce(
      (count, segment) => (segment.checked ? count + 1 : count),
      0
    );
  };

  const handleSegmentCompare = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  // set checked and unchecked segments
  const handleSegments = (selectedIds, unSelectedIds) => {
    const data = {};
    const updatedSegments = { ...selectedSegments };

    unSelectedIds.forEach((id) => {
      delete updatedSegments[id];
    });

    selectedIds.forEach((id) => {
      data[id] = true; // mark true to checked seg id
    });

    setSelectedSegments({
      ...updatedSegments,
      ...data,
    });
  };

  const handleComparedSegments = (selectedIds, unSelectedIds) => {
    const data = {};
    const updatedSegments = { ...comparedSelectedSegments };

    unSelectedIds.forEach((id) => {
      delete updatedSegments[id];
    });

    selectedIds.forEach((id) => {
      data[id] = true; // mark true to checked seg id
    });

    setComparedSelectedSegments({
      ...updatedSegments,
      ...data,
    });
  };

  const handleCheckboxChange = (e) => {
    const id = e.target.value;
    setSegmentsWithChecked((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], checked: !prevData[id]?.checked },
    }));
  };

  const handleComparedCheckboxChange = (e) => {
    const id = e.target.value;
    setComparedSegmentsWithChecked((prevData) => ({
      ...prevData,
      [id]: { ...prevData[id], checked: !prevData[id]?.checked },
    }));
  };

  const handleKeywordChange = (e) => {
    const value = e.target.value;
    setSearchInputText(value);
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    searchTimeout = setTimeout(() => {
      setKeyword(value);
    }, 800);
  };

  const searchFilter = (segmentId) => {
    const segment = segments[segmentId];
    if (
      segment &&
      `${segment.name}.${segment.tags.toString()}`
        .toLowerCase()
        .indexOf(keyword.toLowerCase()) > -1
    ) {
      return true;
    }
    return false;
  };

  const toggleSegmentsDropdown = () => {
    setIsVisible(!isVisible);
    cleanUnsavedChanges();
  };

  const handleClickEdit = (e) => {
    setFocusedSegmentId(e.currentTarget.dataset.segmentid);
    handleClickSegment();
    setIsVisible(false);
    cleanUnsavedChanges();
  };

  const handleApply = () => {
    const selectedData = Object.keys(segmentsWithChecked)
      .filter((key) => segmentsWithChecked[key].checked)
      .map((key) => key);
    const unSelectedData = Object.keys(segmentsWithChecked)
      .filter((key) => !segmentsWithChecked[key].checked)
      .map((key) => key);
    handleSegments(selectedData, unSelectedData);

    if (showCompareMode) {
      if (!isSwitchOn) {
        setComparedSelectedSegments({});
        setComparedSegmentsWithChecked({});
      } else {
        const selectedComparedData = Object.keys(comparedSegmentsWithChecked)
          .filter((key) => comparedSegmentsWithChecked[key].checked)
          .map((key) => key);
        const unSelectedComparedData = Object.keys(comparedSegmentsWithChecked)
          .filter((key) => !comparedSegmentsWithChecked[key].checked)
          .map((key) => key);
        handleComparedSegments(selectedComparedData, unSelectedComparedData);
      }
    }

    setReloadApi(true);
    setIsVisible(false);
    setIsSegmentCompareOn(isSwitchOn);
  };

  return (
    <div
      ref={dropdownRef}
      className="segment-dropdown-wrapper mb-0 list-toggle dropdown"
    >
      <button
        className="btn-grey"
        id="segments-dropdown"
        aria-haspopup="true"
        aria-expanded={isVisible}
        onClick={toggleSegmentsDropdown}
      >
        <span className="icon">
          <InsightechIcon name="Group" />
        </span>
        Segments{" "}
        <span className="chevron-icon">
          {isVisible ? <FaChevronUp /> : <FaChevronDown />}
        </span>
      </button>
      <div
        className={`dropdown-menu dropdown-menu-md-left shadow segments-dropdown
          animated--grow-in ${isVisible ? "show" : ""}`}
        aria-labelledby="segments-dropdown"
      >
        <div className="segment-search">
          <span className="fa fa-search"></span>
          <input
            type="search"
            className="form-control form-control-sm"
            onChange={handleKeywordChange}
            value={searchInputText}
            placeholder="Search Segment"
          />
        </div>
        <div className="segments-table-wrapper">
          <table className="table segments-table">
            <thead className="thead-dark">
              <tr>
                <th scope="col"></th>
                {showCompareMode && isSwitchOn && <th scope="col"></th>}
                <th scope="col" width="50%">
                  Name
                </th>
                <th scope="col" width="50%">
                  Tags
                </th>
                <th scope="col" style={{ whiteSpace: "nowrap" }}>
                  Shared Level
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {!!Object.keys(segments).length &&
                Object.keys(segments)
                  .filter(searchFilter)
                  .map((id) => segments[id])
                  .sort(defaultSortOrder("name"))
                  .map((segment) => {
                    return segment ? (
                      <tr key={segment.id}>
                        <td>
                          <CustomCheckbox
                            id={segment.id}
                            value={segment.id}
                            onChange={handleCheckboxChange}
                            checked={
                              segmentsWithChecked[segment.id]?.checked || false
                            }
                          />
                        </td>
                        {showCompareMode && isSwitchOn && (
                          <td>
                            <CustomCheckbox
                              id={`compare-${segment.id}`}
                              value={segment.id}
                              onChange={handleComparedCheckboxChange}
                              checked={
                                comparedSegmentsWithChecked[segment.id]
                                  ?.checked || false
                              }
                              variant={"compared"}
                            />
                          </td>
                        )}
                        <td>{segment.name}</td>
                        <td>
                          {segment.tags.map((tag, index) => {
                            return (
                              <span
                                key={index}
                                className="badge badge-pill
                                  badge-primary"
                              >
                                {tag}
                              </span>
                            );
                          })}
                        </td>
                        <td align="center">
                          {segment.shared ? <FiUnlock /> : <FaLock />}
                        </td>
                        {showEditSegments && (
                          <td>
                            <Button
                              onClick={handleClickEdit}
                              data-segmentid={segment.id}
                              size="small"
                              variant="plain"
                            >
                              Edit
                            </Button>
                          </td>
                        )}
                      </tr>
                    ) : null;
                  })}
              {!Object.keys(segments).length && (
                <tr>
                  <td colSpan="4" align="center">
                    No Available Segments
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="dropdown-footer d-flex align-items-center justify-content-between">
          <div className="ml-2 mt-1">
            <p className="mt-1 mb-1">
              <span className="strip strip-blue mr-2"></span>
              <span>
                Selected Segments: <b>{selectedSegmentsCnt}</b>
              </span>
            </p>

            {showCompareMode && isSwitchOn && (
              <p className="mt-2 mb-0">
                <span className="strip strip-orange mr-2"></span>
                <span>
                  Comparison Segments: <b>{comparisonSegmentsCnt}</b>
                </span>
              </p>
            )}
          </div>
          <div className="d-flex flex-column align-items-end">
            {showCompareMode && (
              <div className="d-flex align-items-center mr-n2 mt-0">
                <label htmlFor="compare" className="mt-2 mr-2">
                  Compare Segments
                </label>
                <Switch
                  variant="compare-switch"
                  name="compare-segment"
                  id="compare-segment"
                  isChecked={isSwitchOn}
                  onChange={handleSegmentCompare}
                  disabled={isCompareDisabled}
                  tooltip={
                    isCompareDisabled ? DISABLED_SEGMENT_COMPARISON : null
                  }
                />
              </div>
            )}
            <Button
              style={{ minWidth: 155 }}
              size="small"
              variant="primary"
              onClick={handleApply}
            >
              Apply
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

SegmentsDropdown.propTypes = {
  segments: PropTypes.object,
  selectedSegments: PropTypes.object,
  segmentsWithChecked: PropTypes.object,
  handleSegments: PropTypes.func,
};
SegmentsDropdown.defaultProps = {};

export default SegmentsDropdown;

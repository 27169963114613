// Translate report type to human readable string
export const reportTypes = {
  dashboard: "Dashboard",
  page_list: "Page Analysis - All Pages",
  landing_page_list: "Page Analysis - Landing Pages",
  form_list: "Form Analysis",
  funnel_list: "Funnel List",
  funnel_view: "Funnel View",
  pathing: "Pathing",
  replay_list: "Replay List",
  external: "External report",
};

import React, { useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "./../../lib/firebase";
import { captureExceptionToSentry, pushUserSignInEvent } from "../../utils";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({
    user: null,
    checked: false,
    token: null,
    isAdmin: false,
    role: null,
  });

  useEffect(() => {
    onAuthStateChanged(getAuth(), function (user) {
      if (user !== null) {
        user.getIdTokenResult().then((idTokenResult) => {

          // Get custom claims, prevent user.getIdTokenResult() that calls server in emulator mode, not sure in production
          //const customAttributes = user.reloadUserInfo.customAttributes? JSON.parse(user.reloadUserInfo.customAttributes) : {};
          const customAttributes = idTokenResult.claims;
          let role = null;
          if (customAttributes && customAttributes.role) {
            role = customAttributes.role;
          }

          setAuthUser({
            user: user,
            checked: true,
            token: idTokenResult.token,
            isAdmin: false,
            role: role,
          });
          pushUserSignInEvent(user);
        })
        .catch((error) => {
          console.error("Error getting custom claims: ", error);
          captureExceptionToSentry(error);
        });
      } else {
        setAuthUser({
          user: null,
          checked: true,
          token: null,
          isAdmin: false,
          role: null,
        });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

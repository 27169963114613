import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import { AuthContext } from "../../../components/auth/FirebaseAuthContext";
import Alert from "../../../components/share/Alert";
import { Form, Input, Button } from "./../../../components/share/InsightUI";
import { signinFormValidator } from "../../../helpers/formValidators";
import {
  updateUser,
  addLog,
  getAuth,
  EmailAuthProvider,
} from "./../../../lib/firebase";
import { UPDATE_EMAIL } from "./../../../constants/log";

const UpdateUserEmail = () => {
  const { authUser } = useContext(AuthContext);
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });
  const [initialValues, setInitialValues] = useState({
    email: authUser.user.email || "",
    password: "",
  });

  const handleSubmit = (values, { setSubmitting }) => {
    const credential = EmailAuthProvider.credential(
      getAuth().currentUser.email,
      values.password
    );

    authUser.user
      .reauthenticateWithCredential(credential)
      .then(() => {
        authUser.user
          .updateEmail(values.email)
          .then(function () {
            updateUser({ email: values.email });
            addLog(UPDATE_EMAIL);
            setSubmitting(false);
            setInitialValues({
              email: values.email,
              password: "",
            });
            // Send verification email for this user
            authUser.user
              .sendEmailVerification()
              .then(() => {
                setAlert({
                  show: true,
                  type: "success",
                  message:
                    'Your email address has been updated. Please click "Back" button to go back to your profile page.',
                  count: alert.count + 1,
                });
              })
              .catch((error) => {
                setAlert({
                  show: true,
                  type: "danger",
                  message: error.message,
                  count: alert.count + 1,
                });
              });
          })
          .catch(function (error) {
            setSubmitting(false);
            setAlert({
              show: true,
              type: "danger",
              message: error.message,
              count: alert.count + 1,
            });
          });
      })
      .catch(() => {
        setSubmitting(false);
        setAlert({
          ...alert,
          type: "danger",
          show: true,
          message: "The password you provided is invalid",
        });
      });
  };

  return (
    <>
      <div className="row">
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      </div>
      <div className="row">
        <div className="col mb-4">
          <div className="card shadow mb-4">
            <div className="card-body">
              <Formik
                initialValues={initialValues}
                validate={signinFormValidator}
                validateOnBlur={false}
                validateOnChange={true}
                onSubmit={handleSubmit}
              >
                {({
                  dirty,
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form className="user" handleSubmit={handleSubmit}>
                    <Input
                      inline
                      columnwidths={[2, 4]}
                      isInvalid={!!(touched.email && errors.email)}
                      invalidFeedback={errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                      value={values.email || ""}
                      label="Email Address"
                      placeholder="Enter your new email address"
                    />
                    <Input
                      inline
                      columnwidths={[2, 4]}
                      isInvalid={!!(touched.password && errors.password)}
                      invalidFeedback={errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="password"
                      value={values.password || ""}
                      label="Re-enter Password"
                      type="password"
                      placeholder="Re-enter your password"
                    />
                    <div className="form-group row">
                      <div className="col-sm-2">&nbsp;</div>
                      <div className="col-sm-4">
                        <Button
                          disabled={
                            isSubmitting || initialValues.email === values.email
                          }
                          isprocessing={isSubmitting}
                          type="submit"
                          variant="primary"
                        >
                          Submit
                        </Button>
                        {` `}
                        <Link to="/user/profile" className="btn btn-secondary">
                          Back
                        </Link>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>

          {/*
                    <div className="card shadow mb-4">
                        <div className="card-body">
                        <EmailField id="email-address" label="Email Address" email={emailAddress} placeholder="Your email address" helpText="Please put in your new email address." handleChange={(e) => {
                            setEmailAddress(e.target.value);
                        }}></EmailField>
                        <SubmitButtons processing={processing} backButtonTo="/user/profile" handleClick={(e) => {
                            e.preventDefault();
                            setProcessing(true);
                            authUser.user.updateEmail(emailAddress).then(function () {
                            updateUser({ 'email': emailAddress });
                            addLog('updated email address');
                            setProcessing(false);
                            setFormVisibility(false);
                            setAlert({
                                'show': true,
                                'type': 'success',
                                'message': 'Your email address has been updated. Please click "Back" button to go back to your profile page.',
                                'count': alert.count + 1
                            });
                            }).catch(function (error) {
                            setProcessing(false);
                            setAlert({
                                'show': true,
                                'type': 'danger',
                                'message': error.message,
                                'count': alert.count + 1
                            });
                            });
                        }}></SubmitButtons>
                        </div>
                    </div>
                    */}
        </div>
      </div>
    </>
  );
};

export default UpdateUserEmail;

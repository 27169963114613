import React, { useContext, useEffect, useRef, useState } from "react";
import * as Sentry from "@sentry/react";
import PropTypes from "prop-types";
import { withRouter, useParams } from "react-router-dom";
import { Button, Drawer, Modal } from "./../../share/InsightUI";
import Alert from "../../share/Alert";
import ReactJson from "react-json-view";
import NoteModal from "./../../app/NoteModal";
import OverlayScreen from "./../OverlayScreen";
import { AuthContext } from "./../../auth/FirebaseAuthContext";
import { SegmentsContext } from "././../../../context/Segments";
import { UserDataContext } from "./../UserData";
import { dateRanges } from "../../../helpers/dateRange";
import api from "./../../../api";
import {
  fetchSessionNotes,
  deleteNoteById,
} from "./../../../lib/firebase/notes";
import { fetchUsersByProfileId } from "./../../../lib/firebase/user";
import moment from "moment";
import { eventTypes } from "./../../../helpers/replayEventTypes";
import { replayKeyboardEvents } from "../../../helpers/replayKeyboardEvents";
import ReplayComponent from "./ReplayComponent";
import FilterDrawer from "./../FilterDrawer";
import {
  deepCopy,
  overrideStyle,
  saveUserPrefs,
  getUserPrefs,
  setDateRangeLabel,
  captureExceptionToSentry,
} from "./../../../utils";
import {
  ERRORS_MAPPED
} from "../../../constants/error";
import {
  getCSSPath,
  formatCss,
  querySelector,
  querySelectorAll,
  getAllShadowRoots,
  replayMessageContent,
  getDocumentMaxHeight,
  createOverlayAndHighlightLayer,
  appendElementsToBody,
  createHighlight,
  findDesendentElements,
  getDataFromElementCSSPath,
} from "./../../../utils/replay";
import { ReducerContext } from "./../../../reducer/context";
import {
  OPEN_CONFIRM,
  CLOSE_CONFIRM,
  CONFIRM_BUSY,
  CONFIRM_IDLE,
} from "./../../../reducer/index";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import "./style.scss";
import { renderEventBackgroundColor } from "../../../helpers/replayEventBgStyle";
import ElementStats from "./components/ElementStats";
import ReplaySidePanelTabs from "./ReplaySidePanelTabs";

const overlayBg = "rgba(0,0,0, .6)";
const today = moment();
const analyticsModesObj = require("../../../inc/analyticsModes.json");

const initialListFilterConditions = {
  userConditions: [
    {
      attribute: "",
      path: "",
      unit: "",
      operator: "",
      values: [""],
    },
  ],

  pageContainers: [
    {
      inclusion: true,
      eventConditions: [
        {
          type: "",
          conditions: [
            {
              attribute: "CSSSelector",
              path: "",
              unit: "",
              operator: "is",
              values: [""],
            },
          ],
        },
      ],
    },
    {
      inclusion: false,
      eventConditions: [
        {
          type: "",
          conditions: [
            {
              attribute: "CSSSelector",
              path: "",
              unit: "",
              operator: "is",
              values: [""],
            },
          ],
        },
      ],
    },
  ],
};

function ReplayViewer(props) {
  const { handleClickCancel, visitedReplays, setVisitedReplays, liteMode } =
    props;
  const { profileId, visitorId, sessionNumber } = useParams();
  const { search, state } = useLocation();

  //parse the state parameters
  const autoPlay = state?.autoPlay;

  //parse the search parameters
  const searchParams = new URLSearchParams(search);
  const pid = searchParams.get("pageview");
  const eid = searchParams.get("event");

  const userPrefs = getUserPrefs(profileId);
  const initialEventFilters =
    userPrefs && userPrefs.data && userPrefs.data.eventFilters
      ? {
          map: userPrefs.data.eventFilters,
          list: Object.keys(userPrefs.data.eventFilters),
        }
      : {
          map: {
            1: true,
            2: true,
            3: true,
            4: true,
            7: true,
            8: true,
            9: true,
            13: true,
            14: true,
            15: true,
            98: true,
          },
          list: ["1", "2", "3", "4", "7", "8", "9", "13", "14", "15", "98"],
        };

  const initialSkipIdle =
    userPrefs && userPrefs.data && userPrefs.data.hasOwnProperty("skipIdle")
      ? userPrefs.data.skipIdle
      : true;
  const initialPlaybackSpeed =
    userPrefs && userPrefs.data && userPrefs.data.playbackSpeed
      ? userPrefs.data.playbackSpeed
      : 1;

  const { authUser } = useContext(AuthContext);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setSelectedStartDate,
    setSelectedEndDate,
    selectedDateRange,
    setSelectedDateRange,
    profileUsers,
    setProfileUsers,
    apiServer,
    isApiServerLoading,
  } = useContext(UserDataContext);
  const { segments, setSegments, selectedSegments, setSelectedSegments } =
    useContext(SegmentsContext);
  const { dispatch } = useContext(ReducerContext);

  const [duration, setDuration] = useState(0);
  const [pageviews, setPageviews] = useState(null);
  const [skipPageviews, setSkipPageviews] = useState({});
  const [activePageviewIndex, setActivePageviewIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [formatVersion, setFormatVersion] = useState(2);
  const [initialTimestamp, setInitialTimestamp] = useState(0);
  const [focusedJSON, setFocusedJSON] = useState(null);
  const [focusedEventTitle, setFocusedEventTitle] = useState("");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [playSpeed, setPlaySpeed] = useState(initialPlaybackSpeed);
  const [skipIdle, setSkipIdle] = useState(initialSkipIdle);
  const [playing, setPlaying] = useState(false);
  const [isReplayViewerVisible, setIsReplayViewerVisible] = useState(false); // set flase if other layer, drawer, modal active
  const [progress, setProgress] = useState(0);
  const [pageUrl, setPageUrl] = useState("");
  const [eventFilters, setEventFilters] = useState(initialEventFilters);
  const [isEventsFilterModalVisible, setIsEventsFilterModalVisible] =
    useState(false);
  const eventComponents = [];
  let eventsCount = 0;
  const [filterConditions, setFilterConditions] = useState([
    {
      type: "VisitPage",
      inclusion: true,
      conditions: [
        {
          attribute: "PageURL",
          path: "",
          unit: "",
          operator: "is",
          values: [pageUrl],
        },
      ],
    },
  ]);

  const filterModalElementContextFixture = {
    element: null,
    text: "",
  };

  const [hovered, setHovered] = useState(null);
  const [hoveredData, setHoveredData] = useState(null);

  const [activeTab, setActiveTab] = useState("details");

  /* Analytics */
  const [analyticsMode, setAnalyticsMode] = useState("elements");
  const [analyticsClickmapMode, setAnalyticsClickmapMode] = useState("links");
  const [analyticsFormAnalysisMode, setAnalyticsFormAnalysisMode] =
    useState("lastClicks");
  const [analyticsFrictionmapMode, setAnalyticsFrictionmapMode] =
    useState("rageClicks");
  const [noDataAlert, setNoDataAlert] = useState(false);

  /* Clickmap */
  const [isClickmapDataLoading, setIsClickmapDataLoading] = useState(false);
  const [isClickmapDataLoaded, setIsClickmapDataLoaded] = useState({});
  const [clickmapData, setClickmapData] = useState({});
  const [clickmapLinks, setClickmapLinks] = useState({});
  const [isRawClickmapDataLoaded, setIsRawClickmapDataLoaded] = useState({});
  const [rawClickmapData, setRawClickmapData] = useState({});
  const [clickmapDataFilter, setClickmapDataFilter] = useState({
    lable: "All",
    value: "all",
  });
  const [allClickmapData, setAllClickmapData] = useState();
  const clickmapLinksRef = useRef(clickmapLinks); // ref to store clickmapLinks

  /* Scrolling Heatmap */
  const [isScrollmapDataLoading, setIsScrollmapDataLoading] = useState(false);
  const [isScrollmapDataLoaded, setIsScrollmapDataLoaded] = useState({});
  const [scrollmapData, setScrollmapData] = useState({});
  const [mouseData, setMouseData] = useState({
    x: 0,
    y: 0,
    pageViews: "",
  });
  const scrollingHeatMapMousemoveRef = useRef();
  const scrollingHeatMapMousedownRef = useRef();

  /* Drawer */
  const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false);
  const [isDrawerFetchingData, setIsDrawerFetchingData] = useState(false);
  const [selectedDates, setSelectedDates] = useState({
    startDate: startDate,
    endDate: endDate,
  });

  /* Override State */
  const [isInitialized, setIsInitialized] = useState(false);

  /* Filter Modal */
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [filterModalClickmapId, setFilterModalClickmapId] = useState(null);
  const [filterElementData, setFilterElementData] = useState(null); // clicked element data for filter modal

  const [filterModalElementContext, setFilterModalElementContext] = useState(
    deepCopy(filterModalElementContextFixture)
  );
  const [listFilterMode, setListFilterMode] = useState("click");
  const [listFilterScrollPixel, setListFilterScrollPixel] = useState(0);
  const [listFilterConditions, setListFilterConditions] = useState(
    deepCopy(initialListFilterConditions)
  );
  const [listFilterAction, setListFilterAction] = useState("CLICK");

  const [replayIframe, setReplayIframe] = useState(null);
  const [iframeElements, setIframeElements] = useState([]);

  /* Notes */
  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [isNotesLoaded, setIsNotesLoaded] = useState(false);
  const [isNotesLoading, setIsNotesLoading] = useState(false);
  const [focusedNoteId, setFocusedNoteId] = useState(null);
  const [notes, setNotes] = useState({
    byId: {},
    all: [],
  });
  const [noteUrlId, setNoteUrlId] = useState(null);
  const [cantLoadReplay, setCantLoadReplay] = useState(false);
  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  const disableInspectHandlerRef = useRef();
  const inspectedElementRef = useRef();
  const intervalRef = useRef();
  const clickmapHighlightClickRef = useRef();
  const playRef = useRef(); // ref to the play()
  const progressRef = useRef(0); // ref to the progress value, progress bar slider value
  const stepInterval = useRef(50 / playSpeed); // ms
  const eventStartTimeRef = useRef(0);
  const playTimeoutRef = useRef(); // ref to store play timeout ID
  const fetchClickmapTimeoutRef = useRef(); // ref to store fetch timeout ID
  const handleScrollRef = useRef(); // ref to store handleScroll function

  let replayScreen = null;

  let nodes = []; // dom nodes
  let currentPageviewIndex = 0;
  let currentPageviewStartingDuration = 0;
  let mouseDiv = null;
  let top = 0;
  let left = 0;
  let scale = 0;
  let timeout = null;

  const processAnalyticsMode = () => {
    if (!analyticsMode) return "moment";
    if (analyticsMode === "clickmap")
      return `clickmap-${analyticsClickmapMode}`;
    if (analyticsMode === "form-analysis")
      return `formAnalysis-${analyticsFormAnalysisMode}`;
    if (analyticsMode === "frictionmap")
      return `frictionmap-${analyticsFrictionmapMode}`;

    // Otherwise, return the single mode as is: elements, scrollmap.
    return analyticsMode;
  };

  const addFoldLines = (parentLayer, overlayLayer, foldLinesOnly = true) => {
    const doc = replayIframe.contentWindow.document;
    const line = doc.createElement("div");
    const label = doc.createElement("span");
    const maxHeight = parseInt(parentLayer.style.height, 10);

    overrideStyle(line, {
      borderBottom: "1px solid #fff",
      position: "absolute",
      top: `${
        scrollmapData[pageUrl].minFold <= maxHeight
          ? scrollmapData[pageUrl].minFold
          : maxHeight
      }px`,
      left: 0,
      right: 0,
      zIndex: 999999,
    });

    const minLine = line.cloneNode();
    const medianLine = line.cloneNode();
    const maxLine = line.cloneNode();

    medianLine.style.top = `${
      scrollmapData[pageUrl].medianFold <= maxHeight
        ? scrollmapData[pageUrl].medianFold
        : maxHeight
    }px`;
    maxLine.style.top = `${
      scrollmapData[pageUrl].maxFold <= maxHeight
        ? scrollmapData[pageUrl].maxFold
        : maxHeight
    }px`;

    if (overlayLayer) {
      /* Add gradient */
      let shownPageviews = 0;
      let overlayGradientStyle = "linear-gradient(180deg";

      if (scrollmapData[pageUrl].scrollData.length) {
        scrollmapData[pageUrl].scrollData.forEach((scrollData) => {
          if (scrollData.spv) {
            const height = scrollData.max;
            const pct =
              (scrollmapData[pageUrl].totalPageviews - shownPageviews) /
              scrollmapData[pageUrl].totalPageviews;
            const red = pct * 255;
            const blue = (1 - pct) * 255;
            overlayGradientStyle += `, rgba(${red},0,${blue}, .6) ${height}px`;
            shownPageviews += scrollData.spv;
          }
        });

        overlayGradientStyle += ", rgba(0,0,0, .6) 100%)";
        overlayLayer.style.backgroundColor = "transparent";
        overlayLayer.style.background = overlayGradientStyle;
      } else {
        overlayLayer.style.backgroundColor = "rgba(0,0,0, .6)";
      }
    }

    /* Fold Labels */
    overrideStyle(label, {
      fontSize: "24px",
      fontWeight: 400,
      display: "inline-block",
      backgroundColor: "#fff",
      padding: "5px 10px",
      color: "#000",
      position: "absolute",
      bottom: 0,
    });

    const minLabel = label.cloneNode();
    const medianLabel = label.cloneNode();
    const maxLabel = label.cloneNode();

    minLabel.innerText = "Minimum Fold";
    maxLabel.innerText = "Maximum Fold";
    medianLabel.innerText = "Median Fold";

    minLabel.style.right = 0;
    maxLabel.style.left = 0;
    medianLabel.style.left = "50%";
    medianLabel.style.transform = "translateX(-50%)";

    /* Append elements */
    minLine.appendChild(minLabel);
    maxLine.appendChild(maxLabel);
    medianLine.appendChild(medianLabel);

    parentLayer.appendChild(medianLine);
    parentLayer.appendChild(minLine);
    parentLayer.appendChild(maxLine);

    if (!foldLinesOnly) {
      const rulerLabel = label.cloneNode();
      rulerLabel.innerText = "100% pageviews reached this point";
      rulerLabel.id = "scrollingHeatmapRulerLabel";

      const ruler = line.cloneNode();
      ruler.id = "scrollingHeatmapRuler";
      ruler.style.borderColor = "#000";

      overrideStyle(rulerLabel, {
        bottom: "auto",
        color: "#fff",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#000",
      });
      ruler.appendChild(rulerLabel);
      parentLayer.appendChild(ruler);
    }
  };

  const scrollingHeatMapMousemove = (e) => {
    const ruler = replayIframe.contentWindow.document.getElementById(
      "scrollingHeatmapRuler"
    );
    const rulerLabel = replayIframe.contentWindow.document.getElementById(
      "scrollingHeatmapRulerLabel"
    );
    if (ruler !== null && rulerLabel !== null) {
      let aggregatedViews = 0;
      const yOffset = e.clientY + replayIframe.contentWindow.scrollY;

      ruler.style.top = `${yOffset}px`;

      scrollmapData[pageUrl].scrollData.forEach((data) => {
        if (data.max <= yOffset) {
          aggregatedViews += data.spv;
        }
      });

      const viewsDiff = scrollmapData[pageUrl].totalPageviews - aggregatedViews;
      let pageViewsPct = Math.round(
        (viewsDiff / scrollmapData[pageUrl].totalPageviews) * 100
      );
      pageViewsPct = pageViewsPct ? pageViewsPct : 0;
      rulerLabel.innerText = pageViewsPct + "% pageviews reached this point";

      setMouseData({
        ...mouseData,
        x: e.clientX,
        y: yOffset,
        pageViews: `${pageViewsPct}%`,
      });
    }
  };

  const scrollmapClick = (e) => {
    const yOffset = e.clientY + replayIframe.contentWindow.scrollY;
    setListFilterScrollPixel(Math.floor(yOffset));
    setListFilterMode("scroll");
    setIsFilterModalVisible(true);
    setIsReplayViewerVisible(false);
  };

  const enableScrollingHeatmap = () => {
    const doc = replayIframe.contentWindow.document;
    const newIframeElements = [];
    const overlay = doc.createElement("div");
    overlay.id = "is-replay-overlay";
    const highlightLayer = doc.createElement("div");
    highlightLayer.id = "is-replay-highlight-layer";

    if (doc.body === null) {
      return;
    }

    const maxHeight = Math.max(
      doc.body.scrollHeight,
      doc.body.offsetHeight,
      doc.documentElement.clientHeight,
      doc.documentElement.offsetHeight,
      doc.documentElement.scrollHeight
    );

    overrideStyle(overlay, {
      width: "100%",
      position: "fixed",
      top: `${replayIframe.contentWindow.scrollY * -1}px`,
      right: 0,
      left: 0,
      height: `${maxHeight}px`,
      backgroundColor: "rgba(255,100,100, .75)",
      zIndex: 2147483648,
    });

    overrideStyle(highlightLayer, {
      width: "100%",
      position: "fixed",
      top: `${replayIframe.contentWindow.scrollY * -1}px`,
      right: 0,
      height: `${maxHeight}px`,
      left: 0,
      zIndex: 2147483649, // Should be higher than "overlay"
    });

    addFoldLines(highlightLayer, overlay, false);

    newIframeElements.push(overlay);
    newIframeElements.push(highlightLayer);

    doc.body.appendChild(overlay);
    doc.body.appendChild(highlightLayer);

    doc.addEventListener("mousemove", scrollingHeatMapMousemove);
    doc.addEventListener("mousedown", scrollmapClick);
    scrollingHeatMapMousemoveRef.current = scrollingHeatMapMousemove;
    scrollingHeatMapMousedownRef.current = scrollmapClick;

    setIframeElements(newIframeElements);
  };

  // Process selected element data for representation
  const processSelectedElementData = ({ eleId, setter, data }) => {
    if (clickmapData[pageUrl] && eleId) {
      const views = clickmapData[pageUrl].totals.pageViews;
      if (data) {
        const element = querySelector(
          replayIframe?.contentWindow?.document,
          formatCss(data?.css)
        );
        setter({
          hovers: data.hov,
          hoversPct: data.hoverPct,
          clicks: data.clc,
          clicksPct: data.clickPct,
          rageClicks: data.rgc,
          rageClicksPct: data.rgcPct,
          lastClicks: data.lfc,
          lastClicksPct: data.lfcPct,
          conversions: data.con,
          conversionsPct: data.conPct,
          revenue: data.rev,
          revenueAvg: data.revAvg,
          path: data.css,
          tag: element?.tagName,
          innerText: element?.innerText || "",
          totalPageviews: views,
          clickedSessions: data.clcSess,
        });
      } else {
        const element = querySelector(
          replayIframe?.contentWindow?.document,
          formatCss(eleId)
        );
        setter({
          hovers: 0,
          hoversPct: `0.00%`,
          clicks: 0,
          clicksPct: `0.00%`,
          rageClicks: 0,
          rageClicksPct: `0.00%`,
          lastClicks: 0,
          lastClicksPct: `0.00%`,
          conversions: 0,
          conversionsPct: `0.00%`,
          revenue: 0,
          revenueAvg: 0,
          path: eleId,
          tag: element?.tagName,
          innerText: element?.innerText || "",
          totalPageviews: views,
          clickedSessions: 0,
        });
      }
    }
  };

  const clickmapHighlightClick = (e) => {
    const id = e.target.dataset["clickmapid"];
    if (id) {
      setFilterModalClickmapId(id);
      processSelectedElementData({
        eleId: id,
        setter: setFilterElementData,
        data: clickmapLinks[id]?.data,
      });
      setIsFilterModalVisible(true);
      setIsReplayViewerVisible(false);
    }
  };

  const filterClickmapData = (highlightedLinksMap) => {
    // If filter is 'all', return the original map immediately
    if (clickmapDataFilter.value === "all") {
      return highlightedLinksMap;
    }

    const filteredHighlightedLinksMap = {};

    Object.keys(highlightedLinksMap).forEach((key, index) => {
      if (index >= parseInt(clickmapDataFilter.value)) {
        return;
      }
      filteredHighlightedLinksMap[key] = highlightedLinksMap[key];
    });

    return filteredHighlightedLinksMap;
  };

  // Utility function to safely get clickmap elementData
  const getClickmapElementData = (data) => {
    if (data && data[0]) {
      return data[0]?.elementData;
    } else {
      console.error(
        `rawClickmapData for pageUrl ${pageUrl} is null or undefined`
      );
      return [];
    }
  };

  const aggregateLinkElements = (doc) => {
    // Aggregate link's child elements
    const links = querySelectorAll(doc, "A");
    let linkData = [];
    for (let i = 0; i < links.length; i++) {
      let data = {
        hovers: 0,
        clicks: 0,
        rageClicks: 0,
        conversions: 0,
        revenue: 0.0,
        element: null,
        elementCSSPath: "",
        elementClicks: 0,
        descendents: [],
        clickedSessions: 0,
      };
      const link = links[i];
      // find all chilren of the link element
      const descendents = findDesendentElements(link);
      data.element = link;
      data.elementCSSPath = getCSSPath(link);
      data.hovers = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).hov;
      data.clicks = data.elementClicks = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).clc;
      data.rageClicks = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).rgc;
      data.conversions = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).con;
      data.revenue = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).rev;
      data.clickedSessions = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).clcSess;

      for (let j = 0; j < descendents.length; j++) {
        let desc = {
          element: null,
          elementCSSPath: "",
          elementHovers: 0,
          elementClicks: 0,
          elementRageClicks: 0,
          elementConversion: 0,
          elementRevenue: 0,
          elementClickedSessions: 0,
        };
        desc.element = descendents[j];
        desc.elementCSSPath = getCSSPath(desc.element);

        desc.elementHovers = getDataFromElementCSSPath(
          desc.elementCSSPath,
          getClickmapElementData(rawClickmapData[pageUrl])
        ).hov;
        data.hovers = data.hovers + desc.elementHovers;

        desc.elementClicks = getDataFromElementCSSPath(
          desc.elementCSSPath,
          getClickmapElementData(rawClickmapData[pageUrl])
        ).clc;
        data.clicks = data.clicks + desc.elementClicks;

        desc.elementRageClicks = getDataFromElementCSSPath(
          desc.elementCSSPath,
          getClickmapElementData(rawClickmapData[pageUrl])
        ).rgc;
        data.rageClicks = data.rageClicks + desc.elementRageClicks;

        desc.elementConversion = getDataFromElementCSSPath(
          desc.elementCSSPath,
          getClickmapElementData(rawClickmapData[pageUrl])
        ).con;
        data.conversions = data.conversions + desc.elementConversion;

        desc.elementRevenue = getDataFromElementCSSPath(
          desc.elementCSSPath,
          getClickmapElementData(rawClickmapData[pageUrl])
        ).rev;
        data.revenue = data.revenue + desc.elementRevenue;

        desc.elementClickedSessions = getDataFromElementCSSPath(
          desc.elementCSSPath,
          getClickmapElementData(rawClickmapData[pageUrl])
        ).clcSess;
        data.clickedSessions =
          data.clickedSessions + desc.elementClickedSessions;

        data.descendents.push(desc);
      }
      if (data.clicks > 0) {
        linkData.push(data);
      }
    }

    linkData.sort((a, b) => {
      return b.clicks - a.clicks;
    });

    //Filter sort link data

    const sortedLinksMap = {};
    // todo : cache replayIframe.contentWindow.pageYOffset
    linkData.forEach((data) => {
      const key = getCSSPath(data.element);
      const rect = data.element.getBoundingClientRect();
      let linkEle = {
        data: {
          clc: data.clicks,
          clickPct: `${(
            (data.clicks / rawClickmapData[pageUrl][0].totalPageviews) *
            100
          ).toFixed(2)}%`,
          css: data.elementCSSPath,
          hov: data.hovers,
          hoverPct: `${(
            (data.hovers / rawClickmapData[pageUrl][0].totalPageviews) *
            100
          ).toFixed(2)}%`,
          rgc: data.rageClicks,
          rgcPct: `${(
            (data.rageClicks / rawClickmapData[pageUrl][0].totalPageviews) *
            100
          ).toFixed(2)}%`,
          key: key,
          lec: 0,
          lecPct: "0.00%",
          lfc: 0,
          lfcPct: "0.00%",
          tag: data.element.tagName,
          con: data.conversions,
          conPct: `${((data.conversions / data.clickedSessions) * 100).toFixed(
            2
          )}%`,
          rev: data.revenue,
          revAvg: data.revenue / data.conversions || 0,
          clcSess: data.clickedSessions,
        },
        style: {
          width: `${data.element.offsetWidth}px`,
          height: `${data.element.offsetHeight}px`,
          left: `${rect.left}px`,
          top: `${rect.top + replayIframe.contentWindow.pageYOffset}px`,
        },
        targetElement: data.element,
      };
      sortedLinksMap[key] = linkEle;
    });

    return sortedLinksMap;
  };

  const aggregateButtonElements = (doc, filterBy, sortBy) => {
    // Aggregate button's child elements
    const btns = querySelectorAll(doc, "BUTTON");
    let btnData = [];

    btns.forEach((btn) => {
      let data = {
        clicks: 0,
        lastClicks: 0,
        hover: 0,
        conversions: 0,
        revenue: 0.0,
        element: btn,
        elementCSSPath: getCSSPath(btn),
        elementClicks: 0,
        descendents: [],
      };

      // Find all children of this button element
      const descendents = findDesendentElements(btn);

      data.clicks = data.elementClicks = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).clc;

      data.lastClicks = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).lfc;

      data.hover = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).hov;

      data.conversions = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).con;

      data.revenue = getDataFromElementCSSPath(
        data.elementCSSPath,
        getClickmapElementData(rawClickmapData[pageUrl])
      ).rev;

      // Aggregate data from button and its descendents
      descendents.forEach((desc) => {
        const descData = {
          element: desc,
          elementCSSPath: getCSSPath(desc),
          elementClicks: getDataFromElementCSSPath(
            getCSSPath(desc),
            getClickmapElementData(rawClickmapData[pageUrl])
          ).clc,
          elementLastClicks: getDataFromElementCSSPath(
            getCSSPath(desc),
            getClickmapElementData(rawClickmapData[pageUrl])
          ).lfc,
          elementHover: getDataFromElementCSSPath(
            getCSSPath(desc),
            getClickmapElementData(rawClickmapData[pageUrl])
          ).hov,
          elementConversion: getDataFromElementCSSPath(
            getCSSPath(desc),
            getClickmapElementData(rawClickmapData[pageUrl])
          ).con,
          elementRevenue: getDataFromElementCSSPath(
            getCSSPath(desc),
            getClickmapElementData(rawClickmapData[pageUrl])
          ).rev,
        };

        data.clicks += descData.elementClicks;
        data.lastClicks += descData.elementLastClicks;
        data.hover += descData.elementHover;
        data.conversions += descData.elementConversion;
        data.revenue += descData.elementRevenue;

        data.descendents.push(descData);
      });

      if (data[filterBy] > 0) {
        btnData.push(data);
      }
    });

    // Update button element values in clickmaps
    const filteredClickmaps = getVisibleClickmaps(rawClickmapData[pageUrl]);

    btnData.forEach((data) => {
      const btnKey = getCSSPath(data.element);

      const clickmapData = filteredClickmaps.byCssSelector[btnKey] || {};

      clickmapData.clc = data.clicks;
      clickmapData.clickPct = `${(
        (data.clicks / rawClickmapData[pageUrl][0].totalPageviews) *
        100
      ).toFixed(2)}%`;

      clickmapData.lfc = data.lastClicks;
      clickmapData.lfcPct = `${(
        (data.lastClicks / rawClickmapData[pageUrl][0].totalPageviews) *
        100
      ).toFixed(2)}%`;

      clickmapData.hov = data.hover;
      clickmapData.con = data.conversions;
      clickmapData.rev = data.revenue;

      filteredClickmaps.byCssSelector[btnKey] = clickmapData;
    });

    // Sort by last clicks
    const sortedClicks = Object.keys(filteredClickmaps.byCssSelector).sort(
      (a, b) =>
        filteredClickmaps.byCssSelector[b][sortBy] -
        filteredClickmaps.byCssSelector[a][sortBy]
    );

    const highlightedLinksMap = {};
    sortedClicks.forEach((key) => {
      let data = filteredClickmaps.byCssSelector[key];
      data["key"] = key;
      if (
        ["INPUT", "SELECT", "TEXTAREA", "LABEL", "BUTTON"].includes(data.tag) &&
        data[sortBy] > 0
      ) {
        const linkData = {
          data,
        };
        const el = querySelector(doc, formatCss(data.css));
        if (el) {
          const rect = el.getBoundingClientRect();
          linkData.style = {
            width: `${el.offsetWidth}px`,
            height: `${el.offsetHeight}px`,
            left: `${rect.left}px`,
            top: `${rect.top + replayIframe.contentWindow.pageYOffset}px`,
          };
          linkData.targetElement = el;
        }
        highlightedLinksMap[key] = linkData;
      }
    });

    return highlightedLinksMap;
  };

  const getSortedClickmapData = (doc, filteredClickmaps, sortBy) => {
    // Dynamically sort based on the provided sortBy property
    const sortedClicks = Object.keys(filteredClickmaps.byCssSelector).sort(
      (a, b) =>
        filteredClickmaps.byCssSelector[b][sortBy] -
        filteredClickmaps.byCssSelector[a][sortBy]
    );

    const highlightedLinksMap = {};
    sortedClicks.forEach((key, index) => {
      const data = filteredClickmaps.byCssSelector[key];
      data["key"] = key;
      if (data[sortBy] > 0) {
        const el = querySelector(doc, formatCss(data.css));
        if (el) {
          const rect = el.getBoundingClientRect();
          highlightedLinksMap[key] = {
            data,
            style: {
              width: `${el.offsetWidth}px`,
              height: `${el.offsetHeight}px`,
              left: `${rect.left}px`,
              top: `${rect.top + replayIframe.contentWindow.pageYOffset}px`,
            },
            targetElement: el,
          };
        }
      }
    });

    return highlightedLinksMap;
  };

  // General function to handle enabling clickmap layers and highlights with dynamic sorting
  const enableClickmapLayer = (mode) => {
    const doc = replayIframe.contentWindow.document;
    if (doc.body === null) return;

    const maxHeight = getDocumentMaxHeight(doc);
    const { overlay, highlightLayer } = createOverlayAndHighlightLayer(
      doc,
      replayIframe.contentWindow.scrollY,
      maxHeight,
      overlayBg
    );
    addFoldLines(highlightLayer);
    appendElementsToBody(doc, [overlay, highlightLayer]);

    const filteredClickmaps = getVisibleClickmaps(rawClickmapData[pageUrl]);

    // Define the sorting key based on the mode
    let sortBy, filterBy;
    switch (mode) {
      case "clickmap-elements":
      case "clickmap-links":
        sortBy = "clc"; // Sorting by clicks
        break;
      case "clickmap-lastElementClick":
        sortBy = "lec"; // Sorting by last element clicks
        break;
      case "form-analysis-lastClicks":
        sortBy = "lfc"; // Sorting by last clicks
        filterBy = "lastClicks"; // Filter by last clicks
        break;
      case "form-analysis-mostClicks":
        sortBy = "clc"; // Sorting by clicks
        filterBy = "clicks"; // Filter by clicks
        break;
      case "frictionmap-rageClicks":
        sortBy = "rgc"; // Sorting by rage clicks
        break;
      default:
        sortBy = "clc"; // Default sorting by clicks
    }

    let highlightedLinksMap = {};

    if (
      mode === "form-analysis-mostClicks" ||
      mode === "form-analysis-lastClicks"
    ) {
      highlightedLinksMap = aggregateButtonElements(doc, filterBy, sortBy);
    } else if (mode === "clickmap-links") {
      highlightedLinksMap = aggregateLinkElements(doc);
    } else {
      highlightedLinksMap = getSortedClickmapData(
        doc,
        filteredClickmaps,
        sortBy
      );
    }

    setAllClickmapData(Object.keys(highlightedLinksMap).length);

    // Filter top X clicks
    const filteredHighlightedLinksMap = filterClickmapData(highlightedLinksMap);

    // Create highlights and Store highlight element to data
    Object.keys(filteredHighlightedLinksMap).forEach((key, index) => {
      const highlight = createHighlight(
        doc,
        filteredHighlightedLinksMap[key],
        index,
        mode
      );
      highlightLayer.appendChild(highlight);
      filteredHighlightedLinksMap[key].highlightElement = highlight;
    });

    setIframeElements([overlay, highlightLayer]);
    setClickmapLinks(filteredHighlightedLinksMap);
    doc.addEventListener("click", clickmapHighlightClick, true);
    clickmapHighlightClickRef.current = clickmapHighlightClick;
  };

  const cleanupIframeElements = () => {
    if (clickmapHighlightClickRef.current) {
      replayIframe.contentWindow.document.removeEventListener(
        "click",
        clickmapHighlightClickRef.current,
        true
      );
    }

    if (scrollingHeatMapMousemoveRef.current) {
      replayIframe.contentWindow.document.removeEventListener(
        "mousemove",
        scrollingHeatMapMousemoveRef.current
      );
    }

    if (scrollingHeatMapMousedownRef.current) {
      replayIframe.contentWindow.document.removeEventListener(
        "mousedown",
        scrollingHeatMapMousedownRef.current
      );
    }

    // Remove all iframe elements from DOM
    iframeElements.forEach((el) => {
      const parentNode = el.parentNode;
      if (parentNode) {
        parentNode.removeChild(el);
      }
    });
    setIframeElements([]);
  };

  const cleanupInspectedElement = () => {
    if (!inspectedElementRef.current) return;

    inspectedElementRef.current.classList.remove("insightechInspection");
    inspectedElementRef.current.removeEventListener(
      "mousedown",
      inspectElementAction,
      true
    );

    inspectedElementRef.current = null;
  };

  const inspectElement = (e) => {
    cleanupInspectedElement();

    let path = getCSSPath(e.target);
    e.target.classList.add("insightechInspection");

    e.target.addEventListener("mousedown", inspectElementAction, true);

    inspectedElementRef.current = e.target;

    setHovered(path);
  };

  // Click on a element
  const inspectElementAction = (e) => {
    let text = null;
    if (
      e.target.childNodes &&
      e.target.childNodes.length === 1 &&
      e.target.childNodes[0].nodeType === Node.TEXT_NODE
    ) {
      text = e.target.childNodes[0].nodeValue;
    }

    setFilterModalElementContext({
      element: e.target,
      text,
    });
    const cssPath = getCSSPath(e.target);
    setFilterModalClickmapId(cssPath);
    processSelectedElementData({
      eleId: cssPath,
      setter: setFilterElementData,
      data: clickmapData[pageUrl]["byCssSelector"][cssPath],
    });

    setIsFilterModalVisible(true);
    setIsReplayViewerVisible(false);
  };

  const enableInspectElement = () => {
    // Cleanup first before adding listeners
    if (disableInspectHandlerRef.current) {
      disableInspectHandlerRef.current();
    }

    // Add listeners
    replayIframe.contentWindow.document.addEventListener(
      "mouseover",
      inspectElement,
      true
    );
    getAllShadowRoots(replayIframe.contentWindow.document).forEach(
      (shadowRoot) => {
        shadowRoot.addEventListener("mouseover", inspectElement, true);
      }
    );

    // Update cleanup function ref
    disableInspectHandlerRef.current = () => {
      replayIframe.contentWindow.document.removeEventListener(
        "mouseover",
        inspectElement,
        true
      );
      getAllShadowRoots(replayIframe.contentWindow.document).forEach(
        (shadowRoot) => {
          shadowRoot.removeEventListener("mouseover", inspectElement, true);
        }
      );

      cleanupInspectedElement();
    };
  };

  const cssContentInsightechInspection = `
    .insightechInspection {
      border: 1px solid red !important;
      background-color:rgb(255,255,102,0.8)} !important;
    }
  `;
  let sheetInsightechInspection; // Stylesheet shared across the doc and shadow root

  const initializeIframe = () => {
    var iframeDocument = replayIframe.contentWindow.document;

    // Prevent replay viewer from typing in inputs, applies to shadow root
    iframeDocument.addEventListener("keydown", function (e) {
      e.preventDefault();
      e.stopPropagation();
    });

    // Prevent clicked links from being followed, applies to shadow root
    iframeDocument.addEventListener("click", function (e) {
      e.preventDefault();
    });

    iframeDocument.adoptedStyleSheets = [
      ...iframeDocument.adoptedStyleSheets,
      sheetInsightechInspection,
    ];
  };

  const setReplaySize = (browserWidth, browserHeight) => {
    let hScale = 0;
    let wScale = 0;
    var backgroundDiv = document.getElementById("replay-background");
    if (backgroundDiv === null) {
      Sentry.captureMessage("Can't setReplaySize, replay-background missing", {
        level: "info",
      });
      return;
    }
    var scaleDiv = document.getElementById("replay-scale");
    const iframe = document.getElementById("replay-iframe");
    setReplayIframe(iframe);

    if (browserHeight > 0 && browserWidth > 0) {
      hScale = backgroundDiv.clientHeight / browserHeight;
      wScale = backgroundDiv.clientWidth / browserWidth;
      scale = Math.min(hScale, wScale);

      scaleDiv.style.width = `${scale * browserWidth}px`;
      scaleDiv.style.height = `${scale * browserHeight}px`;
      replayIframe.style.width = `${browserWidth}px`;
      replayIframe.style.height = `${browserHeight}px`;
      replayIframe.style.overflow = "hidden";
      replayIframe.style.transform = `scale(${scale})`;

      left = Math.floor((backgroundDiv.clientWidth - scale * browserWidth) / 2);
      top = Math.floor(
        (backgroundDiv.clientHeight - scale * browserHeight) / 2
      );
    }
  };

  const generateResourceUrl = (url) => {
    const exts = [".css", ".axd", ".eot", ".otf", ".ttf", ".woff", ".woff2"];
    if (new RegExp(exts.join("|")).test(url)) {
      // At least one match
      return `${apiServer}/api/rf?profile=${profileId}&url=${encodeURIComponent(
        url
      )}`;
    } else {
      return url;
    }
  };

  const generateResourceUrlForce = (url) => {
    return `${apiServer}/api/rf?profile=${profileId}&url=${encodeURIComponent(
      url
    )}`;
  };

  const convertSrcsetUrls = (string) => {
    var regex = /([^\s]+\s+\d+[wx](,|))/gi;
    var sets = string.match(regex);
    if (sets !== null) {
      for (var i = 0; i < sets.length; i++) {
        if (sets[i].trim() !== "") {
          var url = sets[i]
            .trim()
            .substring(0, sets[i].trim().lastIndexOf(" "));
          url = url.trim();
          var width = sets[i]
            .trim()
            .substring(
              sets[i].trim().lastIndexOf(" ") + 1,
              sets[i].trim().length
            );
          url = new URL(url, pageviews[currentPageviewIndex].url).href;
          sets[i] = generateResourceUrl(url) + " " + width;
        }
      }
      return sets.toString();
    } else {
      if (string !== "") {
        url = new URL(string, pageviews[currentPageviewIndex].url).href;
      } else {
        url = "";
      }
      return url;
    }
  };

  const isLinkResource2 = (nodeName, attributes) => {
    if (nodeName !== "LINK") return false;

    if (!attributes.hasOwnProperty("rel")) return false;

    var rel = attributes["rel"].toUpperCase();

    // <link href="main.css" rel="stylesheet" />
    if (["STYLESHEET", "ICON"].includes(rel)) {
      return true;
    }

    // <link rel="preload" href="main.css" as="style"/>
    if ("PRELOAD" === rel) {
      if (
        attributes.hasOwnProperty("as") &&
        ["STYLE", "FONT"].includes(attributes["as"].toUpperCase())
      ) {
        return true;
      }
    }

    return false;
  };

  const replaceResourceUrlsInStyle = (style) => {
    // get base URL
    var iframeDocument = replayIframe.contentWindow.document;
    var baseUrl = pageviews[currentPageviewIndex].url;
    var url = "";
    if (
      iframeDocument.getElementsByTagName("base").length !== 0 &&
      iframeDocument.getElementsByTagName("base")[0].getAttribute("href") !==
        null
    ) {
      baseUrl = iframeDocument
        .getElementsByTagName("base")[0]
        .getAttribute("href");
    }
    // url() resources
    // eslint-disable-next-line no-useless-escape
    const regex = /url\(("|'|)[^\)]*("|'|)\)/gi;
    const found = style.match(regex);
    if (found !== null) {
      for (var i = 0; i < found.length; i++) {
        let str = found[i].substring(4);
        str = str.substring(0, str.length - 1); // remove the )
        let quote = "";
        if (str.substring(0, 1) === '"' || str.substring(0, 1) === "'") {
          quote = str.substring(0, 1);
          str = str.substring(1);
          str = str.substring(0, str.length - 1);
        }
        if (!(str.indexOf("data:") === 0)) {
          url = str;
          if (str.indexOf("http") !== 0) {
            url = new URL(str, baseUrl).href;
          }
          style = style.replace(
            `url(${quote}${str}${quote})`,
            `url(${quote}${apiServer}/api/rf?profile=${profileId}&url=${encodeURIComponent(
              url
            )}${quote})`
          );
        }
      }
    }
    return style;
  };

  // Converts URL to proxy, override css :hover & :focus
  const convertStyleText = (styleText) => {
    styleText = replaceResourceUrlsInStyle(styleText);
    styleText = styleText.replace(
      new RegExp(/:hover/i, "g"),
      ".InsightechMouseHover"
    );
    styleText = styleText.replace(
      new RegExp(/:focus/i, "g"),
      ".InsightechMouseFocus"
    );

    return styleText;
  };

  // Resolve adoptedStyleSheets & attach to the target
  const attachAdoptedStyleSheets = (target, rulesText) => {
    try {
      const sheets = [sheetInsightechInspection];
      // see css content : cssContentInsightechInspection

      if (typeof rulesText == "string") {
        rulesText
          .split("\n/*insightechSheetsSeparator/*\n")
          .forEach((cssContent) => {
            const sheet =
              new replayIframe.contentWindow.document.defaultView.CSSStyleSheet();
            cssContent = convertStyleText(cssContent);
            sheet.replaceSync(cssContent);
            sheets.push(sheet);
          });
      }

      if (sheets.length > 0) {
        target.adoptedStyleSheets = sheets;
      }
    } catch (e) {
      // Style error should not stop the rendering of the element
      console.error(e);
    }
  };

  // Attribute insightech-stylesheet-disabled
  const handleSylesheetDisabled = (node, value) => {
    if (value === "true") value = true;
    else if (value === "false") value = false;
    else {
      console.error("handleSylesheetDisabled unsuported value : ", value);
      return;
    }

    if (!node.sheet) {
      // Sheet is not yet loaded, apply value when sheet is ready
      node.onload = () => {
        node.sheet.disabled = value;
      };
    } else {
      node.sheet.disabled = value;
    }
  };

  const playDummyPage = (url, flag, iframeWidth) => {
    cleanupIframeElements();
    if (replayIframe === null || replayIframe.contentWindow === null) {
      Sentry.captureMessage("Can't play event, replayIframe document missing", {
        level: "info",
      });
      return;
    }
    let iframeDocument = replayIframe.contentWindow.document;

    // Define the content based on liteMode and event flag
    let dummyPageContent = replayMessageContent(
      liteMode,
      url,
      flag,
      iframeWidth
    );

    iframeDocument.open();
    iframeDocument.write(dummyPageContent);
    iframeDocument.close();
  };

  const playDom = (nodeData, treeData) => {
    var iframeDocument = replayIframe.contentWindow.document;
    var nt = nodeData; // [0:nodeIdx, 1:parentIdx, 2:prevSiblingIdx, 3:nodeTypeIdx, 4:nodeNameIdx, 5:text_content_of_text_nodes, 6:attr, 7:cssPath]
    let nodeIndex = nt[0];
    let nodeName = treeData.nodeNames[nt[4]];
    let nodeParentIndex = nt[1];
    let previousSiblingIndex = nt[2];
    let nodeText = nt[5];
    let nodeType = nt[3];
    // let cssPath = nt[7]; // Not used anymore, keep this as a doc & legacy
    let attributes = {}; // nt[6]

    // Map attributes names & values of the element
    for (const ai in nt[6]) {
      try {
        let attributeName = treeData.attrNames[ai];
        let attributeValue = treeData.attrValues[nt[6][ai]];
        if (attributeName === "style") {
          attributeValue = convertStyleText(attributeValue);
        }

        attributes[attributeName] = attributeValue;
      } catch (e) {
        console.log(e);
      }
    }

    var node = null;
    switch (nodeType) {
      // Node.ELEMENT_NODE
      case 1:
        try {
          if (["HTML", "HEAD", "BODY"].includes(nodeName)) {
            switch (nodeName) {
              case "HTML":
                node = iframeDocument.documentElement;
                break;
              case "HEAD":
                iframeDocument.head.innerHTML = "";
                node = iframeDocument.head;
                break;
              case "BODY":
                iframeDocument.body.innerHTML = "";
                node = iframeDocument.body;
                const styleTag = iframeDocument.createElement("style");
                styleTag.id = "insightechInspectionStyle";
                styleTag.innerText =
                  ".is-highlight-active { background-color: rgba(255,255,0, .6); }";
                iframeDocument.body.appendChild(styleTag);
                break;
              default:
                break;
            }

            if (!node) {
              console.error("Node not found", nodeName);
              return;
            }

            for (const attributeName in attributes) {
              try {
                node.setAttribute(attributeName, attributes[attributeName]);
              } catch (e) {
                console.warn("Attribute error on", node, e);
              }
            }

            nodes[nodeIndex] = node;
          } else {
            if (
              [
                "svg",
                "path",
                "circle",
                "g",
                "use",
                "polygon",
                "rect",
                "line",
                "text",
              ].includes(nodeName)
            ) {
              node = iframeDocument.createElementNS(
                "http://www.w3.org/2000/svg",
                nodeName
              );
            } else {
              node = iframeDocument.createElement(nodeName);
            }

            // Set attributes
            for (const attributeName in attributes) {
              let attributeValue = attributes[attributeName];
              try {
                if (nodeName === "SCRIPT" && attributeName === "src") {
                  node.setAttribute("origin-src", attributeValue);
                } else if (
                  nodeName === "IMG" &&
                  attributeName === "src" &&
                  !(attributeValue.indexOf("data:") === 0)
                ) {
                  node.setAttribute("src", generateResourceUrl(attributeValue));
                } else if (
                  nodeName === "IMG" &&
                  attributeName === "data-src" &&
                  !(attributeValue.indexOf("data:") === 0)
                ) {
                  node.setAttribute(
                    "data-src",
                    generateResourceUrl(attributeValue)
                  );
                } else if (nodeName === "IMG" && attributeName === "srcset") {
                  node.setAttribute(
                    "srcset",
                    convertSrcsetUrls(attributeValue)
                  );
                } else if (
                  nodeName === "use" &&
                  attributeName === "xlink:href"
                ) {
                  node.setAttributeNS(
                    "http://www.w3.org/1999/xlink",
                    "xlink:href",
                    // same-origin policy : There is currently no defined way to set a cross-origin policy for use elements. Proxy the file
                    generateResourceUrlForce(
                      attributeValue.indexOf("http") === 0
                        ? attributeValue
                        : new URL(
                            attributeValue,
                            pageviews[currentPageviewIndex].url
                          ).href
                    )
                  );
                } else if (
                  nodeName === "SOURCE" &&
                  attributeName === "srcset" &&
                  !(attributeValue.indexOf("data:") === 0)
                ) {
                  node.setAttribute(
                    "srcset",
                    convertSrcsetUrls(attributeValue)
                  );
                } else if (
                  nodeName === "INPUT" &&
                  attributeName === "src" &&
                  !(attributeValue.indexOf("data:") === 0)
                ) {
                  node.setAttribute("src", generateResourceUrl(attributeValue));
                } else if (
                  nodeName === "LINK" &&
                  isLinkResource2(nodeName, attributes) &&
                  attributeName === "href" &&
                  !(attributeValue.indexOf("data:") === 0)
                ) {
                  node.setAttribute(
                    "href",
                    generateResourceUrl(attributeValue) // If local test, remove generateResourceUrl()
                  );
                } else if (nodeName === "IFRAME" && attributeName === "src") {
                  node.setAttribute("https://cdn.insightech.com/iframe.html");
                } else if (
                  nodeName === "META" &&
                  attributeName === "http-equiv"
                ) {
                  //node.setAttribute('src', 'https://cdn.insightech.com/iframe.html');
                } else if (attributeName === "integrity") {
                  // Ignore script file hash check
                } else {
                  node.setAttribute(attributeName, attributeValue);
                }
              } catch (e) {}
            }

            if (attributes["insightech-stylesheet-disabled"] === "true") {
              handleSylesheetDisabled(node, "true");
            }

            let nodeWasAdded = false;
            // Try inserting the node using its previous sibling
            if (
              previousSiblingIndex !== -1 &&
              previousSiblingIndex !== -2 &&
              previousSiblingIndex !== nodeIndex - 1 &&
              nodes[previousSiblingIndex] &&
              nodes[previousSiblingIndex].nextSibling &&
              nodes[previousSiblingIndex].parentElement &&
              nodes[previousSiblingIndex].parentElement.isConnected // Parent has to be part of the DOM
            ) {
              // nodes[previousSiblingIndex].parentElement might be different from nodes[nodeParentIndex]
              var prevSiblingParent = nodes[previousSiblingIndex].parentElement;
              // Parent element is not the parent of the silbiling element for unknown reason
              // Use the silbiling element to locate the parent instead
              prevSiblingParent.insertBefore(
                // Add or move the node if already in the Dom
                node,
                nodes[previousSiblingIndex].nextSibling
              );
              nodeWasAdded = true;
              //}
            }

            const parentNode = nodes[nodeParentIndex];

            // Or, force to be the first child
            if (!nodeWasAdded && parentNode && previousSiblingIndex === -2) {
              parentNode.prepend(node); // Add or move the node if already in the Dom
              nodeWasAdded = true;
            }

            // Fallback, use node parent, insert node as last child, probably the first or only child
            if (!nodeWasAdded) {
              if (!parentNode) {
                console.error(
                  "Can't find parent idx : ",
                  nodeParentIndex,
                  " for node ",
                  node
                );
              } else if (!parentNode.isConnected) {
                console.error(
                  "parentNode is not connected, parentNode, node",
                  parentNode,
                  node
                );
              } else {
                parentNode.appendChild(node); // Add or move the node if already in the Dom
                nodeWasAdded = true;
                if (!parentNode.isConnected) {
                  console.error(
                    "parentNode is not connected, adding anyway, parentNode, node",
                    parentNode,
                    node
                  );
                }
              }
            }

            nodes[nodeIndex] = node;
          }
        } catch (e) {
          nodes[nodeIndex] = null;
          console.error(e);
        }
        break;
      // Node.TEXT_NODE
      case 3:
        if (
          nodes[nodeParentIndex] &&
          nodes[nodeParentIndex] !== "" &&
          nodes[nodeParentIndex].tagName === "STYLE"
        ) {
          nodeText = convertStyleText(nodeText);
          nodeText = nodeText.replace(/ng:cloak/g, "ng\\:cloak");
        }
        try {
          node = iframeDocument.createTextNode(nodeText);
          nodes[nodeParentIndex].appendChild(node);
          nodes[nodeIndex] = node;
          //nodes[nodeParentIndex].innerHTML += nodeText;
        } catch (e) {
          console.error(e);
        }
        break;
      // Node.CDATA_SECTION_NODE
      case 4:
        try {
          node = iframeDocument.createCDATASection("");
          nodes[nodeParentIndex].appendChild(node);
          nodes[nodeIndex] = node;
        } catch (e) {}
        break;
      // Node.PROCESSING_INSTRUCTION_NODE
      case 7:
        try {
          node = iframeDocument.createProcessingInstruction("", "");
          nodes[nodeParentIndex].appendChild(node);
          nodes[nodeIndex] = node;
        } catch (e) {}
        break;
      // Node.COMMENT_NODE
      case 8:
        try {
          node = iframeDocument.createComment("");
          nodes[nodeParentIndex].appendChild(node);
          nodes[nodeIndex] = node;
        } catch (e) {}
        break;
      // Node.DOCUMENT_NODE
      case 9:
        node = iframeDocument.implementation.createHTMLDocument("");
        nodes[nodeIndex] = node;
        break;
      // Node.DOCUMENT_TYPE_NODE
      case 10:
        nodes[nodeIndex] = null;
        break;
      // Node.DOCUMENT_FRAGMENT_NODE
      case 11:
        try {
          if (attributes["insightech-shadow-root-mode"] === "open") {
            // SHADOW ROOT
            const shadowRoot = nodes[nodeParentIndex].attachShadow({
              mode: "open",
            });
            let cssText = null;

            if (attributes["insightech-adopted-stylesheets-css-content"]) {
              cssText =
                attributes["insightech-adopted-stylesheets-css-content"];
            }

            attachAdoptedStyleSheets(shadowRoot, cssText);

            node = shadowRoot; // override
          } else {
            // DOCUMENT_FRAGMENT_NODE
            node = iframeDocument.createDocumentFragment();
            nodes[nodeParentIndex].append(node);
          }
          nodes[nodeIndex] = node;
        } catch (e) {
          console.error(e);
        }
        break;
      default:
        break;
    }
  };

  const playEvent = (event) => {
    let noDomPage = pageviews[currentPageviewIndex].noDomPage;
    let iframeWidth = pageviews[currentPageviewIndex].browserWidth;

    cleanupIframeElements();
    if (replayIframe === null || replayIframe.contentWindow === null) {
      Sentry.captureMessage("Can't play event, replayIframe document missing", {
        level: "info",
      });
      return;
    }
    var iframeDocument = replayIframe.contentWindow.document;

    var index = 0;
    if (formatVersion === 1) {
      // decommissioned
      return;
    }

    switch (event.t) {
      case 1000:
        // fake dummy page event
        playDummyPage(event.url, event.hasOtherEvents, iframeWidth);
        break;
      case 1:
        // page load
        iframeDocument.body.scrollTop = 0;
        iframeDocument.body.scrollLeft = 0;
        break;
      case 2:
        // dom ready
        nodes = [];
        if (!event.d || !event.d.tree) {
          break;
        }

        for (var n = 0; n < event.d.tree.length; n++) {
          if (n === 0 && event.d.tree[n][3] !== 10) {
            //missing doctype node
            iframeDocument.open().write("<!DOCTYPE html>");
            iframeDocument.close();

            // Create shared spreadsheet with our content for that page
            sheetInsightechInspection =
              new replayIframe.contentWindow.document.defaultView.CSSStyleSheet();
            sheetInsightechInspection.replaceSync(
              cssContentInsightechInspection
            );
          }
          playDom(event.d.tree[n], event.d);
        }
        initializeIframe();
        break;
      case 7: // Click
        if (event.d !== null) {
          var clickposition = event.d;
          var clickDiv = document.createElement("div");
          clickDiv.className = "replay-click-ripple";
          clickDiv.style.position = "absolute";
          clickDiv.style.left = left + scale * clickposition.sx + "px";
          clickDiv.style.top = top + scale * clickposition.sy + "px";
          document.getElementById("replay-background").appendChild(clickDiv);
          setTimeout(() => {
            clickDiv.parentNode.removeChild(clickDiv);
          }, 500);
        }
        break;
      case 8: // Input event
        if (noDomPage) return; // prevent input when is no dom ready page but has other events

        const handleInputEvent = (data) => {
          try {
            if (data.v.length > 0) {
              const node = nodes[data.i];
              if (!node) {
                console.log("Can't find node i:" + data.i + " path:", data.p);
                return;
              }

              node.value = data.v[0].v;

              // Checked ?
              if (!["radio", "checkbox"].includes(node.type)) return;
              if (data.c === true) {
                node.checked = true;
              } else if (data.c === false) {
                node.checked = false;
              }
            }
          } catch (error) {
            // Record the undefined value issue for further debug before silencing the issue
            captureExceptionToSentry(error);
          }
        };

        // Input event on the same element can be grouped together
        event.d.forEach((data) => {
          if (event.ct !== data.t) {
            // Delay handling if we have not reached the event time in case of successive key press
            setTimeout(() => {
              handleInputEvent(data);
            }, data.t - event.ct);
          } else {
            // Handle now
            handleInputEvent(data);
          }
        });
        break;
      case 11: // Mouse movement
        for (index in event.d) {
          var mouseposition = event.d[index];
          if (mouseposition.t !== event.ct) {
            // eslint-disable-next-line no-loop-func
            setTimeout(() => {
              // remove all hover classes
              let eles = querySelectorAll(
                replayIframe.contentWindow.document,
                ".InsightechMouseHover"
              );
              for (var i = 0; i < eles.length; i++) {
                eles[i].classList.remove("InsightechMouseHover");
              }
              // add hover class
              try {
                let ele = nodes[mouseposition.i];
                if (ele) {
                  ele.classList.add("InsightechMouseHover");
                  let parent = ele.parentElement;
                  while (parent) {
                    parent.classList.add("InsightechMouseHover");
                    parent = parent.parentElement || parent.host;
                  }
                } else if (!noDomPage) {
                  console.warn(
                    "mouseposition.i does not match any node",
                    mouseposition.i,
                    mouseposition.p,
                    mouseposition.m
                  );
                }
              } catch (e) {
                console.log(e);
              }
              if (document.getElementById("replay-mouse") !== null) {
                // remove mouse div element
                document
                  .getElementById("replay-mouse")
                  .parentNode.removeChild(
                    document.getElementById("replay-mouse")
                  );
              }
              mouseDiv = document.createElement("div");
              mouseDiv.id = "replay-mouse";
              mouseDiv.innerHTML =
                '<i class="fa fa-lg fa-mouse-pointer text-dark replay-mouse-pointer"></i>';
              mouseDiv.style.position = "absolute";
              mouseDiv.style.left = left + scale * mouseposition.sx + "px";
              mouseDiv.style.top = top + scale * mouseposition.sy + "px";
              document
                .getElementById("replay-background")
                .appendChild(mouseDiv);
            }, mouseposition.t - event.ct);
          } else {
            // remove all hover classes
            let eles = querySelectorAll(
              replayIframe.contentWindow.document,
              ".InsightechMouseHover"
            );
            for (var i = 0; i < eles.length; i++) {
              eles[i].classList.remove("InsightechMouseHover");
            }
            // add hover class
            try {
              let ele = nodes[mouseposition.i];
              if (ele) {
                ele.classList.add("InsightechMouseHover");
                let parent = ele.parentElement;
                while (parent !== null) {
                  parent.classList.add("InsightechMouseHover");
                  parent = parent.parentElement;
                }
              } else if (!noDomPage) {
                console.warn(
                  "mouseposition.i does not match any node",
                  mouseposition.i,
                  mouseposition.p,
                  mouseposition.m
                );
              }
            } catch (e) {
              console.log(e);
            }

            if (document.getElementById("replay-mouse") !== null) {
              // remove mouse div element
              document
                .getElementById("replay-mouse")
                .parentNode.removeChild(
                  document.getElementById("replay-mouse")
                );
            }
            mouseDiv = document.createElement("div");
            mouseDiv.id = "replay-mouse";
            mouseDiv.innerHTML =
              '<i class="fa fa-lg fa-mouse-pointer text-dark replay-mouse-pointer"></i>';
            mouseDiv.style.position = "absolute";
            mouseDiv.style.left = left + scale * mouseposition.sx + "px";
            mouseDiv.style.top = top + scale * mouseposition.sy + "px";
            document.getElementById("replay-background").appendChild(mouseDiv);
          }
        }
        break;
      // Scroll
      case 12:
        for (index in event.d) {
          var scrolling = event.d[index];
          if (scrolling.i === 0) {
            // scroll page
            iframeDocument.body.scrollTop = scrolling.pt;
            iframeDocument.body.scrollLeft = scrolling.pl;

            iframeDocument.documentElement.scrollTo(scrolling.pl, scrolling.pt);
          } else {
            // Scroll element
            if (!nodes[scrolling.i]) {
              console.error(
                "Can't find scroll : node, index",
                scrolling.i,
                scrolling.p
              );
            } else if (!nodes[scrolling.i].isConnected) {
              console.error(
                "Scrolled node is not connected",
                nodes[scrolling.i],
                scrolling.i,
                scrolling.p
              );
              // Possible fallback to querySelector(iframeDocument, scrolling.p)
            } else {
              nodes[scrolling.i].scrollTo(scrolling.cl, scrolling.ct);
            }
          }
        }
        break;
      // Resize
      case 15:
        for (index in event.d) {
          var resize = event.d[index];
          setReplaySize(resize.bw, resize.bh);
        }
        break;
      // Mutation
      case 16:
        if (noDomPage) {
          // prevent mutation when is no dom ready page but has other events
          return;
        }

        for (index in event.d) {
          var mutation = event.d[index]; /*
            {
              t: int type
              i: int target index
              b, string attribute name
              r: [removed node idx]
              a: [] _domTree
              p: int previous sibling index
              n: int next sibling index
              v: attribute value || content text
            }*/
          var target = nodes[mutation.i];
          switch (mutation.t) {
            case 1:
              // mutation change nodes
              if (mutation.r !== null && mutation.r.length > 0) {
                // Nodes were removed
                for (const ri in mutation.r) {
                  try {
                    if (mutation.r[ri] !== -1) {
                      var node = nodes[mutation.r[ri]];
                      if (node.parentElement !== null) {
                        node.parentElement.removeChild(node);
                        nodes[nodes.indexOf(node)] = null;
                      }
                    }
                  } catch (e) {
                    console.error("Can't remove node", e);
                  }
                }
              }
              if (mutation.a !== null && mutation.a.length > 0) {
                // Nodes were added
                mutation.a.forEach((domTree) => {
                  for (var n = 0; n < domTree.tree.length; n++) {
                    playDom(domTree.tree[n], domTree);
                  }
                });
              }
              break;
            case 2:
              // mutation set attributes
              try {
                if (target) {
                  var value = mutation.v;
                  var attribute = mutation.b;
                  if (attribute === "src") {
                    if (target.tagName === "IFRAME") {
                      value = "https://cdn.insightech.com/iframe.html";
                    } else if (target.tagName === "SCRIPT") {
                      attribute = "origin-src";
                    } else {
                      //get full URL of the src
                      value = generateResourceUrl(value);
                    }
                  } else if (attribute === "srcset") {
                    //get full URL of the src
                    value = convertSrcsetUrls(value);
                  } else if (attribute === "style") {
                    value = convertStyleText(value);
                  } else if (
                    attribute === "insightech-adopted-stylesheets-css-content"
                  ) {
                    attachAdoptedStyleSheets(target, value);
                    break;
                  } else if (attribute === "insightech-stylesheet-disabled") {
                    // Remove bool to string conversion after may 2024
                    let val;
                    if (value === true) val = "true";
                    else if (value === false) val = "false";
                    else val = value;
                    handleSylesheetDisabled(target, val);
                  }
                  target.setAttribute(attribute, value);
                }
              } catch (e) {
                console.log(e);
                console.log(target);
                console.log(nodes);
                console.log(mutation);
              }
              break;
            case 3:
              // mutation set text
              try {
                if (target) {
                  target.data = mutation.v;
                }
              } catch (e) {
                console.log(e);
                console.log(target);
                console.log(nodes);
                console.log(mutation);
              }
              break;
            case 4:
              // mutation remove attributes
              try {
                if (target) {
                  target.removeAttribute(mutation.b);
                }
              } catch (e) {
                console.log(e);
                console.log(nodes);
                console.log(target);
                console.log(mutation);
              }
              break;
            default:
              break;
          }
        }
        break;
      case 17:
        // field focus
        for (let i = 0; i < event.d.length; i++) {
          const focus = event.d[i];
          try {
            let ele = nodes[focus.i];
            if (ele) {
              ele.classList.add("InsightechMouseFocus");
            } else if (!noDomPage) {
              console.warn("focus.i does not match any node", focus.i);
            }
          } catch (e) {
            console.log(event);
            console.log(e);
          }
        }
        break;
      case 18:
        // field blur
        // remove all focus classes
        let eles = querySelectorAll(iframeDocument, ".InsightechMouseFocus");
        for (let i = 0; i < eles.length; i++) {
          eles[i].classList.remove("InsightechMouseFocus");
        }
        break;
      case 19:
        // url change
        setPageUrl(event.url);
        break;
      default:
        break;
    }
  };

  const setCurrentPageview = (pageviewIndex) => {
    currentPageviewIndex = pageviewIndex;
    var previousPageviewsDuration = 0;
    for (var i = 0; i < currentPageviewIndex; i++) {
      var pageview = pageviews[i];
      var pageviewDuration =
        pageview.events[pageview.events.length - 1].ct - pageview.events[0].ct;
      previousPageviewsDuration = previousPageviewsDuration + pageviewDuration;
    }
    currentPageviewStartingDuration = previousPageviewsDuration;
    setReplaySize(
      pageviews[currentPageviewIndex].browserWidth,
      pageviews[currentPageviewIndex].browserHeight
    );
    setPageUrl(pageviews[currentPageviewIndex].url);
    setActivePageviewIndex(pageviewIndex);
  };

  const preventEvent = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const playStep = (timestamp, index, timeout) => {
    // Pageview has no DOM Ready event
    if (skipPageviews[pageviews[currentPageviewIndex].pageviewId]) {
      // Check if there is a next pageview
      if (pageviews[currentPageviewIndex + 1]) {
        const currentPageview = pageviews[currentPageviewIndex];
        // Skip to next pageview
        // Get current pageview duration + previous pageviews durations
        const currentPageviewDuration =
          currentPageview.events[currentPageview.events.length - 1].ct -
          currentPageview.events[0].ct;

        let previousPageviewsDuration = 0;
        for (let i = 0; i < currentPageviewIndex; i++) {
          const pageview = pageviews[i];
          const pageviewDuration =
            pageview.events[pageview.events.length - 1].ct -
            pageview.events[0].ct;
          previousPageviewsDuration =
            previousPageviewsDuration + pageviewDuration;
        }

        let playProgress = currentPageviewDuration + previousPageviewsDuration;
        timestamp = pageviews[currentPageviewIndex + 1].events[0].ct;
        setProgress(playProgress);
        setCurrentPageview(currentPageviewIndex + 1);
        timeout = setTimeout(
          playStep,
          stepInterval.current,
          timestamp,
          index,
          timeout
        );
        intervalRef.current = timeout;
      } else {
        // This is the last pageview
        setProgress(duration);
      }
    } else {
      // Do what is to be done
      timestamp = timestamp + stepInterval.current * playSpeed;
      let playProgress =
        timestamp -
        pageviews[currentPageviewIndex].events[0].ct +
        currentPageviewStartingDuration;
      setProgress(playProgress);
      let lastPlayedEventTimestamp = 0;
      let skipToEvent = null;

      if (index < pageviews[currentPageviewIndex].events.length) {
        for (
          var i = index;
          i < pageviews[currentPageviewIndex].events.length;
          i++
        ) {
          var event = pageviews[currentPageviewIndex].events[i];
          if (event.ct < timestamp) {
            index = i + 1;
            lastPlayedEventTimestamp = event.ct;
            playEvent(event);
          } else {
            // search for the next event to decide if skip is required
            if (
              event.t === 7 ||
              event.t === 8 ||
              event.t === 11 ||
              event.t === 12 ||
              event.t === 15
            ) {
              // skip 5 seconds idle
              if (
                skipIdle &&
                lastPlayedEventTimestamp > 0 &&
                event.ct > lastPlayedEventTimestamp + 5000
              ) {
                skipToEvent = event;
              }
              break;
            }
            // last event
            if (
              i === pageviews[currentPageviewIndex].events.length - 1 &&
              skipIdle &&
              lastPlayedEventTimestamp > 0 &&
              event.ct > lastPlayedEventTimestamp + 5000
            ) {
              skipToEvent = event;
            }
          }
        }
      } else {
        // reach the end of the pageview
        if (document.getElementById("replay-mouse") !== null) {
          // remove mouse div element
          document
            .getElementById("replay-mouse")
            .parentNode.removeChild(document.getElementById("replay-mouse"));
        }
        if (currentPageviewIndex === pageviews.length - 1) {
          setPlaying(false);
          clearTimeout(timeout);
          index = 0;
          currentPageviewStartingDuration = 0;
          setCurrentPageview(0);
          return;
        } else {
          timestamp = pageviews[currentPageviewIndex + 1].events[0].ct;
          index = 0;
          setCurrentPageview(currentPageviewIndex + 1);
        }

        setActivePageviewIndex(currentPageviewIndex);
      }

      const eventsContainer = document.getElementById(
        "replay-events-tab-container"
      );
      let timestamps = document.querySelector(".event-list tr:not(.elapsed)");
      if (timestamps) {
        eventsContainer.scrollTop = timestamps.offsetTop;
      }

      if (skipToEvent !== null) {
        timeout = setTimeout(playStep, 1000, skipToEvent.ct, index, timeout); // take into account drift
        intervalRef.current = timeout;
      } else {
        timeout = setTimeout(
          playStep,
          stepInterval.current,
          timestamp,
          index,
          timeout
        ); // take into account drift
        intervalRef.current = timeout;
      }
    }
  };

  const playElapsedEvents = (elapsedProgress) => {
    setProgress(elapsedProgress);
    setTimeout(() => {
      detectCurrentPageview();

      // play the events that is before the progress
      const currentPageview = pageviews[currentPageviewIndex];
      const eventsLength = currentPageview.events.length;
      const playedEvents = {};
      let domEvent = null;
      let domEventIndex = null;

      // Find the DOM Ready event index
      for (let i = 0; i < eventsLength; i++) {
        const event = currentPageview.events[i];
        if (event.t === 2) {
          domEvent = event;
          domEventIndex = i;
          break;
        }
      }

      for (let i = 0; i < currentPageview.events.length; i++) {
        if (
          elapsedProgress >=
          currentPageview.events[i].ct -
            currentPageview.events[0].ct +
            currentPageviewStartingDuration
        ) {
          if (
            currentPageview.events[i].t === 1 ||
            currentPageview.events[i].t === 2 ||
            currentPageview.events[i].t === 8 ||
            currentPageview.events[i].t === 12 ||
            currentPageview.events[i].t === 15 ||
            currentPageview.events[i].t === 16 ||
            currentPageview.events[i].t === 19 ||
            currentPageview.events[i].t === 1000
          ) {
            playEvent(currentPageview.events[i]);
            playedEvents[i] = true;
          }
        }
        setActivePageviewIndex(currentPageviewIndex);
      }

      // If DOM Ready event was not played, play it
      if (!playedEvents[domEventIndex] && domEvent) {
        playEvent(domEvent);
      }
    }, 0);
  };

  const play = () => {
    setPlaying(true);

    if (document.getElementById("replay-overlay") == null) {
      let overlay = document.createElement("div");
      overlay.style.position = "absolute";
      overlay.style.top = "0";
      overlay.style.left = "0";
      overlay.style.right = "0";
      overlay.style.bottom = "0";
      overlay.style.backgroundColor = "rgba(255, 255, 255, 0)";
      overlay.style.cursor = "not-allowed";
      overlay.id = "replay-overlay";
      document.getElementById("replay-scale").appendChild(overlay);
    }

    detectCurrentPageview();

    var timestamp = pageviews[currentPageviewIndex].events[0].ct;
    var index = 0;

    // play the events that is before the progress
    for (var i = 0; i < pageviews[currentPageviewIndex].events.length; i++) {
      if (
        progress >
        pageviews[currentPageviewIndex].events[i].ct -
          pageviews[currentPageviewIndex].events[0].ct +
          currentPageviewStartingDuration
      ) {
        timestamp = pageviews[currentPageviewIndex].events[i].ct;
        index = i;
        if (
          pageviews[currentPageviewIndex].events[i].t === 1 ||
          pageviews[currentPageviewIndex].events[i].t === 2 ||
          pageviews[currentPageviewIndex].events[i].t === 8 ||
          pageviews[currentPageviewIndex].events[i].t === 12 ||
          pageviews[currentPageviewIndex].events[i].t === 15 ||
          pageviews[currentPageviewIndex].events[i].t === 16 ||
          pageviews[currentPageviewIndex].events[i].t === 19 ||
          pageviews[currentPageviewIndex].events[i].t === 1000
        ) {
          playEvent(pageviews[currentPageviewIndex].events[i]);
        }
      } else {
        index = i;
        timestamp =
          progress -
          currentPageviewStartingDuration +
          pageviews[currentPageviewIndex].events[0].ct;
        break;
      }
    }

    // Begin Loop
    if (progress >= duration) {
      timeout = setTimeout(
        playStep,
        stepInterval.current,
        initialTimestamp,
        index,
        timeout
      );
    } else {
      timeout = setTimeout(
        playStep,
        stepInterval.current,
        timestamp,
        index,
        timeout
      );
    }
    clearTimeout(intervalRef.current);
    clearTimeout(playTimeoutRef.current);
    intervalRef.current = timeout;
  };
  playRef.current = play;

  const playButtonClick = () => {
    if (playing) {
      pause();
    } else {
      play();
    }
  };

  const preLoadPage = () => {
    for (var i = 0; i < pageviews[currentPageviewIndex].events.length; i++) {
      var event = pageviews[currentPageviewIndex].events[i];
      if (event.t === 2) {
        playEvent(event);
        break;
      }
    }
  };

  const detectCurrentPageview = () => {
    var previousPageviewsDuration = 0; // total duration before the current pageview
    var totalDuration = 0;
    for (var i = 0; i < pageviews.length; i++) {
      var pageview = pageviews[i];
      var pageviewDuration =
        pageview.events[pageview.events.length - 1].ct - pageview.events[0].ct;
      if (progressRef.current >= previousPageviewsDuration) {
        if (
          progressRef.current <
          previousPageviewsDuration + pageviewDuration
        ) {
          currentPageviewIndex = i;
          currentPageviewStartingDuration = previousPageviewsDuration;
        }
        previousPageviewsDuration =
          previousPageviewsDuration + pageviewDuration;
      }
      totalDuration = totalDuration + pageviewDuration;
    }
    setReplaySize(
      pageviews[currentPageviewIndex].browserWidth,
      pageviews[currentPageviewIndex].browserHeight
    );
    setPageUrl(pageviews[currentPageviewIndex].url);
    setDuration(totalDuration);
    setActivePageviewIndex(currentPageviewIndex);
  };

  const getVisibleClickmaps = (rawClickmapData) => {
    const transformedClickmapData = {
      totals: {
        totalPageviews: 0,
        totalSessions: 0,
      },
      byCssSelector: {},
    };

    if (
      rawClickmapData &&
      rawClickmapData.length &&
      rawClickmapData[0]?.elementData
    ) {
      rawClickmapData[0].elementData.forEach((el) => {
        if (el.css) {
          let element = querySelector(
            replayIframe.contentWindow.document,
            formatCss(el.css)
          );
          if (element) {
            transformedClickmapData.byCssSelector[el.css] = {
              ...el,
              hoverPct: `${(
                (el.hov / rawClickmapData[0].totalPageviews) *
                100
              ).toFixed(2)}%`,
              clickPct: `${(
                (el.clc / rawClickmapData[0].totalPageviews) *
                100
              ).toFixed(2)}%`,
              rgcPct: `${(
                (el.rgc / rawClickmapData[0].totalPageviews) *
                100
              ).toFixed(2)}%`,
              lfcPct: `${(
                (el.lfc / rawClickmapData[0].totalPageviews) *
                100
              ).toFixed(2)}%`,
              lecPct: `${(
                (el.lec / rawClickmapData[0].totalPageviews) *
                100
              ).toFixed(2)}%`,
              conPct: `${((el.con / el.clcSess) * 100).toFixed(2)}%`,
              revAvg: el.rev / el.con || 0,
            };
          }
        }
      });
      transformedClickmapData.totals = {
        pageViews:
          rawClickmapData && rawClickmapData[0].totalPageviews
            ? rawClickmapData[0].totalPageviews
            : 0,
        sessions:
          rawClickmapData && rawClickmapData[0].totalSessions
            ? rawClickmapData[0].totalSessions
            : 0,
      };
    }

    return transformedClickmapData;
  };

  const handleAnalyticsApiError = (error, type, loaderToggle) => {
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      // Todo : show message to the user
      const msg = type + " : " + ERRORS_MAPPED[errMsg]
      console.error(msg);
      Sentry.captureMessage(msg, {level: "warning"});
    } else {
      captureExceptionToSentry(error);
    }
    setNoDataAlert(true);
    loaderToggle(false);
  }

  const readClickmap = ({ queryId, key, callback }) => {
    api.cancel("readClickmap");
    api
      .readClickmap(authUser, {
        profileId,
        queryId,
        key,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readClickmap({ queryId, key, callback });
        }
        if (res.data.status === 200) {
          setIsClickmapDataLoading(false);
          // check no data scenario
          if (
            res.data.data.totalRows === 0 ||
            res.data.data.records[0].totalPageviews === 0
          ) {
            setNoDataAlert(true);
            // do not return here, needs to show 4 collapsable mode on Analysis tab
          } else {
            setNoDataAlert(false);
          }

          // Transform data once
          // for a more efficient search time
          const transformedClickmapData = getVisibleClickmaps(
            res.data.data.records
          );

          setClickmapData({
            ...clickmapData,
            [pageUrl]: transformedClickmapData,
          });
          setRawClickmapData({
            ...rawClickmapData,
            [pageUrl]: res.data.data.records,
          });
          setIsRawClickmapDataLoaded({
            ...isRawClickmapDataLoaded,
            [pageUrl]: true,
          });
          setIsClickmapDataLoaded({
            ...isClickmapDataLoaded,
            [pageUrl]: true,
          });

          if (callback && typeof callback === "function") {
            callback();
          }
        }
      })
      .catch((error) => handleAnalyticsApiError(error, "read clickmap", setIsClickmapDataLoading));
  };

  const fetchClickmapData = ({
    pageUrl,
    operator,
    segments = selectedSegments,
    start = startDate.format("YYYY-MM-DD"),
    end = endDate.format("YYYY-MM-DD"),
    callback,
  }) => {
    setIsClickmapDataLoading(true);
    fetchScrollingHeatmapData(pageUrl, segments, start, end);
    const pageUrlObj = {
      operator: operator
        ? operator
        : filterConditions[0].conditions[0].operator,
      values: pageUrl ? [pageUrl] : filterConditions[0].conditions[0].values,
    };
    api.cancel("queryClickmap");
    api
      .queryClickmap(authUser, {
        profileId,
        startDate: start,
        endDate: end,
        pageURL: JSON.stringify(pageUrlObj),
        segments: JSON.stringify(
          Object.keys(segments).map((key) => {
            return key;
          })
        )
      })
      .then((res) => {
        const { key, queryId } = res.data.data;
        readClickmap({ queryId: queryId, key, callback });
      })
      .catch((error) => handleAnalyticsApiError(error, "query clickmap", setIsClickmapDataLoading));
  };

  const readScrollmap = ({ queryId, key }) => {
    api.cancel("readScrollmap");
    api
      .readScrollmap(authUser, {
        profileId,
        queryId,
        key,
      })
      .then((res) => {
        if (res.data.status === 100) {
          readScrollmap({ queryId, key });
        }
        if (res.data.status === 200) {
          setIsScrollmapDataLoading(false);

          const data =
            res.data.data.records && res.data.data.records[0]
              ? res.data.data.records[0]
              : {};

          const sortedScrollData =
            res.data.data.records && res.data.data.records[0]
              ? res.data.data.records[0].scrollData.sort((a, b) => {
                  return a.max - b.max;
                })
              : [];

          data.scrollData = sortedScrollData;

          setScrollmapData({
            ...scrollmapData,
            [pageUrl]: data,
          });
          setIsScrollmapDataLoaded({
            ...isScrollmapDataLoaded,
            [pageUrl]: true,
          });
        }
      })
      .catch((error) => handleAnalyticsApiError(error, "read scrollmap", setIsScrollmapDataLoading));
  };

  const fetchScrollingHeatmapData = (pageUrl, segments, start, end) => {
    setIsScrollmapDataLoading(true);
    api.cancel("queryScrollmap");
    const pageUrlObj = {
      operator: filterConditions[0].conditions[0].operator,
      values: pageUrl ? [pageUrl] : filterConditions[0].conditions[0].values,
    };
    api
      .queryScrollmap(authUser, {
        profileId,
        startDate: start,
        endDate: end,
        pageURL: JSON.stringify(pageUrlObj),
        segments: JSON.stringify(
          Object.keys(segments).map((key) => {
            return key;
          })
        ),
      })
      .then((res) => {
        const { key, queryId } = res.data.data;
        readScrollmap({ queryId: queryId, key });
      })
      .catch((error) => handleAnalyticsApiError(error, "query scrollmap", setIsScrollmapDataLoading));
  };

  const handleClickFilterChange = (e) => {
    const target = e.target;
    const id = target.dataset["eventid"];
    const newEventFiltersMap = { ...eventFilters.map };
    let newEventFiltersList;

    if (target.checked) {
      newEventFiltersMap[id] = true;
    } else {
      delete newEventFiltersMap[id];
    }
    newEventFiltersList = Object.keys(newEventFiltersMap);

    saveUserPrefs({ eventFilters: newEventFiltersMap }, profileId);

    setEventFilters({
      map: {
        ...newEventFiltersMap,
      },
      list: [...newEventFiltersList],
    });
  };

  const handleClickTab = async (e) => {
    const tabKey = e.currentTarget.dataset["tabkey"];
    setActiveTab(tabKey);
    if (tabKey === "analytics") {
      // pause the play when "analytics" tab is open
      if (playing) {
        pause();
      }
    } else {
      if (disableInspectHandlerRef.current) {
        disableInspectHandlerRef.current();
      }
    }
  };

  const handleChangeProgress = (e) => {
    const progress = parseInt(e.target.value);
    if (playing) {
      clearTimeout(playTimeoutRef.current);
      clearTimeout(intervalRef.current);
      playElapsedEvents(progress);
      playTimeoutRef.current = setTimeout(() => {
        playRef.current();
      }, 0);
      return;
    } else {
      playElapsedEvents(progress);
    }
  };

  const pause = () => {
    if (document.getElementById("replay-overlay") !== null) {
      document
        .getElementById("replay-overlay")
        .parentNode.removeChild(document.getElementById("replay-overlay"));
    }
    if (document.getElementById("replay-mouse") !== null) {
      // remove mouse div element
      document
        .getElementById("replay-mouse")
        .parentNode.removeChild(document.getElementById("replay-mouse"));
    }
    setPlaying(false);
    clearTimeout(intervalRef.current);
    clearTimeout(playTimeoutRef.current);
  };

  const handleChangePlaySpeed = (e) => {
    const playbackSpeed = Number(e.currentTarget.dataset["speed"]);
    saveUserPrefs({ playbackSpeed }, profileId);
    stepInterval.current = 50 / playbackSpeed;
    setPlaySpeed(playbackSpeed);
  };

  const handleClickPlayEvent = (e) => {
    const eventStartTime = e.currentTarget.dataset["timestamp"];
    setProgress(eventStartTime);
  };

  const handleClickPlayNote = (e) => {
    const timestamp = e.currentTarget.dataset["timestamp"];
    clearTimeout(intervalRef.current);
    clearTimeout(playTimeoutRef.current);
    playElapsedEvents(timestamp);
    setPlaying(false);
  };

  const handleScreenResize = () => {
    if (pageviews.length) {
      setReplaySize(
        pageviews[currentPageviewIndex].browserWidth,
        pageviews[currentPageviewIndex].browserHeight
      );
    }
  };

  const handleClickFullscreen = () => {
    if (!replayScreen) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      replayScreen = document.getElementById("replay-screen-area");
    }
    if (replayScreen) {
      if (isFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      } else {
        replayScreen.requestFullscreen();
        setIsFullscreen(true);
      }
    }
  };

  const handleCloseDrawer = () => {
    setIsDrawerVisible(false);
    setIsReplayViewerVisible(true);
    setFocusedJSON(null);
    setFocusedEventTitle("");
  };

  const handleChangeClickmapMode = (values) => {
    // reset filter to all when click map mode changes
    setClickmapDataFilter({ label: "All", value: "all" });
    setAnalyticsClickmapMode(values.value);
  };

  const handleChangeClickmapDataFilter = ({ label, value }) => {
    setClickmapDataFilter({ label, value });
  };

  const handleChangeFormAnalysisMode = (values) => {
    setAnalyticsFormAnalysisMode(values.value);
  };

  const handleChangeFricitionmapMode = (values) => {
    setAnalyticsFrictionmapMode(values.value);
  };

  const handleChangeAnalysisMode = (element, isOpen) => {
    const dataMode = element.dataset["mode"];
    const mode = dataMode && isOpen ? dataMode : null;
    setAnalyticsMode(mode);
  };

  const handleClickFiltersApplied = () => {
    setIsFilterDrawerVisible(true);
    setIsReplayViewerVisible(false);
  };

  const handleClickHideFiltersDrawer = () => {
    setIsFilterDrawerVisible(false);
    setIsReplayViewerVisible(true);
  };

  const handleClickRefreshData = () => {
    setIsDrawerFetchingData(true);
    fetchClickmapData({
      segments: selectedSegments,
      start: selectedDates.startDate.format("YYYY-MM-DD"),
      end: selectedDates.endDate.format("YYYY-MM-DD"),
      callback: () => {
        setIsDrawerFetchingData(false);
        setIsFilterDrawerVisible(false);
        setIsReplayViewerVisible(true);
      },
    });
  };

  const handleClickShowData = (e) => {
    const pageViewIndex = e.currentTarget.dataset["pageviewindex"];
    const eventIndex = e.currentTarget.dataset["eventindex"];
    const eventTitle = e.currentTarget.dataset["eventtitle"];
    const event = pageviews[pageViewIndex].events[eventIndex];

    setFocusedJSON(event);
    setFocusedEventTitle(eventTitle);
    setIsDrawerVisible(true);
    setIsReplayViewerVisible(false);
  };

  const handleClickShowEventFilters = (e) => {
    setIsEventsFilterModalVisible(true);
    setIsReplayViewerVisible(false);
  };

  const handleClickHideEventFilters = (e) => {
    setIsEventsFilterModalVisible(false);
    setIsReplayViewerVisible(true);
  };

  const handleChangeListFilterScrollPixel = (e) => {
    setListFilterScrollPixel(Math.floor(e.target.value));
  };

  const handleClickShowFilterModal = (e) => {
    const clickmapId = e.currentTarget.dataset["clickmapid"];
    setFilterModalClickmapId(clickmapId);
    processSelectedElementData({
      eleId: clickmapId,
      setter: setFilterElementData,
      data: clickmapLinks[clickmapId]?.data,
    });
    setIsFilterModalVisible(true);
    setIsReplayViewerVisible(false);
  };

  const handleClickHideFilterModal = () => {
    setFilterModalClickmapId(null);
    setFilterElementData(null);

    setListFilterMode("click");
    setFilterModalElementContext(deepCopy(filterModalElementContextFixture));
    setListFilterScrollPixel(0);
    setIsFilterModalVisible(false);
    setIsReplayViewerVisible(true);
  };

  const handleToggleSkipIdle = () => {
    saveUserPrefs({ skipIdle: !skipIdle }, profileId);
    setSkipIdle(!skipIdle);
  };

  const handleCloseViewer = (e) => {
    pause();
    handleClickCancel();
  };

  const handleChangeActionType = (e) => {
    const type = e.target.value;
    setListFilterAction(type);
  };

  const handleClickFilterView = () => {
    props.history.push("/profile/" + profileId + "/analytics/dashboard", {
      overrideFilters: listFilterConditions,
    });
  };

  const handleClickAddNote = () => {
    setIsNoteModalVisible(true);
    setIsReplayViewerVisible(false);
    setFocusedNoteId(null);
  };

  const handleCloseNoteModal = () => {
    setIsNoteModalVisible(false);
    setIsReplayViewerVisible(true);
    setFocusedNoteId(null);
  };

  const handleClickEditNote = (e) => {
    const noteId = e.currentTarget.dataset["noteid"];
    setIsNoteModalVisible(true);
    setIsReplayViewerVisible(false);
    setFocusedNoteId(noteId);
  };

  const handleClickDeleteNote = (e) => {
    const noteId = e.currentTarget.dataset["noteid"];

    dispatch({
      type: OPEN_CONFIRM,
      confirmConfig: {
        title: "Confirm Action",
        text: "Are you sure you want to delete this note?",
        confirmText: "Delete Note",
        confirmAction: () => {
          dispatch({ type: CONFIRM_BUSY });
          deleteNoteById(profileId, noteId).then(() => {
            dispatch({ type: CONFIRM_IDLE });
            dispatch({ type: CLOSE_CONFIRM });
          });

          const updatedAll = [...notes.all].filter((el) => el !== noteId);

          const updatedById = { ...notes.byId };
          delete updatedById[noteId];

          const updatedNotes = {
            all: [...updatedAll],
            byId: { ...updatedById },
          };
          setNotes(updatedNotes);
        },
      },
    });
  };

  useEffect(() => {
    return () => {
      // cancele api calling when component is unmounted
      api.cancel("readClickmap");
      api.cancel("queryClickmap");
      api.cancel("readScrollmap");
      api.cancel("queryScrollmap");
      api.cancel("getSessionEvents");

      // clear timeout when component is unmounted
      clearTimeout(intervalRef.current);
      clearTimeout(playTimeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (!isApiServerLoading) {
      setVisitedReplays({
        ...visitedReplays,
        [`${visitorId + "." + sessionNumber}`]: true,
      });

      api
        .getSessionEvents(authUser, {
          profileId: profileId,
          visitorId: visitorId,
          sessionNumber: sessionNumber,
        })
        .then((res) => {
          if (res.status === 200) {
            setIsLoading(false);

            if (res.data.status === 200) {
              setIsReplayViewerVisible(true);
              if (autoPlay) {
                clearTimeout(playTimeoutRef.current);
                setPlaying(true);
                playTimeoutRef.current = setTimeout(() => {
                  playRef.current();
                }, 0);
              }

              var pageviewsArray = [];
              var skipPageviewsMap = {};
              // flatten the mousemovement, scrolling and resize event data
              var i;
              for (i in res.data.data) {
                var pageview = {};
                var keys = Object.keys(res.data.data[i]);
                // read the pageview property to the pageview object, except events
                for (var key of keys) {
                  if (key !== "events" && key !== "eventObjects") {
                    pageview[key] = res.data.data[i][key];
                  }
                }
                if (!liteMode) {
                  skipPageviewsMap[pageview.pageviewId] = true;
                }
                var events = [];
                var j;
                var url = pageview.url;
                if (res.data.data[i].eventObjects !== null) {
                  pageview.format = 2;
                  pageview.noDomPage = true;
                  setFormatVersion(2);

                  // new format
                  for (j in res.data.data[i].eventObjects) {
                    if (res.data.data[i].eventObjects[j].t === 2) {
                      pageview.noDomPage = false;
                      delete skipPageviewsMap[pageview.pageviewId];
                    }
                    if (
                      [11, 12, 15].includes(res.data.data[i].eventObjects[j].t)
                    ) {
                      // Events are grouped for 11:mousemouve, 12:scrolling, 15:resize
                      var k;
                      for (k in res.data.data[i].eventObjects[j].data) {
                        var event = {};
                        event.t = res.data.data[i].eventObjects[j].t;
                        event.ct = res.data.data[i].eventObjects[j].data[k].t;
                        event.d = [res.data.data[i].eventObjects[j].data[k]];
                        event.pid = pageview.pageviewId;
                        event.url = url;
                        events.push(event);
                      }
                    } else {
                      let event = {};
                      event.t = res.data.data[i].eventObjects[j].t;
                      event.ct = res.data.data[i].eventObjects[j].d;
                      event.d = res.data.data[i].eventObjects[j].data;
                      event.pid = pageview.pageviewId;
                      if (res.data.data[i].eventObjects[j].t === 19) {
                        // Url change event : will update the url for the next events
                        url = res.data.data[i].eventObjects[j].url;
                      }
                      event.url = url;
                      events.push(event);
                    }
                  }

                  // check page flag to insert fake events for no dom page
                  if (events.length > 0 && pageview.noDomPage && liteMode) {
                    let pageDuration =
                      events[events.length - 1].ct - events[0].ct;

                    events.splice(1, 0, {
                      t: 1000, // fake event for dummy page
                      ct: events[0].ct,
                      pid: pageview.pageviewId,
                      url: url,
                      ...(pageDuration > 0 && { hasOtherEvents: true }),
                    });

                    if (pageDuration <= 0) {
                      events.push({
                        t: 1001, // fake page unload event
                        ct: events[events.length - 1].ct + 3000,
                        pid: pageview.pageviewId,
                        url: url,
                      });
                    }
                  }
                } else {
                  // decommissioned
                }
                pageview.events = events;
                pageviewsArray.push(pageview);
              }

              if (pageviewsArray.length === 0) {
                setCantLoadReplay(true);
                setAlert({
                  show: true,
                  type: "danger",
                  message: "The data for this session replay has expired.",
                  count: alert.count + 1,
                });
              } else {
                setPageviews(pageviewsArray);
                setSkipPageviews(skipPageviewsMap);
                setInitialTimestamp(pageviewsArray[0].events[0].ct);
              }
            } else { // Issue loading the replay
              setCantLoadReplay(true);
              setAlert({
                show: true,
                type: "danger",
                message: "The data for this session replay has expired.",
                count: alert.count + 1,
              });
              Sentry.captureMessage("Replay expired", {level: "log"});
            }
          }
        })
        .catch((err) => {
          if (api.isCancel(err)) {
            return;
          }
          setCantLoadReplay(true);
          setAlert({
            show: true,
            type: "danger",
            message: "Something went wrong.",
            count: alert.count + 1,
          });
          setIsLoading(false);
          captureExceptionToSentry(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiServerLoading]);

  useEffect(() => {
    setReplaySize(0, 0);
    if (pageviews !== null && pageviews.length > 0) {
      if (!replayScreen) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        replayScreen = document.getElementById("replay-screen-area");
      }

      detectCurrentPageview();
      preLoadPage();
      setInitialTimestamp(pageviews[0].events[0].ct);
      replayIframe.contentWindow.document.addEventListener(
        "keydown",
        preventEvent,
        true
      );
      replayIframe.contentWindow.document.addEventListener(
        "click",
        preventEvent,
        true
      );
      replayIframe.contentWindow.document.addEventListener(
        "mouseover",
        preventEvent,
        true
      );
      window.removeEventListener("resize", handleScreenResize);
      window.addEventListener("resize", handleScreenResize);
    }

    //unmount
    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageviews]);

  // Set hovered element data by clickmap data
  useEffect(() => {
    if (hovered) {
      processSelectedElementData({
        eleId: hovered,
        setter: setHoveredData,
        data: clickmapData[pageUrl]["byCssSelector"][hovered],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  // Set filter modal condition data
  useEffect(
    () => {
      // CLICK
      // HOVER
      // NO_HOVER
      // HOVER_NO_CLICK
      // LAST_CLICK
      // RAGE_CLCIK
      const newCondition = deepCopy(initialListFilterConditions);

      if (
        pageUrl &&
        clickmapData[pageUrl] &&
        filterModalClickmapId &&
        listFilterMode === "click"
      ) {
        const data = clickmapData[pageUrl].byCssSelector[filterModalClickmapId];
        let selectors = [];
        let selector = data && data.css ? data.css : filterModalClickmapId;

        selectors.push(selector);
        const element = querySelector(
          replayIframe.contentWindow.document,
          formatCss(selector)
        );

        if (analyticsClickmapMode === "links") {
          // find all descendents
          const descendents = findDesendentElements(element);
          descendents.forEach((ele) => {
            selectors.push(getCSSPath(ele));
          });
        }
        let text = null;
        if (
          element &&
          element.childNodes &&
          element.childNodes.length === 1 &&
          element.childNodes[0].nodeType === Node.TEXT_NODE
        ) {
          text = element.childNodes[0].nodeValue;
        }
        setFilterModalElementContext({
          element: element,
          text,
        });
        switch (listFilterAction) {
          case "CONTENT_LOADED":
            newCondition.pageContainers[0].eventConditions[0] = {
              type: "ContentLoad",
              conditions: [
                {
                  attribute: "ContentSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [filterModalClickmapId],
                },
                {
                  attribute: "ContentText",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [filterModalElementContext.text],
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;

          case "CLICK":
            newCondition.pageContainers[0].eventConditions[0] = {
              type: "Click",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "LAST_CLICK":
            newCondition.pageContainers[0].eventConditions[0] = {
              type: "LastClick",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "LAST_FIELD_CLICK":
            newCondition.pageContainers[0].eventConditions[0].type =
              "VisitPage";
            newCondition.pageContainers[0].eventConditions[0].conditions = [
              {
                attribute: "PageURL",
                path: "",
                unit: "",
                operator: "is",
                values: [pageUrl],
              },
            ];
            newCondition.pageContainers[0].eventConditions[1] = {
              type: "LastFieldClick",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
              ],
            };
            break;
          case "NO_CLICK":
            newCondition.pageContainers[1].eventConditions[0] = {
              type: "Click",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "RAGE_CLICK":
            newCondition.pageContainers[0].eventConditions[0] = {
              type: "RageClick",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "HOVER":
            newCondition.pageContainers[0].eventConditions[0] = {
              type: "Hover",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "NO_HOVER":
            newCondition.pageContainers[1].eventConditions[0] = {
              type: "Hover",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "HOVER_NO_CLICK":
            newCondition.pageContainers[1].eventConditions[0] = {
              type: "Click",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            newCondition.pageContainers[0].eventConditions[0] = {
              type: "Hover",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "INPUT":
            newCondition.pageContainers[0].eventConditions[0] = {
              type: "FieldChange",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "NO_INPUT":
            newCondition.pageContainers[1].eventConditions[0] = {
              type: "FieldChange",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          case "CLICK_NO_INPUT":
            newCondition.pageContainers[1].eventConditions[0] = {
              type: "FieldChange",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };

            newCondition.pageContainers[0].eventConditions[0] = {
              type: "Click",
              conditions: [
                {
                  attribute: "CSSSelector",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: selectors,
                },
                {
                  attribute: "PageURL",
                  path: "",
                  unit: "",
                  operator: "is",
                  values: [pageUrl],
                },
              ],
            };
            break;
          default:
            break;
        }
        setListFilterConditions(newCondition);
      }

      if (pageUrl && clickmapData[pageUrl] && listFilterMode === "scroll") {
        newCondition.pageContainers[0].eventConditions[0] = {
          type: "Scroll",
          conditions: [
            {
              attribute: "ScrollDepthPixel",
              path: "",
              unit: "",
              operator: "isAtLeast",
              values: [String(listFilterScrollPixel)],
            },
            {
              attribute: "PageURL",
              path: "",
              unit: "",
              operator: "is",
              values: [pageUrl],
            },
          ],
        };
        setListFilterConditions(newCondition);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      listFilterAction,
      listFilterMode,
      listFilterScrollPixel,
      clickmapData,
      pageUrl,
      filterModalClickmapId,
    ]
  );

  // Update the latest clickmapLinks data, so handleScroll can access it
  useEffect(() => {
    clickmapLinksRef.current = clickmapLinks;
  }, [clickmapLinks]);

  const handleScroll = () => {
    const doc = replayIframe.contentWindow.document;
    const overlay = doc.querySelector("#is-replay-overlay"); // Todo : prevent querySelector
    const highlightLayer = doc.querySelector("#is-replay-highlight-layer"); // Todo : prevent querySelector
    if (
      overlay !== null &&
      overlay.parentElement !== null &&
      highlightLayer !== null
    ) {
      if (analyticsMode === "scrollmap") {
        if (overlay.nextSibling !== null) {
          overlay.parentElement.removeChild(overlay.nextSibling);
        }
        overlay.parentElement.removeChild(overlay);
      }
      if (
        activeTab === "analytics" &&
        isClickmapDataLoaded[pageUrl] &&
        isScrollmapDataLoaded[pageUrl] &&
        (analyticsMode === "clickmap" ||
          analyticsMode === "frictionmap" ||
          analyticsMode === "form-analysis")
      ) {
        const scrollY = replayIframe.contentWindow.scrollY;
        const pageYOffset = replayIframe.contentWindow.pageYOffset; // Cache pageYOffset once before the loop

        // Only update the `top` styles on overlay and highlightLayer
        overlay.style.top = `${scrollY * -1}px`;
        highlightLayer.style.top = `${scrollY * -1}px`;

        // Update the `top` style of each highlighted element
        const currentClickmapLinks = clickmapLinksRef.current; // Use the current value of clickmapLinks from the ref
        const elementsTopMap = new Map();
        Object.keys(currentClickmapLinks).forEach((key) => {
          const data = currentClickmapLinks[key];

          if (data?.targetElement && data?.highlightElement) {
            const rect = data.targetElement.getBoundingClientRect(); // getBoundingClientRect access the DOM, prevent changing DOM between each iteration
            elementsTopMap.set(data.highlightElement, rect.top + pageYOffset);
          }
        });
        // Apply style on all emements in one batch
        for (let [highlightElement, top] of elementsTopMap) {
          highlightElement.style.top = `${top}px`; // Update with new scroll position
        }
      }
      if (
        activeTab === "analytics" &&
        isClickmapDataLoaded[pageUrl] &&
        isScrollmapDataLoaded[pageUrl] &&
        analyticsMode === "scrollmap"
      ) {
        enableScrollingHeatmap();
      }
    }
  };

  useEffect(() => {
    if (activeTab !== "analytics") {
      cleanupIframeElements();
    }

    if (
      activeTab === "analytics" &&
      pageUrl &&
      !isScrollmapDataLoaded[pageUrl] &&
      !isClickmapDataLoaded[pageUrl]
    ) {
      // Reset filter to "is" when pageurl changes
      // and data is not loaded yet
      // and it is not from note (copy link)
      if (
        !props.location.state?.replayOptions ||
        !props.location.state?.replayOptions?.noteId
      ) {
        setFilterConditions([
          {
            type: "VisitPage",
            inclusion: true,
            conditions: [
              {
                attribute: "PageURL",
                path: "",
                unit: "",
                operator: "is",
                values: [pageUrl],
              },
            ],
          },
        ]);

        fetchClickmapData({ pageUrl, operator: "is" });
      }
    }

    if (
      activeTab === "analytics" &&
      isClickmapDataLoaded[pageUrl] &&
      isScrollmapDataLoaded[pageUrl]
    ) {
      const doc = replayIframe.contentWindow.document;

      if (handleScrollRef.current) {
        doc.removeEventListener("scroll", handleScrollRef.current);
      }

      if (analyticsMode !== "elements") {
        if (disableInspectHandlerRef.current) {
          disableInspectHandlerRef.current();
        }
      }

      if (analyticsMode !== "clickmap") {
        cleanupIframeElements();
      }

      if (analyticsMode === "elements") {
        enableInspectElement();
      }

      if (
        analyticsMode === "clickmap" ||
        analyticsMode === "frictionmap" ||
        analyticsMode === "form-analysis" ||
        analyticsMode === "scrollmap"
      ) {
        doc.addEventListener("scroll", handleScroll);
        handleScrollRef.current = handleScroll;

        if (analyticsMode === "clickmap") {
          // Clickmap - Links
          if (analyticsClickmapMode === "links") {
            cleanupIframeElements();
            enableClickmapLayer("clickmap-links");
          }

          // Clickmap - All elements
          if (analyticsClickmapMode === "all") {
            cleanupIframeElements();
            enableClickmapLayer("clickmap-elements");
          }

          // Clickmap - Last Element Click
          if (analyticsClickmapMode === "lastElementClick") {
            cleanupIframeElements();
            enableClickmapLayer("clickmap-lastElementClick");
          }
        }

        if (analyticsMode === "frictionmap") {
          // Frictionmap - Rage Clicks
          if (analyticsFrictionmapMode === "rageClicks") {
            cleanupIframeElements();
            enableClickmapLayer("frictionmap-rageClicks");
          }
        }

        // Form Analysis - Last Clicks
        if (analyticsMode === "form-analysis") {
          if (analyticsFormAnalysisMode === "lastClicks") {
            cleanupIframeElements();
            enableClickmapLayer("form-analysis-lastClicks");
          }
          if (analyticsFormAnalysisMode === "mostClicks") {
            cleanupIframeElements();
            enableClickmapLayer("form-analysis-mostClicks");
          }
        }

        if (analyticsMode === "scrollmap") {
          cleanupIframeElements();
          enableScrollingHeatmap();
        }
      }
    }

    if (activeTab === "notes" && !isNotesLoaded && pageviews) {
      setIsNotesLoading(true);

      // Fetch Notes and set to notes
      fetchSessionNotes({
        profileId,
        sessionKey: `${visitorId}.${sessionNumber}`,
      }).then((res) => {
        const data = {
          byId: {},
          all: [],
        };
        Object.keys(res).forEach((id) => {
          data.byId[id] = res[id];
          data.all.push(id);
        });
        data.all.sort((a, b) => {
          return data.byId[a].noteTime - data.byId[b].noteTime;
        });
        setNotes(data);
        setIsNotesLoading(false);
        setIsNotesLoaded(true);
      });

      // Fetch Users Profiles
      if (!profileUsers.all.length) {
        fetchUsersByProfileId(profileId).then((res) => {
          const users = {
            byId: {},
            all: [],
          };
          res.docs.forEach((user) => {
            users.byId[user.id] = user.data();
            users.all.push(user.id);
          });
          setProfileUsers(users);
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageUrl,
    activeTab,
    analyticsMode,
    analyticsClickmapMode,
    analyticsFormAnalysisMode,
    isClickmapDataLoaded,
    isScrollmapDataLoaded,
    clickmapDataFilter.value,
  ]);

  // Set filtering mode base on analytics tab (for Filter Modal redirection)
  useEffect(() => {
    if (activeTab === "analytics") {
      if (analyticsMode === "scrollmap") {
        setListFilterMode("scroll");
      } else {
        setListFilterMode("click");
        if (
          analyticsMode === "clickmap" &&
          analyticsClickmapMode === "lastElementClick"
        ) {
          setListFilterAction("LAST_CLICK");
        } else if (
          analyticsMode === "form-analysis" &&
          analyticsFormAnalysisMode === "lastClicks"
        ) {
          if (
            filterModalElementContext.element &&
            ["INPUT", "TEXTAREA", "SELECT"].indexOf(
              filterModalElementContext.element.tagName
            ) > -1
          ) {
            setListFilterAction("LAST_FIELD_CLICK");
          }
        } else if (
          analyticsMode === "frictionmap" &&
          analyticsFrictionmapMode === "rageClicks"
        ) {
          setListFilterAction("RAGE_CLICK");
        } else {
          setListFilterAction("CLICK");
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeTab,
    analyticsMode,
    analyticsClickmapMode,
    analyticsFormAnalysisMode,
    analyticsFrictionmapMode,
    filterModalElementContext.element,
  ]);

  // If user Copy Link from a note
  // We opened the replay from a note : set segments, filter & daterange from the note
  useEffect(() => {
    if (!props.location.state?.replayOptions) {
      return;
    }
    const replayOptions = props.location.state.replayOptions;

    // Initialize the replay with segments, filters & date range from the Note
    if (pageviews && !isInitialized) {
      if (replayOptions.segments) {
        setSelectedSegments(replayOptions.segments);
      }
      if (replayOptions.noteId) {
        setFilterConditions([
          {
            type: "VisitPage",
            inclusion: true,
            conditions: [
              {
                attribute: replayOptions.filter.attribute,
                path: "",
                unit: "",
                operator: replayOptions.filter.operator,
                values: [...replayOptions.filter.values],
              },
            ],
          },
        ]);
        const startDate = moment(replayOptions.startDate);
        const endDate = moment(replayOptions.endDate);
        // "Set Date" button in the date picker
        setSelectedStartDate(startDate);
        setStartDate(startDate);
        setSelectedEndDate(endDate);
        setEndDate(endDate);

        setSelectedDates({
          startDate: startDate,
          endDate: endDate,
        });

        setDateRangeLabel(
          today,
          startDate,
          endDate,
          setSelectedDateRange,
          dateRanges
        );
      }

      setActiveTab(replayOptions.activeTab);

      if (analyticsModesObj[replayOptions.analyticsMode]) {
        setAnalyticsMode(analyticsModesObj[replayOptions.analyticsMode]?.mode);
      }

      if (analyticsModesObj[replayOptions.analyticsMode]?.subMode) {
        if (
          analyticsModesObj[replayOptions.analyticsMode]?.mode === "clickmap"
        ) {
          setAnalyticsClickmapMode(
            analyticsModesObj[replayOptions.analyticsMode]?.subMode
          );
          // Top x data filter
          if (replayOptions.clickmapDataFilter) {
            setClickmapDataFilter(replayOptions.clickmapDataFilter);
          }
        } else if (
          analyticsModesObj[replayOptions.analyticsMode]?.mode ===
          "form-analysis"
        ) {
          setAnalyticsFormAnalysisMode(
            analyticsModesObj[replayOptions.analyticsMode]?.subMode
          );
        } else if (
          analyticsModesObj[replayOptions.analyticsMode]?.mode === "frictionmap"
        ) {
          setAnalyticsFrictionmapMode(
            analyticsModesObj[replayOptions.analyticsMode]?.subMode
          );
        }
      }
      setIsInitialized(true);
      let startTime = 0;
      let previousDurationAggregate = 0;

      loop1: for (
        let pageViewIndex = 0, len = pageviews.length;
        pageViewIndex < len;
        pageViewIndex++
      ) {
        const currentPageview = pageviews[pageViewIndex];
        const previousPageview = pageviews[pageViewIndex - 1];
        const durationOfPreviousPageviewLastEvent =
          pageViewIndex > 0
            ? previousPageview.events[previousPageview.events.length - 1].ct -
              previousPageview.events[0].ct
            : 0;

        // add together duration of previous pageviews
        previousDurationAggregate += durationOfPreviousPageviewLastEvent;

        for (
          let eventIndex = 0, len2 = currentPageview.events.length;
          eventIndex < len2;
          eventIndex++
        ) {
          const currentEvent = currentPageview.events[eventIndex];
          if (
            (currentEvent.t === 1 || currentEvent.t === 19) &&
            currentEvent.url === replayOptions.url
          ) {
            startTime =
              currentEvent.ct -
              currentPageview.events[0].ct +
              previousDurationAggregate;
            break loop1;
          }
        }
      }
      pause();
      playElapsedEvents(startTime);
    }

    // Initialize progress once
    if (replayOptions.noteId && pageviews && !noteUrlId) {
      playElapsedEvents(replayOptions.time);
      setNoteUrlId(replayOptions.noteId);
    }

    if (replayOptions.noteId && pageviews && noteUrlId) {
      //when navigate to other pageUrls, need to set up fitler with the new one
      if (progress !== replayOptions.time) {
        setFilterConditions([
          {
            type: "VisitPage",
            inclusion: true,
            conditions: [
              {
                attribute: "PageURL",
                path: "",
                unit: "",
                operator: "is",
                values: [pageUrl],
              },
            ],
          },
        ]);
        //fetch data with new pageUrl, and use saved segments, date range
        fetchClickmapData({
          pageUrl: pageUrl,
          operator: "is",
          segments: selectedSegments,
          start: selectedDates.startDate.format("YYYY-MM-DD"),
          end: selectedDates.endDate.format("YYYY-MM-DD"),
          callback: () => {
            setIsDrawerFetchingData(false);
            setIsFilterDrawerVisible(false);
            setIsReplayViewerVisible(true);
          },
        });
      } else {
        //detectCurrentPageView() will finally set up the correct pageUrl
        //fetch the clickmap data each time pageUrl changes, but the finial one is the right one
        //pageUrl is set empty here because we want to fetch the data with filter already set up

        // Clear any existing timer and set a new one
        clearTimeout(fetchClickmapTimeoutRef.current);
        // Debouncing API Calls to avoid multiple concurrent calls cause by the pageUrl changes frequently
        fetchClickmapTimeoutRef.current = setTimeout(() => {
          fetchClickmapData({
            pageUrl: "",
            segments: selectedSegments,
            start: selectedDates.startDate.format("YYYY-MM-DD"),
            end: selectedDates.endDate.format("YYYY-MM-DD"),
            callback: () => {
              setIsDrawerFetchingData(false);
              setIsFilterDrawerVisible(false);
              setIsReplayViewerVisible(true);
            },
          });
        }, 200);
      }
    }

    // Cleanup function to clear any pending debounced calls
    return () => clearTimeout(fetchClickmapTimeoutRef.current);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitialized, pageviews, props.location.state, pageUrl]);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  // Listen keyboard event in replay: space, >, <, 1, 2, 4.
  useEffect(() => {
    const handleKeyDown = (e) => {
      let keyboardActions;
      if (e && e.code) {
        keyboardActions = replayKeyboardEvents.some(
          (k) => k["Value"] === e.code.toLowerCase()
        );
      } else {
        return;
      }

      if (
        keyboardActions &&
        isReplayViewerVisible &&
        !(document.activeElement.tagName.toLowerCase() === "textarea")
      ) {
        if (e.code.toLowerCase() === "space") {
          e.preventDefault();
          if (playing) {
            pause();
          } else {
            setPlaying(true);
            clearTimeout(playTimeoutRef.current);
            playTimeoutRef.current = setTimeout(() => {
              playRef.current();
            }, 0);
          }
        } else if (e.code.toLowerCase() === "arrowright") {
          e.preventDefault();
          var forwardProgress;
          duration - progressRef.current <= 5000
            ? (forwardProgress = duration)
            : (forwardProgress = progressRef.current + 5000);
          if (playing) {
            clearTimeout(intervalRef.current);
            clearTimeout(playTimeoutRef.current);
            playElapsedEvents(forwardProgress);
            playTimeoutRef.current = setTimeout(() => {
              playRef.current();
            }, 0);
            return;
          } else {
            playElapsedEvents(forwardProgress);
          }
        } else if (e.code.toLowerCase() === "arrowleft") {
          e.preventDefault();
          var backwardProgress;
          progressRef.current <= 5000
            ? (backwardProgress = 0)
            : (backwardProgress = progressRef.current - 5000);
          if (playing) {
            clearTimeout(playTimeoutRef.current);
            clearTimeout(intervalRef.current);
            playElapsedEvents(backwardProgress);
            playTimeoutRef.current = setTimeout(() => {
              playRef.current();
            }, 0);
            return;
          } else {
            playElapsedEvents(backwardProgress);
          }
        } else if (e.code.toLowerCase() === "digit1") {
          e.preventDefault();
          let speed = 1;
          saveUserPrefs({ speed }, profileId);
          stepInterval.current = 50 / speed;
          setPlaySpeed(speed);
        } else if (e.code.toLowerCase() === "digit2") {
          e.preventDefault();
          let speed = 2;
          saveUserPrefs({ speed }, profileId);
          stepInterval.current = 50 / speed;
          setPlaySpeed(speed);
        } else if (e.code.toLowerCase() === "digit4") {
          e.preventDefault();
          let speed = 4;
          saveUserPrefs({ speed }, profileId);
          stepInterval.current = 50 / speed;
          setPlaySpeed(speed);
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playing, isReplayViewerVisible, isFullscreen]);

  // If user click 'View Replay' button from the note list
  useEffect(() => {
    if (props.location.state && props.location.state.noteData) {
      setActiveTab("notes");
      setIsInitialized(true);
      if (isNotesLoaded) {
        if (
          props.location.state &&
          props.location.state.noteData &&
          props.location.state.noteData.time
        ) {
          playElapsedEvents(props.location.state.noteData.time);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNotesLoaded, isInitialized, props.location.state]);

  useEffect(() => {
    stepInterval.current = 50 / playSpeed;
    if (playing) {
      clearTimeout(intervalRef.current);
      clearTimeout(playTimeoutRef.current);
      playTimeoutRef.current = setTimeout(() => {
        playRef.current();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playSpeed]);

  //If user click the url with pid and eventId
  //Once pageviews are loaded, we finish calculating eventStartTimeRef
  useEffect(() => {
    if (pid && eid && pageviews !== null && pageviews.length > 0) {
      setActiveTab("events");
      setProgress(eventStartTimeRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageviews, pid, eid, eventStartTimeRef.current]);

  if (activeTab === "events") {
    let previousDurationAggregate = 0;
    pageviews &&
      pageviews.length &&
      pageviews.forEach((pageView, pageViewIndex) => {
        const previousPageview = pageviews[pageViewIndex - 1];
        const durationOfPreviousPageviewLastEvent =
          pageViewIndex > 0
            ? previousPageview.events[previousPageview.events.length - 1].ct -
              previousPageview.events[0].ct
            : 0;

        // add together duration of previous pageviews
        previousDurationAggregate += durationOfPreviousPageviewLastEvent;

        return pageView.events.forEach((currentEvent, eventIndex) => {
          const eventType =
            !!eventTypes[currentEvent.t] && eventTypes[currentEvent.t].name;
          const urlEventMatch =
            pid &&
            pageView.pageviewId.toString() === pid.toString() &&
            eid &&
            currentEvent.ct.toString() === eid.toString();

          //select the event filter based on the matching event type
          if (urlEventMatch && !eventFilters.map[currentEvent.t]) {
            const updatedEventFilter = { ...eventFilters };
            updatedEventFilter.map[currentEvent.t] = true;
            updatedEventFilter.list.push(currentEvent.t.toString());
            setEventFilters({ ...updatedEventFilter });
          }

          if (
            eventFilters.list.length > 0 &&
            !eventFilters.map[currentEvent.t]
          ) {
            return null;
          }

          eventsCount += 1;

          let selector = "";
          if (formatVersion === 2) {
            selector =
              currentEvent.d && (currentEvent.t === 7 || currentEvent.t === 98)
                ? ` ${currentEvent.d.p}`
                : "";
          }
          if (formatVersion === 1) {
            // decommissioned
          }

          let eventTimestamp;
          //[11, 12, 15] means ["Mouse movement, "Scrolling", "Window Resize]
          if ([11, 12, 15].includes(currentEvent.t)) {
            //pageView.events[0].ct is the smallest timestamp
            //need to always use the timestamp larger than the smallest
            if (currentEvent.d[0].ct - pageView.events[0].ct >= 0) {
              eventTimestamp = currentEvent.d[0].ct;
            } else {
              eventTimestamp = currentEvent.d[0].t;
            }
          } else {
            eventTimestamp = currentEvent.ct; //assign ct if currentEvent not in the array
          }

          const startTime =
            eventTimestamp - pageView.events[0].ct + previousDurationAggregate;
          if (urlEventMatch) {
            eventStartTimeRef.current = startTime;
          }

          const duration = moment.duration(startTime, "milliseconds");
          eventComponents.push(
            <tr
              key={`${pageView.pageviewId}-${pageViewIndex}-${eventIndex}`}
              className={`${
                progress < startTime ? "" : " elapsed"
              } ${renderEventBackgroundColor(currentEvent.t)}`}
            >
              <td>
                <span
                  className={`
                    timestamp
                    ${
                      progress < startTime
                        ? " bg-success text-gray-100"
                        : " elapsed"
                    }
                  `}
                >
                  {duration.format("HH:mm:ss", {
                    trim: false,
                  })}
                </span>
              </td>
              <td width="100%">
                <strong>{eventType}</strong>
                <div className="buttons ml-4">
                  <Button
                    onClick={handleClickPlayEvent}
                    data-eventtitle={`${eventTypes[currentEvent.t]?.name}`}
                    data-timestamp={startTime}
                    data-pageviewindex={pageViewIndex}
                    data-eventindex={eventIndex}
                    variant="plain"
                    icon="fa fa-play"
                    size="x-small"
                  ></Button>
                  <Button
                    onClick={handleClickShowData}
                    data-eventtitle={`${eventType}${selector}`}
                    data-pageviewindex={pageViewIndex}
                    data-eventindex={eventIndex}
                    variant="plain"
                    icon="fa fa-file-alt"
                    size="x-small"
                    className="ml-2"
                  ></Button>
                </div>
                {selector !== "" && <br />}
                {selector}
              </td>
            </tr>
          );
        });
      });
  }

  return (
    <OverlayScreen
      handleClickCancel={handleCloseViewer}
      title="Analysis Replay"
    >
      {cantLoadReplay && (
        <Alert
          show={alert.show}
          type={alert.type}
          message={alert.message}
          count={alert.count}
        />
      )}
      {!cantLoadReplay && (
        <div className="replay-viewer">
          <div className="main-panel">
            <ReplayComponent
              handleClickAddNote={handleClickAddNote}
              handleToggleSkipIdle={handleToggleSkipIdle}
              isLoading={isLoading}
              isFullscreen={isFullscreen}
              pageviews={pageviews}
              activePageviewIndex={activePageviewIndex}
              initialTimestamp={initialTimestamp}
              progress={progress}
              pause={pause}
              setProgress={setProgress}
              setReplaySize={setReplaySize}
              handleScreenResize={handleScreenResize}
              handleClickFullscreen={handleClickFullscreen}
              handleChangePlaySpeed={handleChangePlaySpeed}
              handleChangeProgress={handleChangeProgress}
              playing={playing}
              pageUrl={pageUrl}
              playSpeed={playSpeed}
              skipIdle={skipIdle}
              duration={duration}
              playButtonClick={playButtonClick}
              playElapsedEvents={playElapsedEvents}
            ></ReplayComponent>
          </div>
          <div className="side-panel">
            <ReplaySidePanelTabs
              {...props}
              // nav
              initialTimestamp={initialTimestamp}
              activeTab={activeTab}
              handleClickTab={handleClickTab}
              // details
              pageviews={pageviews}
              // events
              handleClickShowEventFilters={handleClickShowEventFilters}
              eventsCount={eventsCount}
              eventComponents={eventComponents}
              // analytics
              isClickmapDataLoading={isClickmapDataLoading}
              isPageClickmapDataLoaded={isClickmapDataLoaded[pageUrl]}
              analyticsMode={analyticsMode}
              handleChangeAnalysisMode={handleChangeAnalysisMode}
              hoveredData={hoveredData}
              isScrollmapDataLoading={isScrollmapDataLoading}
              isPageScrollmapDataLoaded={isScrollmapDataLoaded[pageUrl]}
              analyticsClickmapMode={analyticsClickmapMode}
              handleChangeClickmapMode={handleChangeClickmapMode}
              allClickmapData={allClickmapData}
              clickmapDataFilter={clickmapDataFilter}
              handleChangeClickmapDataFilter={handleChangeClickmapDataFilter}
              clickmapLinks={clickmapLinks}
              replayIframeDoc={replayIframe?.contentWindow?.document}
              totalPageviews={clickmapData[pageUrl]?.totals?.pageViews || 0}
              handleClickShowFilterModal={handleClickShowFilterModal}
              analyticsFrictionmapMode={analyticsFrictionmapMode}
              handleChangeFricitionmapMode={handleChangeFricitionmapMode}
              analyticsFormAnalysisMode={analyticsFormAnalysisMode}
              handleChangeFormAnalysisMode={handleChangeFormAnalysisMode}
              mouseData={mouseData}
              noDataAlert={noDataAlert}
              setNoDataAlert={setNoDataAlert}
              // notes
              isNotesLoading={isNotesLoading}
              isNotesLoaded={isNotesLoaded}
              notes={notes}
              profileId={profileId}
              handleClickEditNote={handleClickEditNote}
              handleClickDeleteNote={handleClickDeleteNote}
              handleClickPlayNote={handleClickPlayNote}
              pageClickmapData={clickmapData[pageUrl]}
              handleClickFiltersApplied={handleClickFiltersApplied}
            />
          </div>
          <FilterDrawer
            width="75%"
            selectedDateRange={selectedDateRange}
            setSelectedDateRange={setSelectedDateRange}
            filterConditions={filterConditions}
            setFilterConditions={setFilterConditions}
            isVisible={isFilterDrawerVisible}
            handleClose={handleClickHideFiltersDrawer}
            setSegments={setSegments}
            segments={segments}
            selectedSegments={selectedSegments}
            setSelectedSegments={setSelectedSegments}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            handleRefreshData={handleClickRefreshData}
            isFetchingData={isDrawerFetchingData}
          />
        </div>
      )}

      <Drawer
        title="Event Details"
        isVisible={isDrawerVisible}
        handleClose={handleCloseDrawer}
        className="event-details-drawer"
      >
        <div className="h6 pl-3 mb-3 text-gray-600">{focusedEventTitle}</div>
        <div className="json-container bg-gray-100 px-3 py-3 mb-3">
          <ReactJson enableClipboard={false} src={focusedJSON} />
        </div>
        <Button
          style={{ width: 150 }}
          variant="primary"
          size="small"
          onClick={handleCloseDrawer}
        >
          Ok
        </Button>
      </Drawer>
      {isNoteModalVisible && (
        <NoteModal
          handleClose={handleCloseNoteModal}
          setNotes={setNotes}
          notes={notes}
          noteId={focusedNoteId}
          pageviewKey={pageviews[activePageviewIndex].pageviewId}
          visitorId={visitorId}
          sessionNumber={sessionNumber}
          progress={progress}
          profileId={profileId}
          pageTitle={pageviews[activePageviewIndex].title}
          pageURL={pageUrl}
          processAnalyticsMode={processAnalyticsMode()}
          filterConditions={filterConditions}
          activeTab={activeTab}
          clickmapDataFilter={clickmapDataFilter}
        />
      )}
      <Modal
        width={"600px"}
        handleClose={handleClickHideFilterModal}
        isVisible={isFilterModalVisible}
        title={"Create Filter Condition"}
        footer={
          <>
            <Button
              onClick={handleClickHideFilterModal}
              variant="secondary"
              size="small"
            >
              Cancel
            </Button>
            <Button
              onClick={handleClickFilterView}
              variant="primary"
              size="small"
            >
              View Performance
            </Button>
          </>
        }
        additionalInfo={
          listFilterMode === "click" ? (
            <div className="footer-element-stats">
              <div className="mb-3">Element Summary</div>
              <ElementStats data={filterElementData} viewSize="lg" />
            </div>
          ) : null
        }
      >
        <div className="click-filter-form">
          Find <strong>Pageviews</strong> that
          {listFilterMode === "click" && (
            <>
              <select
                className="form-control form-control-sm"
                value={listFilterAction} // Bind the select value to the state
                onChange={handleChangeActionType}
              >
                <option value="CLICK">Click</option>
                <option value="NO_CLICK">Did not click</option>
                <option value="HOVER">Hover</option>
                <option value="NO_HOVER">Do not hover</option>
                <option value="HOVER_NO_CLICK">Hover without clicking</option>
                <option value="LAST_CLICK">Last Click</option>
                <option value="RAGE_CLICK">Rage Click</option>
                {filterModalElementContext.element &&
                  filterModalElementContext.element.childNodes.length === 1 &&
                  filterModalElementContext.element.childNodes[0].nodeType ===
                    3 &&
                  filterModalElementContext.element.childNodes[0] !== "" && (
                    <option value="CONTENT_LOADED">Content Loaded</option>
                  )}
                {filterModalElementContext.element &&
                  ["INPUT", "TEXTAREA", "SELECT"].indexOf(
                    filterModalElementContext.element.tagName
                  ) > -1 && (
                    <option value="LAST_FIELD_CLICK">Last Field Click</option>
                  )}
                {filterModalElementContext.element &&
                  ["INPUT", "TEXTAREA"].indexOf(
                    filterModalElementContext.element.tagName
                  ) > -1 && (
                    <>
                      <option value="INPUT">Input</option>
                      <option value="NO_INPUT">Did not input</option>
                      <option value="CLICK_NO_INPUT">
                        Clicked without inputting
                      </option>
                    </>
                  )}
              </select>
              the selected element
            </>
          )}
          {listFilterMode === "scroll" && (
            <>
              {""} reach to
              <input
                type="text"
                className="form-control form-control-sm d-inline-block mx-2"
                style={{ width: 150 }}
                value={listFilterScrollPixel}
                onChange={handleChangeListFilterScrollPixel}
              />
              pixels deep
            </>
          )}
        </div>
      </Modal>

      <Modal
        width={"600px"}
        isVisible={isEventsFilterModalVisible}
        title="Event Filter"
        className="event-filter-modal"
        handleClose={handleClickHideEventFilters}
      >
        <h6>
          <strong>Interaction Events</strong>
        </h6>
        <div className="row px-2 py-2 bg-gray-100 mb-4">
          <div className="col">
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["7"]}
                data-eventid={"7"}
                id="checkbox-7"
                type="checkbox"
                value={7}
              />
              <label htmlFor="checkbox-7">{eventTypes[7].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["13"]}
                data-eventid={"13"}
                id="checkbox-13"
                type="checkbox"
                value={13}
              />
              <label htmlFor="checkbox-13">{eventTypes[13].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["12"]}
                data-eventid={"12"}
                id="checkbox-12"
                type="checkbox"
                value={12}
              />
              <label htmlFor="checkbox-12">{eventTypes[12].name}</label>
            </p>
            <p className="mb-0">
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["11"]}
                data-eventid={"11"}
                id="checkbox-11"
                type="checkbox"
                value={11}
              />
              <label htmlFor="checkbox-11">{eventTypes[11].name}</label>
            </p>
          </div>
          <div className="col">
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["8"]}
                data-eventid={"8"}
                id="checkbox-8"
                type="checkbox"
                value={8}
              />
              <label htmlFor="checkbox-8">{eventTypes[8].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["14"]}
                data-eventid={"14"}
                id="checkbox-14"
                type="checkbox"
                value={14}
              />
              <label htmlFor="checkbox-14">{eventTypes[14].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["17"]}
                data-eventid={"17"}
                id="checkbox-17"
                type="checkbox"
                value={17}
              />
              <label htmlFor="checkbox-17">{eventTypes[17].name}</label>
            </p>
            <p className="mb-0">
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["98"]}
                data-eventid={"98"}
                id="checkbox-98"
                type="checkbox"
                value={98}
              />
              <label htmlFor="checkbox-98">{eventTypes[98].name}</label>
            </p>
          </div>
          <div className="col">
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["9"]}
                data-eventid={"9"}
                id="checkbox-9"
                type="checkbox"
                value={9}
              />
              <label htmlFor="checkbox-9">{eventTypes[9].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["15"]}
                data-eventid={"15"}
                id="checkbox-15"
                type="checkbox"
                value={15}
              />
              <label htmlFor="checkbox-15">{eventTypes[15].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["18"]}
                data-eventid={"18"}
                id="checkbox-18"
                type="checkbox"
                value={18}
              />
              <label htmlFor="checkbox-18">{eventTypes[18].name}</label>
            </p>
            <p className="mb-0">
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["97"]}
                data-eventid={"97"}
                id="checkbox-97"
                type="checkbox"
                value={97}
              />
              <label htmlFor="checkbox-97">{eventTypes[97].name}</label>
            </p>
          </div>
        </div>
        <h6>
          <strong>Non-interaction Events</strong>
        </h6>
        <div className="row px-2 py-2 bg-gray-100">
          <div className="col">
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["1"]}
                data-eventid={"1"}
                id="checkbox-1"
                type="checkbox"
                value={1}
              />
              <label htmlFor="checkbox-1">{eventTypes[1].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["19"]}
                data-eventid={"19"}
                id="checkbox-19"
                type="checkbox"
                value={19}
              />
              <label htmlFor="checkbox-19">{eventTypes[19].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["4"]}
                data-eventid={"4"}
                id="checkbox-4"
                type="checkbox"
                value={4}
              />
              <label htmlFor="checkbox-4">{eventTypes[4].name}</label>
            </p>
            <p className="mb-0">
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["10"]}
                data-eventid={"10"}
                id="checkbox-10"
                type="checkbox"
                value={10}
              />
              <label htmlFor="checkbox-10">{eventTypes[10].name}</label>
            </p>
          </div>
          <div className="col">
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["2"]}
                data-eventid={"2"}
                id="checkbox-2"
                type="checkbox"
                value={2}
              />
              <label htmlFor="checkbox-2">{eventTypes[2].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["5"]}
                data-eventid={"5"}
                id="checkbox-5"
                type="checkbox"
                value={5}
              />
              <label htmlFor="checkbox-5">{eventTypes[5].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["16"]}
                data-eventid={"16"}
                id="checkbox-16"
                type="checkbox"
                value={16}
              />
              <label htmlFor="checkbox-16">{eventTypes[16].name}</label>
            </p>
            <p className="mb-0">
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["95"]}
                data-eventid={"95"}
                id="checkbox-95"
                type="checkbox"
                value={95}
              />
              <label htmlFor="checkbox-95">{eventTypes[95].name}</label>
            </p>
          </div>
          <div className="col">
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["3"]}
                data-eventid={"3"}
                id="checkbox-3"
                type="checkbox"
                value={3}
              />
              <label htmlFor="checkbox-3">{eventTypes[3].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["6"]}
                data-eventid={"6"}
                id="checkbox-6"
                type="checkbox"
                value={6}
              />
              <label htmlFor="checkbox-6">{eventTypes[6].name}</label>
            </p>
            <p>
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["99"]}
                data-eventid={"99"}
                id="checkbox-99"
                type="checkbox"
                value={99}
              />
              <label htmlFor="checkbox-99">{eventTypes[99].name}</label>
            </p>
            <p className="mb-0">
              <input
                onChange={handleClickFilterChange}
                defaultChecked={eventFilters.map["96"]}
                data-eventid={"96"}
                id="checkbox-96"
                type="checkbox"
                value={96}
              />
              <label htmlFor="checkbox-96">{eventTypes[96].name}</label>
            </p>
          </div>
        </div>
      </Modal>
    </OverlayScreen>
  );
}

ReplayViewer.propTypes = {
  title: PropTypes.string,
};
ReplayViewer.defaultProps = {};

export default withRouter(ReplayViewer);

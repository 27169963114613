import InsightechIcon from "../../share/InsightechIcon";
import { ImEnter } from "react-icons/im";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineGroups } from "react-icons/md";
import { FaCode } from "react-icons/fa";
import { LuGoal } from "react-icons/lu";

// Nav menu icons
export const Dashboard = () => <InsightechIcon name="Dashboard" />;
export const PageAnalysis = () => <InsightechIcon name="Pages" />;
export const LandingPages = () => <ImEnter />;
export const FormAnalysis = () => <InsightechIcon name="Forms" />;
export const Funnels = () => <InsightechIcon name="Funnels" />;
export const Pathing = () => <InsightechIcon name="Pathing" />;
export const Replays = () => <InsightechIcon name="Replays" />;
export const Notes = () => <InsightechIcon name="Notes" />;
export const Segments = () => <InsightechIcon name="Segments" />;
export const Reports = () => <InsightechIcon name="Reports" />;
export const Settings = () => <InsightechIcon name="Settings" />;
export const SettingsProfile = () => <IoSettingsOutline />;
export const SettingsUsers = () => <MdOutlineGroups />;
export const SettingsCode = () => <FaCode />;
export const SettingsConversions = () => <LuGoal />;

// Add other icons as needed

import React, {
  useEffect,
  useContext,
  useState,
  Fragment,
  useRef,
} from "react";
import { Chart } from "react-google-charts";
import { AuthContext } from "../../../../components/auth/FirebaseAuthContext";
import { UserDataContext } from "../../../../components/app/UserData";
import { SegmentsContext } from "./../../../../context/Segments";
import { Button } from "./../../../../components/share/InsightUI";
import api from "./../../../../api";
import {
  calculatePercentage,
  calculateConversionRateDifference,
  formatChannelValue,
  calculateConversionRate,
  captureExceptionToSentry,
} from "./../../../../utils";
import "./style.scss";
import { updateUser } from "../../../../lib/firebase";
import {
  FaChevronUp,
  FaChevronDown,
  FaArrowUp,
  FaArrowDown,
} from "react-icons/fa";
import DashboardTable from "../../../../components/app/DashboardTable";
import NoDataMessage from "../../../../components/share/NoDataMessage";
import WidgetNumber from "../../../../components/app/WidgetNumber";
import Alert from "../../../../components/share/Alert";
import { Widgets } from "../../../../constants/widgets";
import {
  DEFAULT_ERROR,
  SHORT_ERROR,
  ERRORS_MAPPED,
} from "../../../../constants/error";
import { getDimensionHeader } from "../../../../helpers/getDimensionHeader";
import CardHeader from "../../../../components/app/WidgetCard/CradHeader";

const Dashboard = (props) => {
  const { authUser } = useContext(AuthContext);

  const {
    startDate,
    endDate,
    isDateCompareOn,
    compareStartDate,
    compareEndDate,
    appliedFilters,
    isInitialFiltersLoaded,
    isSegmentCompareOn,
    isApiServerLoading,
    activeProfileId,
  } = useContext(UserDataContext);

  const {
    isInitialSegmentsLoaded,
    selectedSegments,
    comparedSelectedSegments,
  } = useContext(SegmentsContext);

  const _isMounted = useRef(true);

  const isCompareTurnOn = isDateCompareOn || isSegmentCompareOn;
  const [isDependenciesLoaded, setIsDependenciesLoaded] = useState(false);
  const [pageviews, setPageviews] = useState(0);
  const [sessions, setSessions] = useState(0);
  const [visitors, setVisitors] = useState(0);
  const [comparedPageviews, setComparedPageviews] = useState(0);
  const [comparedSessions, setComparedSessions] = useState(0);
  const [comparedVisitors, setComparedVisitors] = useState(0);

  const [isLoadingMetricsData, setIsLoadingMetricsData] = useState(false);
  const [isLoadingComparedMetricsData, setIsLoadingComparedMetricsData] =
    useState(false);
  const [isMetricsLoaded, setIsMetricsLoaded] = useState(false);
  const [isComparedMetricsLoaded, setIsComparedMetricsLoaded] = useState(false);
  const [metricsError, setMetricsError] = useState(null);

  const [conversions, setConversions] = useState(0);
  const [revenue, setRevenue] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [comparedConversions, setComparedConversions] = useState(0);
  const [comparedRevenue, setComparedRevenue] = useState(0);
  const [comparedConversionRate, setComparedConversionRate] = useState(0);

  const [isTrendDataLoading, setIsTrendDataLoading] = useState(false);
  const [isTrendDataLoaded, setIsTrendDataLoaded] = useState(false);
  const [trendChartData, setTrendChartData] = useState([]);
  const [trendTableData, setTrendTableData] = useState([]);
  const [trendError, setTrendError] = useState(null);
  const [maxDeviceOrSession, setMaxDeviceOrSession] = useState(0); //set trend chart left Y axis max value
  const [maxConvRate, setMaxConvRate] = useState(0); // set trend chart right Y axis max value
  const [isComparedTrendDataLoading, setIsComparedTrendDataLoading] =
    useState(false);
  const [isComparedTrendDataLoaded, setIsComparedTrendDataLoaded] =
    useState(false);
  const [comparedTrendChartData, setComparedTrendChartData] = useState([]);
  const [comparedTrendTableData, setComparedTrendTableData] = useState([]);
  const [combinedTrendChartData, setCombinedTrendChartData] = useState([]);
  const [maxCompareDeviceOrSession, setMaxCompareDeviceOrSession] = useState(0);
  const [maxCompareConvRate, setMaxCompareConvRate] = useState(0);
  const [trendChartOptions, setTrendChartOptions] = useState({});
  const commonOptions = {
    legend: { position: "top", maxLines: 3 },
    vAxes: {
      0: {
        title: "Devices and Sessions",
        format: 0,
        minValue: 0,
      },
      1: {
        title: "Conversion Rate",
        format: "#'%'",
        minValue: 0,
      },
    },
    series: {},
  };

  const [isPageListLoading, setIsPageListLoading] = useState(false);
  const [isPageListLoaded, setIsPageListLoaded] = useState(false);
  const [topPageData, setTopPageData] = useState([]);
  const [topPageError, setTopPageError] = useState(null);

  const [isDimensionLoading, setIsDimensionLoading] = useState(false);
  const [isDimensionLoaded, setIsDimensionLoaded] = useState(false);
  const [dimensionError, setDimensionError] = useState(null);
  const [dimensionData, setDimensionData] = useState({});
  const [isComparedDimensionLoading, setIsComparedDimensionLoading] =
    useState(false);
  const [isComparedDimensionLoaded, setIsComparedDimensionLoaded] =
    useState(false);
  const [comparedDimensionData, setComparedDimensionData] = useState([]);

  const [isTablesOpen, setIsTablesOpen] = useState({
    trend: false,
    os: false,
    browser: false,
    country: false,
  });

  const [alert, setAlert] = useState({
    show: false,
    type: "",
    message: "",
    count: 0,
  });

  // Removes releated alerts
  const resetErrors = () => {
    setMetricsError(null);
    setTrendError(null);
    setTopPageError(null);
    setDimensionError(null);
  };

  const formattedStartDate = startDate?.format("YYYY-MM-DD");
  const formattedEndDate = endDate?.format("YYYY-MM-DD");
  const formattedCompareStartDate = compareStartDate?.format("YYYY-MM-DD");
  const formattedCompareEndDate = compareEndDate?.format("YYYY-MM-DD");
  const stringSelectedSegments = JSON.stringify(selectedSegments);
  const stringComparedSelectedSegments = JSON.stringify(
    comparedSelectedSegments
  );
  const segmentsArray = Object.keys(selectedSegments).map((key) => key);
  const comparedSegmentsArray = Object.keys(comparedSelectedSegments).map(
    (key) => key
  );

  const profileId = props.match.params.profileId;

  const handleApiError = (error, setError, loaderToggle) => {
    if (!_isMounted.current) {
      return false;
    }
    if (api.isCancel(error)) {
      return;
    }
    const errMsg = error.response?.data?.result;
    if (ERRORS_MAPPED[errMsg]) {
      setAlert({
        show: true,
        type: "danger",
        message: ERRORS_MAPPED[errMsg],
        count: alert.count + 1,
      });
      setError(SHORT_ERROR);
    } else {
      setError(SHORT_ERROR);
      setAlert({
        show: true,
        type: "danger",
        message: DEFAULT_ERROR,
        count: alert.count + 1,
      });
      captureExceptionToSentry(error);
    }

    loaderToggle(false);
  };

  const readMetrics = ({ isCompare, queryId, key }) => {
    const loaderToggle = isCompare
      ? setIsLoadingComparedMetricsData
      : setIsLoadingMetricsData;
    const cancelTokenName = `readMetrics${isCompare ? ".compare" : ""}`;
    api.cancel(cancelTokenName);
    api
      .readMetrics(authUser, {
        profileId,
        queryId,
        key,
        cancelTokenName,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        }
        if (res.data.status === 100) {
          readMetrics({ isCompare, queryId, key });
        } else if (res.data.status === 200) {
          loaderToggle(false);
          if (isCompare) {
            setIsComparedMetricsLoaded(true);
          } else {
            setIsMetricsLoaded(true);
          }
          if (res.data.data.records) {
            const {
              totalDevices,
              totalPageviews,
              totalSessions,
              conversions,
              revenue,
            } = res.data.data.records[0];
            if (isCompare) {
              setComparedPageviews(totalPageviews);
              setComparedSessions(totalSessions);
              setComparedVisitors(totalDevices);
              setComparedConversions(conversions);
              setComparedConversionRate(
                totalSessions !== 0
                  ? parseFloat(((conversions / totalSessions) * 100).toFixed(2))
                  : 0
              );
              setComparedRevenue(revenue);
            } else {
              setPageviews(totalPageviews);
              setSessions(totalSessions);
              setVisitors(totalDevices);
              setConversions(conversions);
              setConversionRate(
                totalSessions !== 0
                  ? parseFloat(((conversions / totalSessions) * 100).toFixed(2))
                  : 0
              );
              setRevenue(revenue);
            }
          }
          setMetricsError(null);
        }
      })
      .catch((error) => handleApiError(error, setMetricsError, loaderToggle));
  };

  const fetchMetrics = ({ isCompare }) => {
    const loaderToggle = isCompare
      ? setIsLoadingComparedMetricsData
      : setIsLoadingMetricsData;
    loaderToggle(true);

    const cancelTokenName = `queryMetrics${isCompare ? ".compare" : ""}`;
    api.cancel(cancelTokenName);
    api.cancel(`readMetrics${isCompare ? ".compare" : ""}`);
    const cancelToken = api.createCancelToken(cancelTokenName);

    api
      .queryMetrics(
        authUser,
        {
          profileId,
          startDate: isCompare?.date
            ? formattedCompareStartDate
            : startDate.format("YYYY-MM-DD"),
          endDate: isCompare?.date
            ? formattedCompareEndDate
            : endDate.format("YYYY-MM-DD"),
          filter: JSON.stringify(appliedFilters),
          segments: isCompare?.segment
            ? JSON.stringify(comparedSegmentsArray)
            : JSON.stringify(segmentsArray),
        },
        cancelToken
      )
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        } else if (res.data.status === 200 && res.data.data) {
          const { queryId, key } = res.data.data;
          readMetrics({ isCompare, queryId, key });
        } else {
          loaderToggle(false);
        }
        setMetricsError(null);
      })
      .catch((error) => handleApiError(error, setMetricsError, loaderToggle));
  };

  const readTrend = ({ isCompare, queryId, key }) => {
    const loaderToggle = isCompare
      ? setIsComparedTrendDataLoading
      : setIsTrendDataLoading;
    const cancelTokenName = `readTrend${isCompare ? ".compare" : ""}`;
    api.cancel(cancelTokenName);
    api
      .readTrend(authUser, {
        profileId,
        queryId,
        key,
        cancelTokenName,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        }
        if (res.data.status === 100) {
          readTrend({ isCompare, queryId, key });
        }
        if (res.data.status === 200) {
          const chartData = [
            [
              { type: "string", label: "Date" },
              { type: "number", label: "Devices" },
              { type: "number", label: "Sessions" },
              { type: "number", label: "Conversion Rate (%)" },
            ],
          ];
          const tableData = [
            [
              "Date/Time",
              "Devices",
              "Sessions",
              "Pageviews",
              "Conversions",
              "Conversion Rate",
              "Revenue",
            ],
          ];
          var maxDevicesOrSessions = 0;
          var maxConversionRate = 0;

          if (res.data.data.records) {
            res.data.data.records.forEach((row, index) => {
              let dateHourLabel =
                startDate.format("YYYY-MM-DD") === endDate.format("YYYY-MM-DD")
                  ? `${Number(row.datetime.split("T")[1].slice(0, -6))}h`
                  : row.date;
              let conRate =
                row.sessions !== 0
                  ? ((row.conversions / row.sessions) * 100).toFixed(2)
                  : Number(0).toFixed(2);
              chartData.push([
                dateHourLabel,
                row.devices,
                row.sessions,
                parseFloat(conRate),
              ]);
              tableData.push([
                dateHourLabel,
                row.devices,
                row.sessions,
                row.pageviews,
                row.conversions,
                parseFloat(conRate),
                row.revenue,
              ]);
            });
            maxDevicesOrSessions =
              Math.max(
                ...chartData.slice(1).map((dt) => {
                  return Number(Math.max(dt[1], dt[2]));
                })
              ) || 1;
            maxConversionRate = Math.max(
              ...chartData.slice(1).map((dt) => {
                return Number(dt[3]);
              })
            );
          }
          loaderToggle(false);
          if (isCompare) {
            setIsComparedTrendDataLoaded(true);
            setComparedTrendChartData(chartData);
            setComparedTrendTableData(tableData);
            setMaxCompareDeviceOrSession(maxDevicesOrSessions);
            setMaxCompareConvRate(
              maxConversionRate === 0 ? 1 : maxConversionRate
            );
          } else {
            setIsTrendDataLoaded(true);
            setTrendChartData(chartData);
            setTrendTableData(tableData);
            setMaxDeviceOrSession(maxDevicesOrSessions);
            setMaxConvRate(maxConversionRate === 0 ? 1 : maxConversionRate);
          }

          setTrendError(null);
        }
      })
      .catch((error) => handleApiError(error, setTrendError, loaderToggle));
  };

  const fetchTrend = ({ isCompare }) => {
    const loaderToggle = isCompare
      ? setIsComparedTrendDataLoading
      : setIsTrendDataLoading;
    loaderToggle(true);

    const cancelTokenName = `queryTrend${isCompare ? ".compare" : ""}`;
    api.cancel(cancelTokenName);
    api.cancel(`readTrend${isCompare ? ".compare" : ""}`);
    const cancelToken = api.createCancelToken(cancelTokenName);

    api
      .queryTrend(
        authUser,
        {
          profileId,
          startDate: isCompare?.date
            ? compareStartDate.format("YYYY-MM-DD")
            : startDate.format("YYYY-MM-DD"),
          endDate: isCompare?.date
            ? compareEndDate.format("YYYY-MM-DD")
            : endDate.format("YYYY-MM-DD"),
          filter: JSON.stringify(appliedFilters),
          segments: isCompare?.segment
            ? JSON.stringify(comparedSegmentsArray)
            : JSON.stringify(segmentsArray),
          interval: endDate.diff(startDate, "days") === 0 && "1hour",
          withConversion: "1",
        },
        cancelToken
      )
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        }
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readTrend({ isCompare, queryId, key });
          setTrendError(null);
        }
      })
      .catch((error) => handleApiError(error, setTrendError, loaderToggle));
  };

  const readPageList = ({ queryId, key }) => {
    // No comparison
    api.cancel("readPageList");
    api
      .readPageList(authUser, {
        profileId,
        queryId,
        key,
        page: 0,
        pageSize: 10,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        }
        if (res.data.status === 100) {
          readPageList({ queryId, key });
        }
        if (res.data.status === 200) {
          const data = [];
          if (res.data.data.records) {
            data.push(["URL", "Pageviews"]);
            res.data.data.records.forEach((row) => {
              data.push([row.url, row.pageviews]);
            });
          }
          setTopPageData(data);
          setIsPageListLoading(false);
          setIsPageListLoaded(true);
          setTopPageError(null);
        }
      })
      .catch((error) =>
        handleApiError(error, setTopPageError, setIsPageListLoading)
      );
  };

  const fetchPageList = () => {
    // No comparison
    setIsPageListLoading(true);

    api.cancel("queryPageList");
    api
      .queryPageList(authUser, {
        profileId,
        startDate: startDate.format("YYYY-MM-DD"),
        endDate: endDate.format("YYYY-MM-DD"),
        filter: JSON.stringify(appliedFilters),
        pageURL: JSON.stringify({
          operator: "is",
          values: [""],
        }),
        segments: JSON.stringify(segmentsArray),
        sort: "pageviews-desc",
      })
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        }
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readPageList({ queryId, key });
          setTopPageError(null);
        } else {
          setIsPageListLoading(false);
          setTopPageError(DEFAULT_ERROR);
        }
      })
      .catch((error) =>
        handleApiError(error, setTopPageError, setIsPageListLoading)
      );
  };

  useEffect(() => {
    updateUser({ lastAccessTimestamp: new Date().getTime() });
  }, [profileId]);

  const readDimensionData = ({ isCompare, queryId, key }) => {
    const loaderToggle = isCompare
      ? setIsComparedDimensionLoading
      : setIsDimensionLoading;
    // Loader was set to true before, in the query
    const cancelTokenName = `readDimension${isCompare ? ".compare" : ""}`;
    api.cancel(cancelTokenName);

    api
      .readDimension(authUser, {
        profileId,
        queryId,
        key,
        pageSize: 0,
        cancelTokenName,
      })
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        }
        if (res.data.status === 100) {
          readDimensionData({ isCompare, queryId, key });
        }
        if (res.data.status === 200) {
          let transformedDimensionData;
          if (res.data.data.records) {
            const recordsByDimension = res.data.data.records.reduce(
              (acc, row) => {
                acc[row.dimension] = row.values;
                return acc;
              },
              {}
            );
            transformedDimensionData =
              transformDimensionData(recordsByDimension);
          }
          loaderToggle(false);
          if (isCompare) {
            setIsComparedDimensionLoaded(true);
            setComparedDimensionData(transformedDimensionData);
          } else {
            setIsDimensionLoaded(true);
            setDimensionData(transformedDimensionData);
            setDimensionError(null);
          }
        }
      })
      .catch((error) => handleApiError(error, setDimensionError, loaderToggle));
  };

  const transformDimensionData = (input) => {
    return Object.keys(input).reduce((output, dimension) => {
      const header = [
        getDimensionHeader(dimension),
        "Sessions",
        "Conversions",
        "Conversion Rate",
        "Revenue",
      ];

      const dataRows = input[dimension].map((item) => {
        let value;
        if (dimension === "channel") {
          value = formatChannelValue(item.value);
        } else if (dimension === "campaign") {
          value = item.value === "" ? "(not set)" : item.value;
        } else {
          value = item.value;
        }

        const conversionRate = calculateConversionRate(
          item.conversions,
          item.sessions
        );

        return [
          value,
          item.sessions,
          item.conversions,
          conversionRate,
          item.revenue,
        ];
      });

      output[dimension] = [header, ...dataRows];
      return output;
    }, {});
  };

  const fetchDimensionData = ({ isCompare }) => {
    const loaderToggle = isCompare
      ? setIsComparedDimensionLoading
      : setIsDimensionLoading;
    loaderToggle(true);

    const cancelTokenName = `queryDimension${isCompare ? ".compare" : ""}`;
    api.cancel(cancelTokenName);
    api.cancel(`readDimension${isCompare ? ".compare" : ""}`);
    const cancelToken = api.createCancelToken(cancelTokenName);

    api
      .queryDimension(
        authUser,
        {
          profileId,
          startDate: isCompare?.date
            ? formattedCompareStartDate
            : startDate.format("YYYY-MM-DD"),
          endDate: isCompare?.date
            ? formattedCompareEndDate
            : endDate.format("YYYY-MM-DD"),
          filter: JSON.stringify(appliedFilters),
          segments: isCompare?.segment
            ? JSON.stringify(comparedSegmentsArray)
            : JSON.stringify(segmentsArray),
        },
        cancelToken
      )
      .then((res) => {
        if (!_isMounted.current) {
          return false;
        }
        if (res.data.data) {
          const { queryId, key } = res.data.data;
          readDimensionData({ isCompare, queryId, key });
          setDimensionError(null);
        } else {
          loaderToggle(false);
          setDimensionError(DEFAULT_ERROR);
        }
      })
      .catch((error) => handleApiError(error, setDimensionError, loaderToggle));
  };

  const handleClickViewData = (tableType) => {
    setIsTablesOpen((prevState) => ({
      ...prevState,
      [tableType]: !prevState[tableType],
    }));
  };

  const ProcessCombinedTrendsChartData = () => {
    const combinedChartData = [
      [
        "Date",
        "Devices",
        "Compared Devices",
        "Sessions",
        "Compared Sessions",
        "Conversion Rate (%)",
        "Compared Conversion Rate (%)",
      ],
      ...trendChartData.slice(1).map((row, index) => {
        const comparedRow = comparedTrendChartData[index + 1] || [];
        const trendDateValue = row[0]?.includes("-") ? row[0].slice(5) : row[0];
        const comparedDateValue = comparedRow[0]?.includes("-")
          ? comparedRow[0].slice(5)
          : comparedRow[0];
        const trendDate = trendDateValue || "--";
        const comparedDate = comparedDateValue || "--";
        const combinedLabel = isDateCompareOn
          ? `${trendDate} vs ${comparedDate}`
          : row[0];
        return [
          combinedLabel,
          row[1],
          comparedRow[1] || 0,
          row[2],
          comparedRow[2] || 0,
          row[3],
          comparedRow[3] || 0,
        ];
      }),
    ];
    setCombinedTrendChartData(combinedChartData);
  };

  const formatWithArrowAndColor = (value) => {
    if (value === "--%" || value === "0%") {
      return value;
    } else {
      const floatValue = parseFloat(value);
      const isPositive = floatValue > 0;
      const colorClass = isPositive ? "text-success" : "text-danger";
      const arrowIcon = isPositive ? (
        <FaArrowUp className="mb-1" />
      ) : (
        <FaArrowDown className="mb-1" />
      );
      return (
        <span className={`${colorClass}`}>
          {arrowIcon}
          {value}
        </span>
      );
    }
  };

  // get combined trend data and comparison data for trend combo chart
  useEffect(() => {
    if (
      isCompareTurnOn &&
      trendChartData?.length &&
      comparedTrendChartData?.length
    ) {
      ProcessCombinedTrendsChartData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trendChartData, comparedTrendChartData, isCompareTurnOn]);

  useEffect(() => {
    if (
      isInitialFiltersLoaded &&
      isInitialSegmentsLoaded &&
      activeProfileId &&
      appliedFilters &&
      selectedSegments &&
      startDate &&
      !isApiServerLoading &&
      endDate
    ) {
      setIsDependenciesLoaded(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInitialFiltersLoaded,
    isApiServerLoading,
    isInitialSegmentsLoaded,
    appliedFilters,
    selectedSegments,
    startDate,
    endDate,
  ]);

  // Fetch widget API
  useEffect(() => {
    if (
      isDependenciesLoaded &&
      appliedFilters &&
      selectedSegments &&
      startDate &&
      endDate
    ) {
      resetErrors();
      fetchMetrics({ isCompare: false });
      fetchTrend({ isCompare: false });
      fetchPageList();
      fetchDimensionData({
        isCompare: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDependenciesLoaded,
    appliedFilters,
    stringSelectedSegments,
    formattedStartDate,
    formattedEndDate,
  ]);

  // Fetch widget compared API
  useEffect(() => {
    if (
      isDependenciesLoaded &&
      appliedFilters &&
      selectedSegments &&
      ((compareStartDate && compareEndDate) || isSegmentCompareOn)
    ) {
      let compareMode = {
        date: isDateCompareOn,
        segment: isSegmentCompareOn,
      };

      fetchMetrics({ isCompare: compareMode });
      fetchTrend({ isCompare: compareMode });
      fetchDimensionData({
        isCompare: compareMode,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isDependenciesLoaded,
    formattedStartDate,
    formattedEndDate,
    appliedFilters,
    stringSelectedSegments,
    formattedCompareStartDate,
    formattedCompareEndDate,
    stringComparedSelectedSegments,
    isSegmentCompareOn,
  ]);

  // Unmount
  useEffect(() => {
    // Cleanup
    return () => {
      _isMounted.current = false;
      api.cancel("queryMetrics");
      api.cancel("queryMetrics.compare");
      api.cancel("queryTrend");
      api.cancel("queryTrend.compare");
      api.cancel("queryPageList");
      api.cancel(`queryDimension`);
      api.cancel(`queryDimension.compare`);
      // Cancel reads
      api.cancel("readMetrics");
      api.cancel("readTrend");
      api.cancel("readPageList");
      api.cancel("readDimension");
      api.cancel("readMetrics.compare");
      api.cancel("readTrend.compare");
      api.cancel("readDimension.compare");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update combo chart options
  useEffect(() => {
    if (isCompareTurnOn && combinedTrendChartData?.length) {
      commonOptions.series = {
        ...commonOptions.series,
        0: {
          targetAxisIndex: 0,
          color: "#3466CC",
          type: "bars",
        },
        1: {
          targetAxisIndex: 0,
          color: "#EF9E00",
          type: "bars",
        },
        2: {
          targetAxisIndex: 0,
          color: "#7AA1F1",
          type: "bars",
        },
        3: {
          targetAxisIndex: 0,
          color: "#FEC659",
          type: "bars",
        },
        4: {
          targetAxisIndex: 1,
          color: "#1CC88A",
          type: "line",
        },
        5: {
          targetAxisIndex: 1,
          color: "#1CC88A",
          type: "line",
          lineDashStyle: [4, 4],
        },
      };

      commonOptions.vAxes[0].maxValue = Math.max(
        maxDeviceOrSession,
        maxCompareDeviceOrSession
      );
      commonOptions.vAxes[1].maxValue = Math.ceil(
        Math.max(maxConvRate, maxCompareConvRate) || 1
      );

      commonOptions.hAxis = {
        textStyle: {
          fontSize: 12,
        },
      };

      commonOptions.tooltip = {
        isHtml: true,
        trigger: "both",
        ignoreBounds: true,
        textStyle: {
          fontSize: 14,
        },
      };
    } else {
      commonOptions.series = {
        ...commonOptions.series,
        0: {
          color: "#3466CC",
          targetAxisIndex: 0,
          type: "bars",
        },
        1: {
          color: "#7AA1F1",
          targetAxisIndex: 0,
          type: "bars",
        },
        2: {
          color: "#1CC88A",
          targetAxisIndex: 1,
          type: "line",
        },
      };

      commonOptions.vAxes[0].maxValue = maxDeviceOrSession;
      commonOptions.vAxes[1].maxValue = Math.ceil(maxConvRate);
    }

    setTrendChartOptions(commonOptions);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isCompareTurnOn,
    trendChartData,
    combinedTrendChartData,
    maxDeviceOrSession,
    maxConvRate,
    maxCompareDeviceOrSession,
    maxCompareConvRate,
  ]);

  return (
    <>
      <div className="row">
        {(metricsError || trendError || topPageError || dimensionError) && (
          <Alert show={alert.show} type={alert.type} message={alert.message} />
        )}
      </div>
      <div className="row">
        <WidgetNumber
          name="Devices"
          error={metricsError}
          isCompareMode={isCompareTurnOn}
          isLoading={isLoadingMetricsData}
          isComparedLoading={isLoadingComparedMetricsData}
          number={visitors}
          comparedNumber={isComparedMetricsLoaded ? comparedVisitors : null}
          comparisonDiffNumber={
            isComparedMetricsLoaded
              ? formatWithArrowAndColor(
                  calculatePercentage(visitors, comparedVisitors)
                )
              : null
          }
          status={isMetricsLoaded}
          comparedStatus={isComparedMetricsLoaded}
        ></WidgetNumber>
        <WidgetNumber
          name="Sessions"
          error={metricsError}
          isCompareMode={isCompareTurnOn}
          isLoading={isLoadingMetricsData}
          isComparedLoading={isLoadingComparedMetricsData}
          number={sessions}
          comparedNumber={isComparedMetricsLoaded ? comparedSessions : null}
          comparisonDiffNumber={
            isComparedMetricsLoaded
              ? formatWithArrowAndColor(
                  calculatePercentage(sessions, comparedSessions)
                )
              : null
          }
          status={isMetricsLoaded}
          comparedStatus={isComparedMetricsLoaded}
        ></WidgetNumber>
        <WidgetNumber
          name="Pageviews"
          error={metricsError}
          isCompareMode={isCompareTurnOn}
          isLoading={isLoadingMetricsData}
          isComparedLoading={isLoadingComparedMetricsData}
          number={pageviews}
          comparedNumber={isComparedMetricsLoaded ? comparedPageviews : null}
          comparisonDiffNumber={
            isComparedMetricsLoaded
              ? formatWithArrowAndColor(
                  calculatePercentage(pageviews, comparedPageviews)
                )
              : null
          }
          status={isMetricsLoaded}
          comparedStatus={isComparedMetricsLoaded}
        ></WidgetNumber>
        <WidgetNumber
          name="Conversions"
          error={metricsError}
          isCompareMode={isCompareTurnOn}
          isLoading={isLoadingMetricsData}
          isComparedLoading={isLoadingComparedMetricsData}
          number={conversions}
          comparedNumber={isComparedMetricsLoaded ? comparedConversions : null}
          comparisonDiffNumber={
            isComparedMetricsLoaded
              ? formatWithArrowAndColor(
                  calculatePercentage(conversions, comparedConversions)
                )
              : null
          }
          status={true}
          comparedStatus={isComparedMetricsLoaded}
        ></WidgetNumber>
        <WidgetNumber
          name="Conversion Rate"
          error={metricsError}
          isCompareMode={isCompareTurnOn}
          isLoading={isLoadingMetricsData}
          isComparedLoading={isLoadingComparedMetricsData}
          number={conversionRate}
          comparedNumber={
            isComparedMetricsLoaded ? comparedConversionRate : null
          }
          comparisonDiffNumber={
            isComparedMetricsLoaded
              ? formatWithArrowAndColor(
                  calculateConversionRateDifference(
                    conversionRate,
                    comparedConversionRate
                  )
                )
              : null
          }
          suffix="%"
          status={true}
          comparedStatus={isComparedMetricsLoaded}
        ></WidgetNumber>
        <WidgetNumber
          name="Revenue"
          error={metricsError}
          isCompareMode={isCompareTurnOn}
          isLoading={isLoadingMetricsData}
          isComparedLoading={isLoadingComparedMetricsData}
          number={revenue}
          comparedNumber={isComparedMetricsLoaded ? comparedRevenue : null}
          comparisonDiffNumber={
            isComparedMetricsLoaded
              ? formatWithArrowAndColor(
                  calculatePercentage(revenue, comparedRevenue)
                )
              : null
          }
          prefix="$"
          status={true}
          comparedStatus={isComparedMetricsLoaded}
        ></WidgetNumber>
      </div>
      <div className="row">
        <div className="col-md-12 mb-4">
          <div className="card data-tile">
            <CardHeader
              title="Trends"
              widget={Widgets.Trend}
              setIsTablesOpen={setIsTablesOpen}
            />
            <div className="card-body">
              {!isTrendDataLoading && trendError && trendError}
              {((!isCompareTurnOn &&
                isTrendDataLoaded &&
                !isTrendDataLoading) ||
                ((isCompareTurnOn || isComparedTrendDataLoaded) &&
                  !isComparedTrendDataLoading &&
                  isTrendDataLoaded &&
                  !isTrendDataLoading)) && (
                <Fragment>
                  <Chart
                    width={"100%"}
                    height={"300px"}
                    options={trendChartOptions}
                    chartType="ComboChart"
                    loader={
                      <div className="text-center">
                        <i className="fa fa-spinner fa-spin" /> Loading Trends
                        Data...
                      </div>
                    }
                    data={
                      isCompareTurnOn && !!combinedTrendChartData.length
                        ? combinedTrendChartData
                        : trendChartData
                    }
                  />
                  <div className="expand-table">
                    <Button
                      variant="link"
                      className="m-auto"
                      id="view-more-trend"
                      onClick={() => handleClickViewData(Widgets.Trend)}
                    >
                      View{" "}
                      {isCompareTurnOn && !!combinedTrendChartData.length
                        ? "Comparison"
                        : ""}{" "}
                      Data{" "}
                      {isTablesOpen.trend ? (
                        <FaChevronUp
                          style={{ marginTop: "-2px", marginLeft: "5px" }}
                        />
                      ) : (
                        <FaChevronDown
                          style={{ marginTop: "-2px", marginLeft: "5px" }}
                        />
                      )}
                    </Button>
                    {isTablesOpen.trend &&
                      (!isComparedTrendDataLoading || !isTrendDataLoading) && (
                        <>
                          {(!isCompareTurnOn ||
                            (isCompareTurnOn &&
                              !isComparedTrendDataLoaded)) && (
                            <DashboardTable
                              tableData={trendTableData}
                              widget={Widgets.Trend}
                            />
                          )}
                          {isComparedTrendDataLoaded &&
                            !isComparedTrendDataLoading &&
                            isCompareTurnOn && (
                              <DashboardTable
                                isCompare={isCompareTurnOn}
                                isTrend={true}
                                tableData={trendTableData}
                                comparedTableData={comparedTrendTableData}
                                getDiffArrow={formatWithArrowAndColor}
                                widget={Widgets.Trend}
                              />
                            )}
                        </>
                      )}
                  </div>
                </Fragment>
              )}
              {(isTrendDataLoading || isComparedTrendDataLoading) && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading Trends Data...
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card data-tile">
            <CardHeader title="Channels" widget={Widgets.Channel} />
            <div className="card-body">
              {!isDimensionLoading && dimensionError && dimensionError}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !!dimensionData?.channel?.length && (
                  <Fragment>
                    {(!isCompareTurnOn ||
                      (isCompareTurnOn &&
                        !isComparedDimensionLoaded &&
                        !isComparedDimensionLoading)) && (
                      <DashboardTable
                        tableData={dimensionData.channel}
                        widget={Widgets.Channel}
                      />
                    )}
                    {isCompareTurnOn &&
                      isComparedDimensionLoaded &&
                      !isComparedDimensionLoading &&
                      typeof comparedDimensionData !== "undefined" && (
                        <DashboardTable
                          isCompare={isCompareTurnOn}
                          isTrend={false}
                          tableData={dimensionData.channel}
                          comparedTableData={comparedDimensionData.channel}
                          widget={Widgets.Channel}
                          getDiffArrow={formatWithArrowAndColor}
                        />
                      )}
                  </Fragment>
                )}
              {(isDimensionLoading || isComparedDimensionLoading) && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading Channels...
                </div>
              )}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !dimensionData?.channel?.length && (
                  <NoDataMessage message={"No Data"} />
                )}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card data-tile">
            <CardHeader title="Campaigns" widget={Widgets.Campaign} />
            <div className="card-body">
              {!isDimensionLoading && dimensionError && dimensionError}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !!dimensionData?.campaign?.length && (
                  <Fragment>
                    {(!isCompareTurnOn ||
                      (isCompareTurnOn &&
                        !isComparedDimensionLoaded &&
                        !isComparedDimensionLoading)) && (
                      <DashboardTable
                        tableData={dimensionData.campaign}
                        widget={Widgets.Campaign}
                      />
                    )}
                    {isCompareTurnOn &&
                      isComparedDimensionLoaded &&
                      !isComparedDimensionLoading &&
                      typeof comparedDimensionData !== "undefined" && (
                        <DashboardTable
                          isCompare={isCompareTurnOn}
                          isTrend={false}
                          tableData={dimensionData.campaign}
                          comparedTableData={comparedDimensionData.campaign}
                          widget={Widgets.Campaign}
                          getDiffArrow={formatWithArrowAndColor}
                        />
                      )}
                  </Fragment>
                )}
              {(isDimensionLoading || isComparedDimensionLoading) && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading Campaigns...
                </div>
              )}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !dimensionData?.campaign?.length && (
                  <NoDataMessage message={"No Data"} />
                )}
            </div>
          </div>
        </div>
        <div className="col-md-12 mb-4">
          <div className="card data-tile">
            <CardHeader
              title="Top Landing Pages"
              widget={Widgets.TopLandingPage}
            />
            <div className="card-body">
              {!isDimensionLoading && dimensionError && dimensionError}
              {isDimensionLoading && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading Top Landing
                  Pages...
                </div>
              )}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !!dimensionData?.landingpage?.length && (
                  <DashboardTable
                    tableData={dimensionData.landingpage}
                    widget={Widgets.TopLandingPage}
                  />
                )}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !dimensionData?.landingpage?.length && (
                  <NoDataMessage message={"No Data"} />
                )}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card data-tile">
            <CardHeader
              title="OS"
              widget={Widgets.OS}
              setIsTablesOpen={setIsTablesOpen}
            />
            <div className="card-body">
              {!isDimensionLoading && dimensionError && dimensionError}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !!dimensionData?.os?.length && (
                  <Fragment>
                    <Chart
                      width={"100%"}
                      height={"300px"}
                      chartType="PieChart"
                      loader={
                        <div className="text-center">
                          <i className="fa fa-spinner fa-spin" /> Loading OS...
                        </div>
                      }
                      data={dimensionData.os}
                      options={{ sliceVisibilityThreshold: 0 }}
                    />
                    <div className="expand-table">
                      <Button
                        variant="link"
                        className="m-auto"
                        id="view-more-os"
                        onClick={() => handleClickViewData(Widgets.OS)}
                      >
                        View{" "}
                        {isCompareTurnOn && !!comparedDimensionData?.os?.length
                          ? "Comparison"
                          : ""}{" "}
                        Data{" "}
                        {isTablesOpen.os ? (
                          <FaChevronUp
                            style={{ marginTop: "-2px", marginLeft: "5px" }}
                          />
                        ) : (
                          <FaChevronDown
                            style={{ marginTop: "-2px", marginLeft: "5px" }}
                          />
                        )}
                      </Button>
                      {isTablesOpen.os && (
                        <>
                          {(!isCompareTurnOn ||
                            (isCompareTurnOn &&
                              !isComparedDimensionLoaded &&
                              !isComparedDimensionLoading)) && (
                            <DashboardTable
                              tableData={dimensionData.os}
                              widget={Widgets.OS}
                            />
                          )}

                          {isCompareTurnOn &&
                            isComparedDimensionLoaded &&
                            !isComparedDimensionLoading && (
                              <DashboardTable
                                isCompare={isCompareTurnOn}
                                isTrend={false}
                                tableData={dimensionData.os}
                                comparedTableData={comparedDimensionData.os}
                                widget={Widgets.OS}
                                getDiffArrow={formatWithArrowAndColor}
                              />
                            )}
                        </>
                      )}
                    </div>
                  </Fragment>
                )}
              {(isDimensionLoading || isComparedDimensionLoading) && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading OS...
                </div>
              )}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !dimensionData?.os?.length && (
                  <NoDataMessage message={"No Data"} />
                )}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card data-tile">
            <CardHeader
              title="Browsers"
              widget={Widgets.Browser}
              setIsTablesOpen={setIsTablesOpen}
            />
            <div className="card-body">
              {!isDimensionLoading && dimensionError && dimensionError}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !!dimensionData?.browser?.length && (
                  <Fragment>
                    <Chart
                      width={"100%"}
                      height={"300px"}
                      chartType="PieChart"
                      loader={
                        <div className="text-center">
                          <i className="fa fa-spinner fa-spin" /> Loading
                          Browsers...
                        </div>
                      }
                      data={dimensionData.browser}
                    />
                    <div className="expand-table">
                      <Button
                        variant="link"
                        className="m-auto"
                        id="view-more-browser"
                        onClick={() => handleClickViewData(Widgets.Browser)}
                      >
                        View{" "}
                        {isCompareTurnOn && !!dimensionData?.browser?.length
                          ? "Comparison"
                          : ""}{" "}
                        Data{" "}
                        {isTablesOpen.browser ? (
                          <FaChevronUp
                            style={{ marginTop: "-2px", marginLeft: "5px" }}
                          />
                        ) : (
                          <FaChevronDown
                            style={{ marginTop: "-2px", marginLeft: "5px" }}
                          />
                        )}
                      </Button>
                      {isTablesOpen.browser && (
                        <>
                          {(!isCompareTurnOn ||
                            (isCompareTurnOn &&
                              !isComparedDimensionLoaded &&
                              !isComparedDimensionLoading)) && (
                            <DashboardTable
                              tableData={dimensionData.browser}
                              widget={Widgets.Browser}
                            />
                          )}
                          {isCompareTurnOn &&
                            isComparedDimensionLoaded &&
                            !isComparedDimensionLoading && (
                              <DashboardTable
                                isCompare={isCompareTurnOn}
                                isTrend={false}
                                tableData={dimensionData.browser}
                                comparedTableData={
                                  comparedDimensionData.browser
                                }
                                widget={Widgets.Browser}
                                getDiffArrow={formatWithArrowAndColor}
                              />
                            )}
                        </>
                      )}
                    </div>
                  </Fragment>
                )}
              {(isDimensionLoading || isComparedDimensionLoading) && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading Browsers...
                </div>
              )}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !dimensionData?.browser?.length && (
                  <NoDataMessage message={"No Data"} />
                )}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card data-tile">
            <CardHeader
              title="Countries"
              widget={Widgets.Country}
              setIsTablesOpen={setIsTablesOpen}
            />
            <div className="card-body">
              {!isDimensionLoading && dimensionError && dimensionError}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !!dimensionData?.country?.length && (
                  <Fragment>
                    <Chart
                      width={"100%"}
                      height={"300px"}
                      chartType="GeoChart"
                      loader={
                        <div className="text-center">
                          <i className="fa fa-spinner fa-spin" /> Loading
                          Countries...
                        </div>
                      }
                      data={dimensionData.country.map((data) => {
                        return data.slice(0, 2);
                      })}
                    />
                    <div className="expand-table mt-3">
                      <Button
                        variant="link"
                        className="m-auto"
                        id="view-more-country"
                        onClick={() => handleClickViewData(Widgets.Country)}
                      >
                        View{" "}
                        {isCompareTurnOn && !!dimensionData?.country?.length
                          ? "Comparison"
                          : ""}{" "}
                        Data{" "}
                        {isTablesOpen.country ? (
                          <FaChevronUp
                            style={{ marginTop: "-2px", marginLeft: "5px" }}
                          />
                        ) : (
                          <FaChevronDown
                            style={{ marginTop: "-2px", marginLeft: "5px" }}
                          />
                        )}
                      </Button>
                      {isTablesOpen.country && (
                        <>
                          {(!isCompareTurnOn ||
                            (isCompareTurnOn &&
                              !isDimensionLoaded &&
                              !isComparedDimensionLoading)) && (
                            <DashboardTable
                              tableData={dimensionData.country}
                              widget={Widgets.Country}
                            />
                          )}
                          {isCompareTurnOn &&
                            isComparedDimensionLoaded &&
                            !isComparedDimensionLoading && (
                              <DashboardTable
                                isCompare={isCompareTurnOn}
                                isTrend={false}
                                tableData={dimensionData.country}
                                comparedTableData={
                                  comparedDimensionData.country
                                }
                                widget={Widgets.Country}
                                getDiffArrow={formatWithArrowAndColor}
                              />
                            )}
                        </>
                      )}
                    </div>
                  </Fragment>
                )}
              {(isDimensionLoading || isComparedDimensionLoading) && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading Countries...
                </div>
              )}
              {isDimensionLoaded &&
                !isDimensionLoading &&
                !dimensionData?.country?.length && (
                  <NoDataMessage message={"No Data"} />
                )}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4">
          <div className="card data-tile">
            <CardHeader title="Top Pages" widget={Widgets.TopPage} />
            <div className="card-body">
              {!isPageListLoading && topPageError && topPageError}
              {isPageListLoading && (
                <div className="text-center">
                  <i className="fa fa-spinner fa-spin" /> Loading Top Pages...
                </div>
              )}
              {isPageListLoaded &&
                !isPageListLoading &&
                !!topPageData.length && (
                  <DashboardTable
                    tableData={topPageData}
                    widget={Widgets.TopPage}
                  />
                )}
              {isPageListLoaded &&
                !isPageListLoading &&
                !topPageData.length && <NoDataMessage message={"No Data"} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import { initializeApp } from "firebase/app";
import {
  doc,
  getFirestore,
  connectFirestoreEmulator,
  setDoc,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  orderBy,
  where,
  documentId,
  limit,
  startAfter,
  increment,
  deleteField,
} from "firebase/firestore";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  connectAuthEmulator,
  getAuth as getAuthOriginal,
  setPersistence,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  checkActionCode,
  applyActionCode,
  confirmPasswordReset,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
  onAuthStateChanged,
  sendEmailVerification,
  signOut,
  updateProfile,
} from "firebase/auth";
import { captureExceptionToSentry, isFunction } from "./../../utils";
import config from "../../config/config.json";
const FirebaseConfig = require("./../../config/firebase-config.json");

const firebaseApp = initializeApp(FirebaseConfig);
const Firestore = getFirestore(firebaseApp);
if (config.firestoreEmulator) {
  // localhost:9098
  const hostPort = config.firestoreEmulator.split(":");
  const host = hostPort[0];
  const port = hostPort[1];
  connectFirestoreEmulator(Firestore, host, port);
}

const gmailProvider = new GoogleAuthProvider();
const microsoftProvider = new OAuthProvider("microsoft.com");
let auth = null;

const updateUser = (data, uid) => { // SHould use a  Cloud function
  if (typeof uid === "string" && uid.length > 0) {
  } else {
    uid = getAuth().currentUser.uid;
  }
  if (!uid) {
    return;
  }

  var userDocRef = doc(Firestore, "users", uid);

  return setDoc(userDocRef, data, { merge: true });
};

const createRef = (ref) => {
  return doc(Firestore, ref);
};

const addLog = async (action, uid, successCallback, failureCallback) => {
  var dt = new Date();
  var data = {
    action: action,
    timestamp: dt.getTime(),
    time: dt,
    "user-agent": navigator.userAgent,
  };

  if (typeof uid === "string" && uid.length > 0) {
  } else {
    uid = getAuth().currentUser.uid;
  }

  var userDocRef = doc(Firestore, "users", uid);

  try {
    await setDoc(userDocRef, { activityCount: increment(1) }, { merge: true });
  } catch (error) {
    if (typeof failureCallback !== "undefined") {
      failureCallback(error);
    }
  }

  try {
    await setDoc(doc(userDocRef, "activities", "" + dt.getTime()), data);
  } catch (error) {
    setDoc(userDocRef, { activityCount: increment(-1) }, { merge: true });
    if (typeof failureCallback !== "undefined") {
      failureCallback(error);
    }
  }

  if (typeof successCallback !== "undefined") successCallback();
};

const getAuth = () => {
  if (auth) {
    return auth;
  }

  auth = getAuthOriginal(firebaseApp);
  if (config.authEmulator) {
    // http://127.0.0.1:9099
    connectAuthEmulator(auth, config.authEmulator);
  }

  return auth;
}

const signinUser = (
  email,
  password,
  persistent, // Remember Me
  successCallback,
  failureCallback
) => {
  // See Firebase persistence doc : https://firebase.google.com/docs/auth/web/auth-state-persistence
  const persistence = persistent
    ? browserLocalPersistence // Local storage, cross tab, no expiration
    : browserSessionPersistence; // Tab based

  setPersistence(getAuth(), persistence).then(() => {
    signInWithEmailAndPassword(getAuth(), email, password)
      .then((response) => {
        if (!response.user.photoURL) {
          if (isFunction(successCallback)) {
            successCallback(response);
          }
          return;
        }
        updateUser(
          {
            photoURL: response.user.photoURL,
          },
          response.user.uid
        ).then(() => {
          if (isFunction(successCallback)) {
            successCallback(response);
          }
        });
      })
      .catch((error) => {
        if (isFunction(failureCallback)) {
          failureCallback(error);
        }
      });
  })
  .catch((error) => {
    console.error("Error setting persistence:", error);
    captureExceptionToSentry(error);
  });
};

/**
 * Create Firebase Auth user and Sign in him
 */
const registerUserWithEmail = (
  email,
  password,
  successCallback,
  failureCallback
) => {
  createUserWithEmailAndPassword(getAuth(), email, password)
    .then((response) => {
      if (isFunction(successCallback)) {// todo simplify
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const signinWithGmail = (successCallback, failureCallback) => {
  signInWithPopup(getAuth(), gmailProvider)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      captureExceptionToSentry(error);
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const signinWithMicrosoft = (successCallback, failureCallback) => {
  signInWithPopup(getAuth(), microsoftProvider)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const handleSuccessSignin = (authResult, successCallback, failureCallback) => {
  var dt = new Date();
  const userData = {
    name: authResult.user.displayName,
    email: authResult.user.email,
    lastLoginTimestamp: dt.getTime(),
    lastLoginTime: dt,
  };
  if (authResult && authResult.user && authResult.user.photoURL) {
    userData["photoURL"] = authResult.user.photoURL;
  }
  updateUser(userData);
  addLog("signed in", authResult.user.uid, successCallback, failureCallback);
};

const requestPasswordRecovery = (email, successCallback, failureCallback) => {
  sendPasswordResetEmail(getAuth(), email)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const verifyActionCode = (code, successCallback, failureCallback) => {
  checkActionCode(getAuth(), code)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const applyActionCode1 = (code, successCallback, failureCallback) => {
  applyActionCode(getAuth(), code)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

const resetPassword = (code, password, successCallback, failureCallback) => {
  confirmPasswordReset(getAuth(), code, password)
    .then((response) => {
      if (isFunction(successCallback)) {
        successCallback(response);
      }
    })
    .catch((error) => {
      if (isFunction(failureCallback)) {
        failureCallback(error);
      }
    });
};

export {
  firebaseApp,
  Firestore,
  getDoc,
  getDocs,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  collection,
  getAuth,
  addLog,
  updateUser,
  signinUser,
  registerUserWithEmail,
  signinWithGmail,
  signinWithMicrosoft,
  handleSuccessSignin,
  requestPasswordRecovery,
  verifyActionCode,
  resetPassword,
  applyActionCode1 as applyActionCode,
  createRef,
  query,
  orderBy,
  where,
  documentId,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  limit,
  startAfter,
  onAuthStateChanged,
  sendEmailVerification,
  signOut,
  updateProfile,
  deleteField,
};
